import React, { useState, useEffect } from "react"
import axios from "axios"
import { Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../../helpers/Utils"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Select from "react-select"
import MultipleValueTextInput from 'react-multivalue-text-input';

let selectedUserId = []

export default function SourceModal(props) {
   const [loading, setLoading] = useState(false)
   const [image, setImage] = useState("")
   const [imagePreviewProfile, setImagePreviewProfile] = useState(props.source && props.source.logo)
   const [allCheck, setAllCheck] = useState([])

   const options = []

   if (JSON.parse(localStorage.getItem("AllUsers")) !== undefined) {
      JSON.parse(localStorage.getItem("AllUsers")).map(item => {
         options.push({
            value: item.id,
            label: item.name,
         })
      })
   }
   const getSelectedId = e => {
      selectedUserId = []
      e?.map(item => selectedUserId.push(item.value))
   }

   const dValues = []

   const submitSource = (e, value) => {
      setLoading(true)
      if (props.source !== undefined) {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/lead/source/${props.source.id}?name=${value.name}${props.fetch_from_web !== undefined ? `&assignees=${selectedUserId}` : ""}`,
               {
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  props.setIsOpen(false)
                  props.setmodal_lead_source(false)
                  props.setSource(res.data.data)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      } else {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/lead/source`,
               {
                  name: allCheck,
                  // assignees: selectedUserId,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  props.getAllSource()
                  props.setmodal_lead_source(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
   }

   const feedAttach = (e) => {
      setImage(e.target.files[0])
      setImagePreviewProfile(URL.createObjectURL(e.target.files[0]))
   }

   useEffect(() => {
      if (props.source !== undefined) {
         selectedUserId = []
         props.source.assigneename?.map(item => {
            dValues.push({
               value: item.id,
               label: item.name,
            })
            selectedUserId.push(item.id)
         })
      }
   }, [])

   useEffect(() => {
      const close = (e) => {
         if (e.key === 'Escape') {
            props.setmodal_lead_source(false)
         }
      }
      window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
   }, [])

   return (
      <>
         <Modal isOpen={props.modal_lead_source} centered={true}>
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  submitSource(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     {props.source !== undefined ? "Edit Source" : "Create Source"}
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() =>  props.setmodal_lead_source(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  {props.source !== undefined ?
                     <>
                        <div className="mb-3">
                           <AvField
                              label="Name"
                              type="text"
                              name="name"
                              placeholder="Enter name"
                              value={props.source && props.source.name}
                              disabled={props.fetch_from_web !== undefined ? true : false}
                           />
                        </div>
                        {props.fetch_from_web !== undefined ?
                           <div className="form-group mb-3">
                              <label>Select User To Assign</label>
                              <Select
                                 isMulti
                                 isClearable="true"
                                 name="assignees"
                                 options={options}
                                 defaultValue={dValues}
                                 onChange={e => getSelectedId(e)}
                              />
                           </div>
                           :
                           ""
                        }
                     </>
                     :
                     <>
                        <div className="mb-3">
                           <MultipleValueTextInput
                              required
                              onItemAdded={(item, allItems) => console.log(`Item added: ${item, allItems}`,
                                 setAllCheck(allItems)
                              )}
                              onItemDeleted={(item, allItems) => console.log(`Item removed: ${item}`,
                                 setAllCheck(allItems)
                              )}
                              label="Add Lead Source(s)"
                              name="value"
                              className="form-control"
                              placeholder="Enter names you want"
                           />
                           <span className="fst-italic">Use comma (,) to insert a value.</span>
                        </div>
                     </>
                  }
                  {/* <div className="form-group mb-3">
                     <label>Select User To Assign</label>
                     <Select
                        isMulti
                        isClearable="true"
                        name="assignees"
                        options={options}
                        defaultValue={dValues}
                        onChange={e => getSelectedId(e)}
                     />
                  </div> */}
               </div>
               <div className="modal-footer">
                  <div className="text-end">
                     {loading ? (
                        <button
                           className="btn btn-primary disabled"
                           type="button"
                           disabled
                        >
                           Loading
                        </button>
                     ) : (
                        <button
                           className="btn btn-primary"
                           type="submit"
                        // onClick={() => props.setmodal_lead_source(false)}
                        >
                           {props.source !== undefined ? "Submit" : "Create"}
                        </button>
                     )}
                  </div>
               </div>
            </AvForm>
         </Modal>
      </>
   )
}
