import React, { useState, useEffect } from "react"
import axios from "axios"
import { Row, Col, Modal } from "reactstrap"
import {
   AvForm,
   AvField,
   AvRadioGroup,
   AvRadio,
} from "availity-reactstrap-validation"
import { getCurrentUser } from "../../../helpers/Utils"
import Loader from "../../Loader/loader.js"
import CartList from "../../ProductCart"
import Select from "react-select"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

let productNames = []
let total = 0
let subTotal
let otherGst = 0

export default function OrderModal(props) {
   const [loader, setLoader] = useState("")
   const [loader2, setLoader2] = useState("")
   const [loading, setLoading] = useState(false)
   const [error, setError] = useState("")
   const [details, setDetails] = useState("")
   const [nRate, setnRate] = useState("")
   const [gst, setGST] = useState("")
   const [disGst, setDisGst] = useState("")
   const [msp, setMSP] = useState("")
   const [msp2, setMSP2] = useState("")
   const [min, setMin] = useState("")
   const [scheme, setScheme] = useState("none")
   const [quan, setQuan] = useState(1)
   const [netRate2, setNetRate2] = useState(0)
   const [freeItem, setFreeItem] = useState(0)
   const [afterDiscount, setAfterDiscount] = useState("")
   const [productID, setProductID] = useState("")
   const [productName, setProductName] = useState("")
   const [stockLeft, setStockLeft] = useState("")
   const [product, setProduct] = useState([])
   const [productDetail, setProductDetail] = useState([])
   const [showNetRate, setShowNetRate] = useState(false)
   const [showFree, setShowFree] = useState(false)
   const [none, setNone] = useState(true)
   const [proDetail, setProDetail] = useState(false)
   const [showCart, setShowCart] = useState(false)
   const [amountReq, setAmountReq] = useState(false)
   const [err, setErr] = useState(false)
   const [gstErr, setGstErr] = useState(false)
   const [quanErr, setQuanErr] = useState(false)
   const [tandC, setTandC] = useState(
      props.order !== undefined ? props.order.term_condition_message : ""
   )
   const [deliverTo, setDeliverTo] = useState(
      props.order !== undefined ? props.order.deliver_to : ""
   )
   const [billTo, setBillTo] = useState(
      props.order !== undefined ? props.order.bill_to : ""
   )
   const [totalGST, setTotalGST] = useState(0)
   const [grandTot, setGrandTot] = useState("")
   const [orderData, setOrderData] = useState("")
   const [cart, setCart] = useState({ products: [] })
   const [otherCharges, setOtherCharges] = useState({ fields: [] })

   const getOrderDetails = e => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/order/${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setAfterDiscount(res.data.data.amount_after_charges)
               setCart({
                  products: res.data.data.products?.map(item => ({
                     product_id: item.product_id,
                     name: item.product.name,
                     details: item.details,
                     product_price: item.price_per_unit,
                     quantity: item.quantity,
                     min_price: item.min_price,
                     total: item.total,
                     tax: item.tax,
                     freeitem: item.free_item_quantity,
                     subtotal: item.sub_total,
                  })),
               })
               setOtherCharges({
                  fields: res.data.data.other_charges?.map(item => ({
                     action: item.action,
                     name: item.name,
                     value: item.value,
                     gst: item.gst,
                     gstAmount: item.gstAmount ? item.gstAmount : 0,
                  })),
               })
               setOrderData(res.data.data)
               setShowCart(true)
            } else {
            }
         })
   }

   const getTandC = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/term-condition?type=product_list`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setTandC(res.data.data[0]?.term_condition)
            }
         })
   }

   const getDeliverTo = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead/${props.order !== undefined ? props.order.lead_id : props.id}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setDeliverTo(res.data.data.deliver_to)
               setBillTo(res.data.data.proforma_address)
            }
         })
   }

   const getProducts = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/product?per_page=5000&permission=enable`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setProduct(res.data.data.values.data)
            } else {
            }
         })
   }

   const options = []

   if (product !== undefined) {
      product.map(item => {
         item.stock_left !== "0"
            ? options.push({
               value: item.id,
               label: `${item.name} (${item.expiry ? item.expiry : ""})`,
            })
            : ""
      })
   }

   const productDetails = e => {
      setMSP("")
      setnRate("")
      setGST("")
      setDetails("")
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/proforma-product/${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setProDetail(true)
               setProductDetail(res.data.data)
               res.data.data.map(item => {
                  if (item.key == "Selling Price") {
                     setnRate(item.value)
                     setMSP(item.value)
                     setMSP2(item.value)
                  }
                  if (item.key == "Minimum Price") {
                     setMin(item.value)
                  }
                  if (item.key == "GST") {
                     setGST(item.value)
                     setDisGst(item.value)
                  }
                  if (item.key == "Id") {
                     setProductID(item.value)
                  }
                  if (item.key == "Name") {
                     setProductName(item.value)
                  }
                  if (item.key == "Stock Left") {
                     setStockLeft(item.value)
                  }
                  setError("")
               })
               setQuan(1)
               setScheme("none")
               setNone(true)
               setShowNetRate(false)
               setShowFree(false)
               setFreeItem(0)
               setNetRate2(0)
            } else {
            }
         })
   }

   const getNetRate2 = e => {
      if (parseInt(e) < parseInt(min)) {
         setErr(true)
      } else {
         setErr(false)
      }
      setNetRate2(e)
      let Dprice = e * quan
      setMSP(e)
      setnRate(Dprice)
   }

   const getNetRate = e => {
      if (parseInt(e) > parseInt(stockLeft)) {
         setQuanErr(true)
      } else {
         setQuanErr(false)
         setQuan(e)
         let price = msp
         let qn = e
         let netRate = price * qn
         setnRate(netRate)
      }
   }

   const addToCart = () => {
      if (disGst != "") {
         setGstErr(false)
         if (productID == "") {
            setError("Please select a Product before adding to cart.")
         } else {
            setError("")
            let products = cart.products
            let index = products.findIndex(id => id.product_id === productID)
            if (index !== -1) {
               let subtotal
               let netRate
               let rate
               let proDetails = details
               let productPrice = netRate2
               let price = msp
               let qn = quan
               let free = freeItem
               let tax = gst
               if (showNetRate) {
                  rate = productPrice
                  netRate = rate * qn
                  setnRate(netRate)
                  subtotal = netRate + (netRate * tax) / 100
                  setLoader(<Loader />)
                  products[index] = {
                     product_id: productID,
                     name: productName,
                     details: proDetails,
                     product_price: productPrice,
                     quantity: qn,
                     min_price: min,
                     stock_left: stockLeft,
                     subtotal: netRate,
                     tax: gst,
                     freeitem: "",
                     total: subtotal,
                  }
                  setTimeout(() => {
                     setLoader("")
                     setCart({ products })
                  }, 500)
               }
               if (showFree) {
                  rate = price
                  netRate = rate * qn
                  setnRate(netRate)
                  subtotal = netRate + (netRate * tax) / 100
                  setLoader(<Loader />)
                  products[index] = {
                     product_id: productID,
                     name: productName,
                     details: proDetails,
                     product_price: rate,
                     quantity: qn,
                     min_price: min,
                     stock_left: stockLeft,
                     subtotal: netRate,
                     tax: gst,
                     freeitem: free,
                     total: subtotal,
                  }
                  setTimeout(() => {
                     setLoader("")
                     setCart({ products })
                  }, 500)
               }
               if (none) {
                  rate = price
                  netRate = rate * qn
                  setnRate(netRate)
                  subtotal = netRate + (netRate * tax) / 100
                  setLoader(<Loader />)
                  products[index] = {
                     product_id: productID,
                     name: productName,
                     details: proDetails,
                     product_price: rate,
                     quantity: qn,
                     min_price: min,
                     stock_left: stockLeft,
                     subtotal: netRate,
                     tax: gst,
                     freeitem: "",
                     total: subtotal,
                  }
                  setTimeout(() => {
                     setLoader("")
                     setCart({ products })
                  }, 500)
               }
            } else {
               setShowCart(true)
               let subtotal
               let netRate
               let rate
               let proDetails = details
               let productPrice = netRate2
               let price = msp
               let qn = quan
               let free = freeItem
               let tax = gst
               if (showNetRate) {
                  rate = productPrice
                  netRate = rate * qn
                  setnRate(netRate)
                  subtotal = netRate + (netRate * tax) / 100
                  setCart({
                     products: [
                        ...cart.products,
                        {
                           product_id: productID,
                           name: productName,
                           details: proDetails,
                           product_price: productPrice,
                           quantity: qn,
                           min_price: min,
                           stock_left: stockLeft,
                           subtotal: netRate,
                           tax: gst,
                           freeitem: "",
                           total: subtotal,
                        },
                     ],
                  })
               }
               if (showFree) {
                  rate = price
                  netRate = rate * qn
                  setnRate(netRate)
                  subtotal = netRate + (netRate * tax) / 100
                  setCart({
                     products: [
                        ...cart.products,
                        {
                           product_id: productID,
                           name: productName,
                           details: proDetails,
                           product_price: rate,
                           quantity: qn,
                           min_price: min,
                           stock_left: stockLeft,
                           subtotal: netRate,
                           tax: gst,
                           freeitem: free,
                           total: subtotal,
                        },
                     ],
                  })
               }
               if (none) {
                  rate = price
                  netRate = rate * qn
                  setnRate(netRate)
                  subtotal = netRate + (netRate * tax) / 100
                  setCart({
                     products: [
                        ...cart.products,
                        {
                           product_id: productID,
                           name: productName,
                           details: proDetails,
                           product_price: rate,
                           quantity: qn,
                           min_price: min,
                           stock_left: stockLeft,
                           subtotal: netRate,
                           tax: gst,
                           freeitem: "",
                           total: subtotal,
                        },
                     ],
                  })
               }
            }
            setTimeout(() => {
               updateTotalAfterDisc()
            }, 500)
         }
      } else {
         setGstErr(true)
      }
   }

   const handleChange = (i, e) => {
      let products = cart.products
      products[i][e.target.name] = e.target.value
      setCart({ products })
      setTimeout(() => {
         updateTotal()
      }, 500)
   }

   const updateTotal = () => {
      total = cart.products.reduce(
         (total, currentValue) => (total = total + parseFloat(currentValue.total)),
         0
      )
      subTotal = cart.products.reduce(
         (subTotal, currentValue) =>
            (subTotal = subTotal + parseFloat(currentValue.subtotal)),
         0
      )
      setTimeout(() => {
         updateTotalAfterDisc()
      }, 500)
   }

   useEffect(() => {
      if (afterDiscount !== "") {
         updateGrandTotal(totalGST)
      }
   }, [afterDiscount])

   const updateGrandTotal = e => {
      // if (e !== "") {
      //    setTotalGST(e)
      //    if (afterDiscount !== "") {
      //       let gt = (parseInt(afterDiscount) + parseInt(e)).toFixed(2)
      //       setGrandTot(gt)
      //    } else {
      //       let gt2 = (parseInt(total) + parseInt(e)).toFixed(2)
      //       setGrandTot(gt2)
      //    }
      // } else {
      //    setTotalGST(0)
      //    if (afterDiscount !== "") {
      //       let gt = (parseInt(afterDiscount)).toFixed(2)
      //       setGrandTot(gt)
      //    } else {
      //       let gt2 = (parseInt(total)).toFixed(2)
      //       setGrandTot(gt2)
      //    }
      // }
   }

   const removeFormFields = i => {
      let products = cart.products
      setLoader(<Loader />)
      setCart({ products: [] })
      products.splice(i, 1)
      setTimeout(() => {
         setLoader("")
         setCart({ products })
         updateTotal()
      }, 500)
   }

   total = cart.products.reduce(
      (total, currentValue) => (total = total + parseFloat(currentValue.total)),
      0
   )

   subTotal = cart.products.reduce(
      (subTotal, currentValue) => (subTotal = subTotal + currentValue.subtotal),
      0
   )

   otherGst = otherCharges.fields.reduce(
      (otherGst, currentValue) =>
         (otherGst = otherGst + parseFloat(currentValue.gstAmount)),
      0
   )

   const addOtherFields = () => {
      setOtherCharges({
         fields: [
            ...otherCharges.fields,
            { name: "", action: "", value: 0, gst: 0, gstAmount: 0 },
         ],
      })
   }

   const removeOtherFields = i => {
      setLoader2(<Loader />)
      let fields // declare fields variable here
      if (otherCharges) {
         fields = otherCharges.fields
         setOtherCharges({ fields: [] })
      }
      fields.splice(i, 1)
      setTimeout(() => {
         setOtherCharges({ fields })
         setLoader2("")
         let total = cart.products.reduce(
            (total, currentValue) => (total = total + currentValue.total),
            0
         )
         let totalValue = total
         let addAmount = 0
         let addPercent = 0
         let addPercentValue = 0
         let subtractAmount = 0
         let subtractPercent = 0
         let subPercentValue = 0
         let gstValue = 0
         otherCharges.fields.map(item => {
            if (item.action === "1") {
               if (item.value !== "") {
                  addAmount = addAmount + parseFloat(item.value)
                  let amount = parseFloat(item.value)
                  let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
                  gstValue = gstAmnt - amount
               } else {
                  addAmount = addAmount + 0
               }
            } else if (item.action === "2") {
               if (item.value !== "") {
                  subtractAmount = subtractAmount + parseFloat(item.value)
                  let amount = parseFloat(item.value)
                  let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
                  gstValue = gstAmnt - amount
               } else {
                  subtractAmount = subtractAmount + 0
               }
            } else if (item.action === "3") {
               if (item.value !== "") {
                  addPercent = addPercent + parseInt(item.value)
               } else {
                  addPercent = addPercent + 0
               }
               addPercentValue = (totalValue * addPercent) / 100
               let amount = (totalValue * parseFloat(item.value)) / 100
               let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
               gstValue = gstAmnt - amount
            } else if (item.action === "4") {
               if (item.value !== "") {
                  subtractPercent = subtractPercent + parseInt(item.value)
               } else {
                  subtractPercent = subtractPercent + 0
               }
               subPercentValue = (totalValue * subtractPercent) / 100
               let amount = (totalValue * parseFloat(item.value)) / 100
               let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
               gstValue = gstAmnt - amount
            }
         })
         // fields[i]["gstAmount"] = gstValue
         setOtherCharges({ fields })
         setAfterDiscount(
            (
               totalValue -
               subtractAmount +
               addAmount +
               addPercentValue -
               subPercentValue
            ).toFixed(2)
         )
      }, 500)
   }

   const handleOtherFields = (index, e) => {
      let fields = otherCharges.fields
      fields[index][e.target.name] = e.target.value
      setOtherCharges({ fields })
      total = cart.products.reduce(
         (total, currentValue) => (total = total + currentValue.total),
         0
      )
      let totalValue = total
      if (e.target.name == "name") {
      } else {
         let addAmount = 0
         let addPercent = 0
         let addPercentValue = 0
         let subtractAmount = 0
         let subtractPercent = 0
         let subPercentValue = 0
         let gstValue = 0
         otherCharges.fields.map(item => {
            if (item.action === "1") {
               if (item.value !== "") {
                  addAmount = addAmount + parseFloat(item.value)
                  let amount = parseFloat(item.value)
                  let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
                  gstValue = gstAmnt - amount
               } else {
                  addAmount = addAmount + 0
               }
            } else if (item.action === "2") {
               if (item.value !== "") {
                  subtractAmount = subtractAmount + parseFloat(item.value)
                  let amount = parseFloat(item.value)
                  let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
                  gstValue = gstAmnt - amount
               } else {
                  subtractAmount = subtractAmount + 0
               }
            } else if (item.action === "3") {
               if (item.value !== "") {
                  addPercent = addPercent + parseInt(item.value)
               } else {
                  addPercent = addPercent + 0
               }
               addPercentValue = (totalValue * addPercent) / 100
               let amount = (totalValue * parseFloat(item.value)) / 100
               let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
               gstValue = gstAmnt - amount
            } else if (item.action === "4") {
               if (item.value !== "") {
                  subtractPercent = subtractPercent + parseInt(item.value)
               } else {
                  subtractPercent = subtractPercent + 0
               }
               subPercentValue = (totalValue * subtractPercent) / 100
               let amount = (totalValue * parseFloat(item.value)) / 100
               let gstAmnt = amount + (amount * parseFloat(item.gst)) / 100
               gstValue = gstAmnt - amount
            }
         })
         let fields = otherCharges.fields
         fields[index]["gstAmount"] = gstValue
         setOtherCharges({ fields })
         setAfterDiscount(
            (
               totalValue -
               subtractAmount +
               addAmount +
               addPercentValue -
               subPercentValue
            ).toFixed(2)
         )
      }
   }

   const updateTotalAfterDisc = () => {
      if (otherCharges.fields.length > 0) {
         total = cart.products.reduce(
            (total, currentValue) =>
               (total = total + parseFloat(currentValue.total)),
            0
         )
         let totalValue = total
         let addAmount = 0
         let addPercent = 0
         let addPercentValue = 0
         let subtractAmount = 0
         let subtractPercent = 0
         let subPercentValue = 0
         otherCharges.fields?.map(item => {
            if (item.action == "1") {
               if (item.value != "") {
                  addAmount = addAmount + parseFloat(item.value)
               } else {
                  addAmount = addAmount + 0
               }
            } else if (item.action == "2") {
               if (item.value != "") {
                  subtractAmount = subtractAmount + parseFloat(item.value)
               } else {
                  subtractAmount = subtractAmount + 0
               }
            } else if (item.action == "3") {
               if (item.value != "") {
                  addPercent = addPercent + parseInt(item.value)
               } else {
                  addPercent = addPercent + 0
               }
               addPercentValue = (totalValue * addPercent) / 100
            } else if (item.action == "4") {
               if (item.value != "") {
                  subtractPercent = subtractPercent + parseInt(item.value)
               } else {
                  subtractPercent = subtractPercent + 0
               }
               subPercentValue = (totalValue * subtractPercent) / 100
            }
         })
         setAfterDiscount(
            (
               totalValue -
               subtractAmount +
               addAmount +
               addPercentValue -
               subPercentValue
            ).toFixed(2)
         )
      } else {
         setAfterDiscount(total)
      }
   }

   const getPaymentType = e => {
      if (e == "cash") {
         setAmountReq(true)
      } else {
         setAmountReq(false)
      }
   }

   const schemeSet = e => {
      if (e == "none") {
         setScheme(e)
         setNone(true)
         setShowNetRate(false)
         setShowFree(false)
      }
      if (e == "net_rate") {
         setScheme(e)
         setShowNetRate(true)
         setShowFree(false)
         setNone(false)
      }
      if (e == "free_item") {
         setScheme(e)
         setMSP(msp2)
         getNetRate2(msp2)
         setShowNetRate(false)
         setShowFree(true)
         setNone(false)
      }
   }

   const submitOrder = (e, value) => {
      let emailValue = 0
      if (value.send_email) {
         emailValue = 1
      } else {
         emailValue = 0
      }
      let wasup = 0
      if (value.whatsapp) {
         wasup = 1
      } else {
         wasup = 0
      }
      setLoading(true)
      if (cart.products !== "") {
         productNames = cart.products
      }
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      if (props.order !== undefined) {
         if (props.order.is_first_order === 1) {
            axios
               .post(
                  `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                     "company_name"
                  )}/update-order-payment`,
                  {
                     id: props.order.id,
                     amount_after_charges: value.amount_after_charges,
                     paid_amount: value.paid_amount,
                  },
                  {
                     headers: {
                        Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                           }`,
                     },
                  }
               )
               .then(function (res) {
                  if (res.data.status) {
                     setLoading(false)
                     props.setOrder(res.data.data)
                     props.setmodal_standard(false)
                     if (props.setmodal_order !== undefined) {
                        props.setmodal_order(false)
                     }
                     toast.success(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                     })
                  } else {
                     setLoading(false)
                     toast.error(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                     })
                  }
               })
         } else {
            axios
               .put(
                  `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                     "company_name"
                  )}/order/${props.order.id}`,
                  {
                     amount: total,
                     amount_after_charges: value.amount_after_charges,
                     deliver_to: deliverTo,
                     payment_type: value.payment_type,
                     term_condition: tandC,
                     bill_to: billTo,
                     other_charges: otherCharges.fields,
                     paymentamount: value.paymentamount,
                     remarks: value.remarks,
                     type: "order",
                     products: productNames,
                     total_gst: value.total_gst,
                     preferred_transport: value.preferred_transport,
                     // grand_total: value.grand_total
                     send_email: emailValue,
                     whatsapp: wasup,
                  },
                  {
                     headers: {
                        Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                           }`,
                     },
                  }
               )
               .then(function (res) {
                  if (res.data.status) {
                     setLoading(false)
                     props.setOrder(res.data.data)
                     props.setmodal_standard(false)
                     if (props.setmodal_order !== undefined) {
                        props.setmodal_order(false)
                     }
                     toast.success(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                     })
                     setTimeout(() => {
                        setCart({ products: [] })
                        setOtherCharges({ fields: [] })
                        setProDetail(false)
                        setShowCart(false)
                     }, 500)
                  } else {
                     setLoading(false)
                     toast.error(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                     })
                  }
               })
         }
      } else {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/order`,
               {
                  lead_id: props.id,
                  amount: total,
                  amount_after_charges: value.amount_after_charges,
                  deliver_to: deliverTo,
                  payment_type: value.payment_type,
                  term_condition: tandC,
                  bill_to: billTo,
                  other_charges: otherCharges.fields,
                  paymentamount: value.paymentamount,
                  remarks: value.remarks,
                  type: "order",
                  products: productNames,
                  send_email: emailValue,
                  whatsapp: wasup,
                  total_gst: value.total_gst,
                  preferred_transport: value.preferred_transport,
                  // grand_total: value.grand_total
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                        }`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  if (props.getAllLeads !== undefined) {
                     if (props.local !== undefined) {
                        props.getAllLeads("refresh")
                     } else {
                        props.getAllLeads()
                     }
                  }
                  if (props.getAllOrders !== undefined) {
                     props.getAllOrders("order")
                  }
                  props.setmodal_standard(false)
                  if (props.setmodal_order !== undefined) {
                     props.setmodal_order(false)
                  }
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
                  setTimeout(() => {
                     setCart({ products: [] })
                     setOtherCharges({ fields: [] })
                     setProDetail(false)
                     setShowCart(false)
                  }, 500)
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
   }

   useEffect(() => {
      if (props?.order !== undefined) {
         getOrderDetails(props.order.id)
      }
      getProducts()
      if (props.order !== undefined) {
         if (props.order.term_condition_message !== null) {
         } else {
            getTandC()
         }
      } else {
         getTandC()
      }
      if (props.order !== undefined) {
         if (props.order.deliver_to !== null) {
         } else {
            getDeliverTo()
         }
      } else {
         getDeliverTo()
      }
   }, [])

   useEffect(() => {
      const close = e => {
         if (e.key === "Escape") {
            props.setmodal_standard(false)
         }
      }
      window.addEventListener("keydown", close)
      return () => window.removeEventListener("keydown", close)
   }, [])

   return (
      <>
         <Modal
            isOpen={props.modal_standard}
            centered={true}
            {...(props.order?.is_first_order !== 1 ? {
               style: {
                  maxWidth: "1270px",
                  padding: "0 10px",
               }
            } : {
               style: {
                  maxWidth: "600px",
                  padding: "0 10px",
               }
            })}
         >
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  submitOrder(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     {props?.order !== undefined ? "Edit Order" : "Create Order"}
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => props.setmodal_standard(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               {props.order?.is_first_order !== 1 ?
                  <div className="modal-body">
                     <div className="mb-3">
                        <label>Type</label>
                        <AvRadioGroup
                           inline
                           name="payment_type"
                           onChange={e => getPaymentType(e.target.value)}
                           value={props.order?.payment_type}
                           required
                        >
                           <AvRadio label="Cash" value="cash" />
                           <AvRadio label="Self" value="self" />
                           <AvRadio label="Sample" value="sample" />
                           <AvRadio label="Credit" value="credit" />
                        </AvRadioGroup>
                     </div>
                     <div className="table-responsive mb-3">
                        {error ? (
                           <span className="text-danger text-align-center">{error}</span>
                        ) : (
                           ""
                        )}
                        <div className="lead_table mb-3" style={{ minHeight: "50px" }}>
                           <table style={{ width: "100%" }}>
                              <thead>
                                 <tr>
                                    <th>Type</th>
                                    <th>Details</th>
                                    <th>Quantity</th>
                                    <th>Selling Price</th>
                                    <th>Discount/Scheme</th>
                                    {showNetRate ? <th>Net-Rate</th> : null}
                                    {showFree ? <th>Free Item Quantity</th> : null}
                                    <th>Sub-Total</th>
                                    <th>GST</th>
                                    <th>{null}</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr>
                                    <td>
                                       <div style={{ width: "210px" }}>
                                          <Select
                                             id="product"
                                             name="product"
                                             options={options}
                                             onChange={e => productDetails(e.value)}
                                          />
                                       </div>
                                    </td>
                                    <td>
                                       <AvField
                                          type="textarea"
                                          style={{ width: "130px" }}
                                          name="details"
                                          value={details}
                                          onChange={e => setDetails(e.target.value)}
                                       />
                                    </td>
                                    <td>
                                       <AvField
                                          type="number"
                                          onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                          min="1"
                                          style={{ width: "70px" }}
                                          name="quantity"
                                          value={quan}
                                          onChange={e => getNetRate(e.target.value)}
                                       />
                                       {quanErr ? (
                                          <span className="text-danger">
                                             Quantity can not be more than stock left.
                                          </span>
                                       ) : (
                                          ""
                                       )}
                                    </td>
                                    <td>
                                       <AvField
                                          type="number"
                                          onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                          disabled
                                          min="0"
                                          style={{ width: "130px" }}
                                          name="msp"
                                          value={msp}
                                       />
                                    </td>
                                    <td>
                                       <AvField
                                          name="scheme"
                                          type="select"
                                          style={{ width: "120px" }}
                                          className="select-arrow"
                                          onChange={e => schemeSet(e.target.value)}
                                          value={scheme}
                                       >
                                          <option value="none">None</option>
                                          <option value="net_rate">Net Rate</option>
                                          <option value="free_item">Free Item</option>
                                       </AvField>
                                    </td>
                                    {showNetRate ? (
                                       <td>
                                          <AvField
                                             type="number"
                                             onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                             min="0"
                                             style={{ width: "80px" }}
                                             name="net_rate"
                                             value={netRate2}
                                             onChange={e => getNetRate2(e.target.value)}
                                          />
                                          {err ? (
                                             <span className="text-danger">
                                                Net Rate should be more than Min Price
                                             </span>
                                          ) : (
                                             ""
                                          )}
                                       </td>
                                    ) : (
                                       ""
                                    )}
                                    {showFree ? (
                                       <td>
                                          <AvField
                                             type="number"
                                             onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                             min="0"
                                             style={{ width: "80px" }}
                                             value={freeItem}
                                             name="free_item_quantity"
                                             onChange={e => setFreeItem(e.target.value)}
                                          />
                                       </td>
                                    ) : (
                                       ""
                                    )}
                                    <td>
                                       <AvField
                                          type="number"
                                          onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                          style={{ width: "90px" }}
                                          name="net_rate"
                                          value={nRate}
                                          disabled
                                       />
                                    </td>
                                    <td>
                                       <AvField
                                          className="select-arrow"
                                          type="select"
                                          name="gst"
                                          style={{ width: "75px" }}
                                          onChange={e => {
                                             setGST(e.target.value), setDisGst(e.target.value)
                                          }}
                                          value={gst}
                                       // disabled={disGst !== "" ? true : false}
                                       >
                                          <option value="0">0%</option>
                                          <option value="0.1">0.1%</option>
                                          <option value="0.25">0.25%</option>
                                          <option value="5">5%</option>
                                          <option value="12">12%</option>
                                          <option value="18">18%</option>
                                          <option value="28">28%</option>
                                       </AvField>
                                       {gstErr ? (
                                          <span className="text-danger">
                                             Select GST before adding to Cart*
                                          </span>
                                       ) : (
                                          ""
                                       )}
                                    </td>
                                    <td>
                                       <button
                                          type="button"
                                          className="btn bg-success text-light float-end"
                                          onClick={() => addToCart()}
                                          disabled={err || quanErr ? true : false}
                                       >
                                          Add to Cart
                                       </button>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                        <span className="" style={{ fontSize: "24px" }}>
                           Product Details
                        </span>
                        {proDetail ? (
                           <div
                              className="lead_table"
                              style={{ minHeight: "20px", overflowX: "scroll" }}
                           >
                              <table style={{ width: "100%" }}>
                                 <tbody>
                                    <tr>
                                       {productDetail?.map((item, i) => (
                                          <th key={i}>{item.key}</th>
                                       ))}
                                    </tr>
                                    <tr>
                                       {productDetail?.map((item, i) => (
                                          <td key={i}>{item.value}</td>
                                       ))}
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        ) : (
                           <div style={{ height: "50px" }}></div>
                        )}
                        <br />
                        <span className="" style={{ fontSize: "24px" }}>
                           Cart
                        </span>
                        {showCart ? (
                           <div
                              className="lead_table mb-3"
                              style={{ minHeight: "50px", overflowX: "scroll" }}
                           >
                              {loader !== "" ? (
                                 loader
                              ) : (
                                 <table style={{ width: "100%" }}>
                                    <thead>
                                       <tr>
                                          <th>Type</th>
                                          <th>Details</th>
                                          <th>Quantity</th>
                                          <th>Free Item</th>
                                          <th>Rate</th>
                                          <th>Subtotal</th>
                                          <th>GST</th>
                                          <th>Total</th>
                                          <th>Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {cart.products &&
                                          cart.products.map((item, i) => (
                                             <CartList
                                                key={i}
                                                data={item}
                                                i={i}
                                                removeFormFields={removeFormFields}
                                                productDetail={productDetail}
                                                setCart={setCart}
                                                cart={cart}
                                                updateTotal={updateTotal}
                                                handleChange={handleChange}
                                             />
                                          ))}
                                    </tbody>
                                 </table>
                              )}
                           </div>
                        ) : (
                           <div style={{ height: "50px" }}></div>
                        )}
                     </div>
                     <Row>
                        <Col md={5}>
                           <div className="mb-3">
                              <AvField
                                 type="number"
                                 onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                 label="Order Value"
                                 name="deliver"
                                 disabled
                                 value={parseFloat(total).toFixed(2)}
                              />
                           </div>
                           <div className="d-flex justify-content-between">
                              <label>Other Charges/discounts</label>
                              <button
                                 className="btn btn-primary btn-sm"
                                 type="button"
                                 onClick={() => addOtherFields()}
                              >
                                 +
                              </button>
                           </div>
                           <div className="">
                              {otherCharges.fields.length == 0 ? (
                                 <div className="mb-5"></div>
                              ) : (
                                 <>
                                    {loader2 != "" ? (
                                       loader2
                                    ) : (
                                       <div className="table-responsive mb-3">
                                          <table style={{ width: "100%" }}>
                                             <thead>
                                                <tr>
                                                   <th>Name</th>
                                                   <th>Action</th>
                                                   <th>Value</th>
                                                   <th>GST</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                {otherCharges.fields &&
                                                   otherCharges.fields.map((item, index) => (
                                                      <tr key={index}>
                                                         <td>
                                                            <AvField
                                                               name="name"
                                                               type="text"
                                                               placeholder="Enter name"
                                                               onChange={e =>
                                                                  handleOtherFields(index, e)
                                                               }
                                                               value={item.name}
                                                            />
                                                         </td>
                                                         <td>
                                                            <AvField
                                                               className="select-arrow"
                                                               name="action"
                                                               type="select"
                                                               onChange={e =>
                                                                  handleOtherFields(index, e)
                                                               }
                                                               value={item.action}
                                                            >
                                                               <option value="" hidden>
                                                                  Select
                                                               </option>
                                                               <option value="1">Add (+)</option>
                                                               <option value="2">Subtract (-)</option>
                                                               <option value="3">
                                                                  Percentage (+)
                                                               </option>
                                                               <option value="4">
                                                                  Percentage (-)
                                                               </option>
                                                            </AvField>
                                                         </td>
                                                         <td>
                                                            <AvField
                                                               name="value"
                                                               type="number"
                                                               onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                               min="0"
                                                               placeholder="Enter amount"
                                                               value={item.value}
                                                               onChange={e =>
                                                                  handleOtherFields(index, e)
                                                               }
                                                            />
                                                         </td>
                                                         <td>
                                                            <AvField
                                                               className="select-arrow"
                                                               type="select"
                                                               name="gst"
                                                               style={{ width: "75px" }}
                                                               onChange={e =>
                                                                  handleOtherFields(index, e)
                                                               }
                                                               value={item.gst}
                                                            // disabled={disGst !== "" ? true : false}
                                                            >
                                                               <option value="0">0%</option>
                                                               <option value="0.25">0.25%</option>
                                                               <option value="5">5%</option>
                                                               <option value="12">12%</option>
                                                               <option value="18">18%</option>
                                                               <option value="28">28%</option>
                                                            </AvField>
                                                         </td>
                                                         <td>
                                                            <span
                                                               className="btn btn-danger"
                                                               onClick={() => removeOtherFields(index)}
                                                            >
                                                               &times;
                                                            </span>
                                                         </td>
                                                      </tr>
                                                   ))}
                                             </tbody>
                                          </table>
                                       </div>
                                    )}
                                 </>
                              )}
                           </div>
                        </Col>
                        <Col md={4}>
                           <div className="mb-3">
                              <label>Deliver To</label>
                              <ReactQuill
                                 theme="snow"
                                 value={deliverTo}
                                 onChange={e => setDeliverTo(e)}
                              />
                           </div>
                           <div className="mb-3">
                              <label>Bill To</label>
                              <ReactQuill
                                 theme="snow"
                                 value={billTo}
                                 onChange={e => setBillTo(e)}
                              />
                           </div>
                           <div className="mb-3">
                              <label>Terms & Conditions</label>
                              <ReactQuill
                                 theme="snow"
                                 value={tandC}
                                 onChange={e => setTandC(e)}
                              />
                           </div>
                           <div className="mb-3">
                              <AvField
                                 type="textarea"
                                 label="Remarks"
                                 name="remarks"
                                 placeholder="Remarks"
                                 value={props.order && props.order.remarks}
                              />
                           </div>
                        </Col>
                        <Col md={3}>
                           <div className="mb-3">
                              <AvField
                                 type="number"
                                 onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                 label="Grand Value"
                                 name="amount_after_charges"
                                 value={
                                    afterDiscount !== ""
                                       ? (parseFloat(afterDiscount) + otherGst).toFixed(2)
                                       : (parseFloat(total) + otherGst).toFixed(2)
                                 }
                                 disabled
                              />
                           </div>
                           <div className="mb-3 required">
                              <AvField
                                 required
                                 type="text"
                                 label="GST value (only to display on invoice)"
                                 name="total_gst"
                                 placeholder="Enter GST"
                                 // onChange={(e) => updateGrandTotal(e.target.value)}
                                 value={(total - subTotal + otherGst).toFixed(2)}
                              />
                              <span className="text-danger fst-italic">
                                 It will not be calculated anywhere
                              </span>
                           </div>
                           <div className="mb-3">
                              <AvField
                                 required={amountReq ? true : false}
                                 type="text"
                                 label="Amount Received"
                                 name="paymentamount"
                                 placeholder="Amount Received"
                                 value={props.order && props.order.paid_amount}
                              />
                           </div>
                           <div className="mb-3">
                              <AvField
                                 type="text"
                                 label="Prefered Transport"
                                 name="preferred_transport"
                                 placeholder="Prefered Transport"
                                 value={
                                    props.order !== undefined
                                       ? props.order.preferred_transport
                                       : ""
                                 }
                              />
                           </div>
                           <div className="mb-3">
                              <AvField
                                 type="checkbox"
                                 label="Send Email"
                                 name="send_email"
                                 style={{ background: `${getCurrentUser()?.email_enabled === 1 ? "" : "lightgray"}` }}
                                 disabled={getCurrentUser()?.email_enabled === 1 ? false : true}
                              />
                           </div>
                           <div className="mb-3">
                              <AvField
                                 type="checkbox"
                                 label="Send Whatsapp"
                                 name="whatsapp"
                                 style={{ background: `${getCurrentUser()?.whatsapp_enabled === 1 ? "" : "lightgray"}` }}
                                 disabled={getCurrentUser()?.whatsapp_enabled === 1 ? false : true}
                              />
                           </div>
                        </Col>
                     </Row>
                  </div>
                  :
                  <div className="modal-body">
                     <div className="mb-3">
                        <AvField
                           type="number"
                           onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                           label="Order Value"
                           name="amount_after_charges"
                           value={props.order?.amount_after_charges}
                        />
                     </div>
                     <div className="mb-3">
                        <AvField
                           type="number"
                           onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                           label="Amount Received"
                           name="paid_amount"
                           placeholder="Amount Received"
                           value={props.order?.paid_amount}
                        />
                     </div>
                  </div>
               }
               <div className="modal-footer">
                  <div className="text-center">
                     {loading ? (
                        <button
                           className="btn btn-primary disabled"
                           type="button"
                           disabled
                        >
                           Loading
                        </button>
                     ) : (
                        <button
                           className="btn btn-primary"
                           type="submit"
                        // onClick={() => props.setmodal_standard(false)}
                        >
                           Submit
                        </button>
                     )}
                  </div>
               </div>
            </AvForm>
         </Modal>
      </>
   )
}
