import React, { useState, useEffect } from "react"
import {
   AvForm,
   AvField,
   AvRadio,
   AvRadioGroup,
   AvCheckboxGroup,
   AvCheckbox,
} from "availity-reactstrap-validation"
import { getCurrentUser } from "../../../helpers/Utils"
import { addDays } from "date-fns"
import setHours from "date-fns/setHours"
import setMinutes from "date-fns/setMinutes"
import DatePicker from "react-datepicker"
import axios from "axios"
import moment from "moment"
import Loader from "../../Loader/loader.js"
import LeadModal3 from "../../Lead/leadmodal3"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import TimePicker from 'react-times';
// use material theme
import 'react-times/css/material/default.css';
// or you can use classic theme
import 'react-times/css/classic/default.css';

const CupdateTable = props => {
   const [time, setTime] = useState(props.lead.best_time !== null ? props.lead.best_time : '08:00')
   const [loading, setLoading] = useState(false)
   const [loader, setLoader] = useState(<Loader />)
   const [modal_standard3, setmodal_standard3] = useState(false)
   const [lead, setLead] = useState([])
   const [first, setFirst] = useState(false)
   const [followupSec, setFollowupSec] = useState(false)
   const [matureSec, setMatureSec] = useState(false)
   const [deadSec, setDeadSec] = useState(false)
   const [dead_reqSec, setDead_reqSec] = useState(false)
   const [showOrderValue, setShowOrderValue] = useState(false)
   const [allMature, setAllMature] = useState([])
   const [allFollowup, setAllFollowup] = useState([])
   const [allDead, setAllDead] = useState([])
   const [orderDetails, setOrderDetails] = useState([])
   const [followupMessage, setFollowupMessage] = useState("")
   const [startDate, setStartDate] = useState(addDays(new Date(), 1))

   const handleTimeChange = (time) => {
      let hours
      if (time.meridiem === "AM") {
         hours = time.hour
      } else {
         hours = parseInt(time.hour) + 12
      }
      let timeString = `${hours}:${time.minute}`
      setTime(timeString)
   }

   const formatDate = date => {
      let d = new Date(date)
      let month = (d.getMonth() + 1).toString()
      let day = d.getDate().toString()
      let year = d.getFullYear()
      if (month.length < 2) {
         month = "0" + month
      }
      if (day.length < 2) {
         day = "0" + day
      }
      return [year, month, day].join("-")
   }

   const getFollowupCount = (e) => {
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/get-followup-count`,
            {
               date: e !== undefined ? formatDate(e) : formatDate(startDate)
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            setFollowupMessage(res.data.data)
         })
   }

   const getAllFollowup = () => {
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/followup-status?per_page=200`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            setLoader("")
            setAllFollowup(res.data.data.data)
         })
   }

   const getMatureStatus = () => {
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/mature-status?per_page=200`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setAllMature(res.data.data.data)
            } else {
               setLoader("")
            }
         })
   }

   const getAllDead = () => {
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/dead-status?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setAllDead(res.data.data.data)
               setLoader("")
            } else {
               setLoader("")
            }
         })
   }

   const getLastOrderDetails = () => {
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/followup-detail?id=${props.lead && props.lead.id}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setOrderDetails(res.data.data)
               setLoader("")
            } else {
               setLoader("")
            }
         })
   }

   const getMatureValue = e => {
      if (e.target.options[e.target.selectedIndex].text == "Order Received") {
         if (getCurrentUser()?.crm_permission == "1" || getCurrentUser()?.crmpro_permission == "1") {
            setShowOrderValue(true)
         }
      } else {
         setShowOrderValue(false)
      }
   }

   const updateOption = (e, f) => {
      if (e === "followup") {
         getAllFollowup()
         setDeadSec(false)
         setMatureSec(false)
         setDead_reqSec(false)
         setFollowupSec(true)
         setFirst(false)
      } else if (e === "matured") {
         getMatureStatus()
         getLastOrderDetails()
         setFollowupSec(false)
         setDeadSec(false)
         setMatureSec(true)
         setDead_reqSec(false)
         if (f === "firstTime") {
            setFirst(true)
         }
      } else if (e === "dead") {
         getAllDead()
         setFollowupSec(false)
         setMatureSec(false)
         setDead_reqSec(false)
         setDeadSec(true)
         setFirst(false)
      } else if (e === "dead_request") {
         getAllDead()
         setFollowupSec(false)
         setMatureSec(false)
         setDead_reqSec(false)
         setDeadSec(true)
         setFirst(false)
      } else if (e === "matured_dead") {
         getAllDead()
         setDeadSec(true)
         setFollowupSec(false)
         setMatureSec(false)
         setDead_reqSec(true)
         setFirst(false)
      } else if (e === "matured_dead_request") {
         getAllDead()
         setDeadSec(true)
         setDead_reqSec(true)
         setFollowupSec(false)
         setMatureSec(false)
         setFirst(false)
      }
   }

   const submitFollowup = (e, value) => {
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);

      let exe = "0"
      if (value.senior && value.senior.includes("executive")) {
         exe = "1"
      } else {
         exe = "0"
      }
      let teamL = "0"
      if (value.senior && value.senior.includes("team_leader")) {
         teamL = "1"
      } else {
         teamL = "0"
      }
      let admi = "0"
      if (value.senior && value.senior.includes("administrator")) {
         admi = "1"
      } else {
         admi = "0"
      }
      let Priority = "0"
      if (value.priority) {
         Priority = "1"
      } else {
         Priority = "0"
      }
      let Reminder = "0"
      if (value.send_followup_reminder) {
         Reminder = "1"
      } else {
         Reminder = "0"
      }
      let Email = 0
      if (value.send_email) {
         Email = 1
      } else {
         Email = 0
      }
      let Whatsapp = 0
      if (value.whatsapp) {
         Whatsapp = 1
      } else {
         Whatsapp = 0
      }
      if (value.type === "matured_dead") {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/followup`,
               {
                  lead_id: props.lead && props.lead.id,
                  type: value.type,
                  action: value.action,
                  dead_reason_id: value.dead_reason_id,
                  message: value.message,
                  admin: admi,
                  team_leader: teamL,
                  priority: Priority,
                  executive: exe,
                  send_email: Email,
                  whatsapp: Whatsapp,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  if (props.local !== undefined) {
                     props.getAllLeads("local", props.lead.id)
                  } else {
                     props.getAllLeads()
                  }
                  props.setmodal_xl(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
      if (value.type === "matured_dead_request") {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/followup`,
               {
                  lead_id: props.lead && props.lead.id,
                  type: value.type,
                  action: value.action,
                  priority: Priority,
                  dead_reason_id: value.dead_reason_id,
                  message: value.message,
                  admin: admi,
                  priority: Priority,
                  team_leader: teamL,
                  send_email: Email,
                  whatsapp: Whatsapp,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  if (props.local !== undefined) {
                     props.getAllLeads("local", props.lead.id)
                  } else {
                     props.getAllLeads()
                  }
                  props.setmodal_xl(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
      if (value.type === "matured") {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/followup`,
               {
                  lead_id: props.lead && props.lead.id,
                  type: value.type,
                  order_value: showOrderValue ? e.target.order_value.value : "",
                  payment_received: showOrderValue ? e.target.payment_received.value : "",
                  mature_status_id: showOrderValue ? 0 : value.mature_status_id,
                  next_followup_date: e.target.next_followup_date.value,
                  next_followup_time: time,
                  action: value.action,
                  message: value.message,
                  admin: admi,
                  priority: Priority,
                  team_leader: teamL,
                  executive: exe,
                  send_email: Email,
                  whatsapp: Whatsapp,
                  mature_status_options: value.mature_status_options,
                  send_followup_reminder: followupMessage?.follow_event_added === 1 ? Reminder : "0"
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLead(res.data.data?.lead)
                  if (first) {
                     setmodal_standard3(true)
                  } else {
                     if (props.local !== undefined) {
                        props.getAllLeads("local", props.lead.id)
                     } else {
                        props.getAllLeads()
                     }
                     props.setmodal_xl(false)
                  }
                  setLoading(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
      if (value.type === "followup") {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/followup`,
               {
                  lead_id: props.lead && props.lead.id,
                  type: value.type,
                  followup_status_id: value.followup_status_id,
                  next_followup_date: e.target.next_followup_date.value,
                  next_followup_time: time,
                  message: value.message,
                  priority: Priority,
                  action: value.action,
                  admin: admi,
                  team_leader: teamL,
                  executive: exe,
                  send_email: Email,
                  whatsapp: Whatsapp,
                  send_followup_reminder: followupMessage?.follow_event_added === 1 ? Reminder : "0"
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  if (props.local !== undefined) {
                     props.getAllLeads("local", props.lead.id)
                  } else {
                     props.getAllLeads()
                  }
                  props.setmodal_xl(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
      if (value.type === "dead") {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/followup`,
               {
                  lead_id: props.lead && props.lead.id,
                  type: value.type,
                  action: value.action,
                  dead_reason_id: value.dead_reason_id,
                  message: value.message,
                  admin: admi,
                  priority: Priority,
                  team_leader: teamL,
                  executive: exe,
                  send_email: Email,
                  whatsapp: Whatsapp,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  if (props.local !== undefined) {
                     props.getAllLeads("local", props.lead.id)
                  } else {
                     props.getAllLeads()
                  }
                  props.setmodal_xl(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
      if (value.type === "dead_request") {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/followup`,
               {
                  lead_id: props.lead && props.lead.id,
                  type: value.type,
                  action: value.action,
                  dead_reason_id: value.dead_reason_id,
                  message: value.message,
                  admin: admi,
                  team_leader: teamL,
                  priority: Priority,
                  executive: exe,
                  send_email: Email,
                  whatsapp: Whatsapp,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  if (props.local !== undefined) {
                     props.getAllLeads("local", props.lead.id)
                  } else {
                     props.getAllLeads()
                  }
                  props.setmodal_xl(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
   }

   useEffect(() => {
      if (props.lead && props.lead.type.includes("matured")) {
         setMatureSec(true)
         getMatureStatus()
         getLastOrderDetails()
         if (localStorage.getItem("company_name") == "krishlar") {
            updateOption("matured")
         }
      } else {
         if (localStorage.getItem("company_name") == "krishlar") {
            updateOption("followup")
         }
         updateOption("followup")
      }
      getFollowupCount()
   }, [])

   return (
      <>
         {modal_standard3 ? (
            <LeadModal3
               modal_standard3={modal_standard3}
               setmodal_standard3={setmodal_standard3}
               lead={lead}
               setLead={setLead}
               setIsOpen={props.setmodal_xl}
               getAllLeads={props.getAllLeads}
               local={props.local !== undefined ? true : false}
            />
         ) : (
            ""
         )}
         <div className="card">
            <div className="card-body">
               <AvForm
                  className="modal-title mt-0"
                  id="myModalLabel"
                  onValidSubmit={(e, v) => {
                     submitFollowup(e, v)
                  }}
               >
                  {props.typeMature ? (
                     <div>
                        <div className="mb-3 required">
                           <AvField
                              className="select-arrow"
                              label="Lead Status"
                              type="select"
                              required
                              name="type"
                              onChange={e => updateOption(e.target.value)}
                              value={
                                 localStorage.getItem("company_name") == "krishlar"
                                    ? "matured"
                                    : "matured"
                              }
                           >
                              <option value="" hidden>
                                 Select
                              </option>
                              {getCurrentUser() &&
                                 getCurrentUser().role_name === "Executive" ? (
                                 <>
                                    <option value="matured">Matured Followup</option>
                                    <option value="matured_dead_request">
                                       Matured Dead Request
                                    </option>
                                 </>
                              ) : (
                                 <>
                                    {/* <option value="followup">Followup</option> */}
                                    <option value="matured">Matured Followup</option>
                                    <option value="matured_dead">Matured Dead</option>
                                 </>
                              )}
                           </AvField>
                        </div>
                        {followupSec === true ? (
                           <>
                              <div className="mb-3 required">
                                 <AvField
                                    className="select-arrow"
                                    label="Followup Stages"
                                    type="select"
                                    name="followup_status_id"
                                    style={{ maxHeight: '200px !important', overflowY: 'auto !important' }}
                                    value={props.lead?.followup_status_id}
                                 >
                                    <option value="" hidden>
                                       Select
                                    </option>
                                    {allFollowup &&
                                       allFollowup.map((item, i) => (
                                          <option value={item.id} key={i}>
                                             {item.name}
                                          </option>
                                       ))}
                                 </AvField>
                              </div>
                           </>
                        ) : (
                           ""
                        )}
                        {matureSec ? (
                           <>
                              <div className="d-flex mt-2">
                                 <div className="mb-3 pe-2">
                                    <AvField
                                       label="First Order Amount"
                                       type="text"
                                       name="first_order"
                                       value={orderDetails.first_order_amount}
                                       disabled
                                    />
                                    {orderDetails.matured_date !== null ? (
                                       <span>{orderDetails.matured_date},{" "}
                                          {orderDetails.first_order_by}
                                       </span>
                                    ) : (
                                       " "
                                    )}
                                 </div>
                                 {orderDetails.total_orders <= 1 ?
                                    ""
                                    :
                                    <div className="mb-3">
                                       <AvField
                                          label="Last Order Amount"
                                          type="text"
                                          name="last_order"
                                          value={orderDetails.last_order_amount}
                                          disabled
                                       />
                                       {orderDetails.last_order_date !== null ? (
                                          <span>
                                             {orderDetails.last_order_date},{" "}
                                             {orderDetails.last_order_by}
                                          </span>
                                       ) : (
                                          ""
                                       )}
                                    </div>
                                 }
                              </div>
                              {orderDetails.lead_is_three_months_old === 1 ?
                                 <div className="d-flex mt-2">
                                    <div className="mb-3 pe-2">
                                       <AvField
                                          label="Last 3 months billing/total billing"
                                          type="text"
                                          name="total_billing"
                                          value={`${orderDetails.last_three_month_avg}/${orderDetails.last_three_months_total}`}
                                          disabled
                                       />
                                    </div>
                                    <div className="mb-3">
                                       <AvField
                                          label="Last 3 months/total orders"
                                          type="text"
                                          name="total_orders"
                                          value={`${orderDetails.last_three_month_orders}/${orderDetails.total_orders}`}
                                          disabled
                                       />
                                    </div>
                                 </div>
                                 : ""
                              }
                              <div className="mb-3">
                                 <AvField
                                    label="I have received an order"
                                    type="checkbox"
                                    name="order"
                                    onClick={(e) => setShowOrderValue(e.target.checked)}
                                 />
                              </div>
                              {showOrderValue ? (
                                 <>
                                    <div className="mb-3 required">
                                       <AvField
                                          label="Order Value"
                                          type="number"
                                          onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                          name="order_value"
                                          placeholder="Amount"
                                          required
                                       />
                                       <i>*If you have already recieved a new order.</i>
                                    </div>
                                    <div className="mb-3">
                                       <AvField
                                          label="Payment Recieved"
                                          type="number"
                                          onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                          name="payment_received"
                                          placeholder="Payment Amount"
                                       />
                                       <i>*Payment received against this order.</i>
                                    </div>
                                 </>
                              ) : (
                                 <div className="mb-3 required">
                                    <AvField
                                       className="select-arrow"
                                       label="Matured Stages"
                                       type="select"
                                       name="mature_status_id"
                                       // onChange={e => getMatureValue(e)}
                                       value={props.lead?.mature_status_id}
                                    >
                                       <option value="" hidden>
                                          Select
                                       </option>
                                       {allMature &&
                                          allMature.map((item, i) => (item.name !== "Order Received" ?
                                             <option value={item.id} key={i}>
                                                {item.name}
                                             </option>
                                             :
                                             ""
                                          ))}
                                    </AvField>
                                 </div>
                              )}
                           </>
                        ) : (
                           ""
                        )}
                        {deadSec ? (
                           <div className="mb-3 required">
                              <AvField
                                 className="select-arrow"
                                 label="Dead Reason"
                                 type="select"
                                 name="dead_reason_id"
                              >
                                 <option value="" hidden>
                                    Select
                                 </option>
                                 {allDead &&
                                    allDead.map((item, i) => (
                                       <option value={item.id} key={i}>
                                          {item.reason}
                                       </option>
                                    ))}
                              </AvField>
                           </div>
                        ) : (
                           <div className="mt-2 row">
                              <div className="mb-3 col-md-4 required">
                                 <label className="form-group">Next Followup Date</label>
                                 <DatePicker
                                    className="w-100 form-control"
                                    dateFormat="yyyy-MM-dd"
                                    name="next_followup_date"
                                    selected={startDate}
                                    minDate={moment().toDate()}
                                    onChange={date => { setStartDate(date), getFollowupCount(date) }}
                                 />
                                 <span className="fst-italic">{followupMessage?.count !== 0 ? <>
                                    <a href={`/leads?type=followup,matured&nextFollowupDate=${formatDate(startDate)}`} target="_blank" className="text-info">
                                       {followupMessage.count} Followup(s){" "}
                                    </a>
                                    are scheduled for the day.
                                 </> : "No Followup is scheduled for the day."}</span>
                              </div>
                              <div className="mb-3 col-md-4">
                                 <label className="form-group">
                                    Next Followup Time
                                 </label>
                                 <TimePicker
                                    name="next_followup_time"
                                    // colorPalette="dark"
                                    time={time}
                                    theme="material"
                                    timeMode="12"
                                    onTimeChange={(e) => handleTimeChange(e)}
                                 />
                              </div>
                              <div className="mb-3 col-md-4 ">
                                 <AvRadioGroup inline name="mature_status_options">
                                    <AvRadio label="Re-order Date" value="reorder" />
                                    <AvRadio label="Payment Reminder" value="payment_reminder" />
                                 </AvRadioGroup>
                              </div>
                           </div>
                        )}
                        <div className="mb-3 required">
                           <AvField
                              label="Message"
                              type="textarea"
                              name="message"
                              placeholder="Message"
                           />
                        </div>
                        {followupSec || dead_reqSec ?
                           <div className="mb-3">
                              <AvField label="Priority" type="checkbox" name="priority" />
                           </div>
                           :
                           <>
                              {matureSec ?
                                 <div className="d-flex mt-2">
                                    {getCurrentUser()?.crm_permission == "1" || getCurrentUser()?.crmpro_permission == "1" ?
                                       <div className="mb-3 me-4">
                                          <AvField
                                             label="Priority"
                                             type="checkbox"
                                             name="priority"
                                          />
                                       </div>
                                       :
                                       ""
                                    }
                                    {followupMessage?.follow_event_added === 1 ?
                                       <div className="mb-3">
                                          <AvField label="Send Reminder Message" type="checkbox" name="send_followup_reminder" />
                                       </div>
                                       :
                                       ""
                                    }
                                 </div>
                                 : ""
                              }
                           </>
                        }
                        <div className="mb-3">
                           <label>Select Action</label>
                           <AvRadioGroup
                              inline
                              name="action"
                              defaultValue="call"
                              required
                           >
                              <AvRadio label="Call" value="call" />
                              <AvRadio label="Meeting" value="meeting" />
                              <AvRadio label="Update" value="update" />
                              <AvRadio label="Whatsapp" value="whatsapp" />
                           </AvRadioGroup>
                        </div>
                        <div className="d-flex mt-2">
                           {(props.lead && props.lead.leadholder_role == null) ||
                              (props.lead &&
                                 props.lead.leadholder_role == "Administrator") ? (
                              ""
                           ) : (
                              <>
                                 {getCurrentUser() &&
                                    getCurrentUser().role_name == "Administrator" ? (
                                    <div className="mb-3 me-2">
                                       <label>Send Notification to:</label>
                                       <AvCheckboxGroup inline name="senior">
                                          <AvCheckbox
                                             label="Team Leader"
                                             value="team_leader"
                                          ></AvCheckbox>{" "}
                                          {props.lead.leadholder_role == "Team Leader" ? (
                                             ""
                                          ) : (
                                             <AvCheckbox
                                                label="Executive"
                                                value="executive"
                                             ></AvCheckbox>
                                          )}
                                       </AvCheckboxGroup>
                                    </div>
                                 ) : (
                                    ""
                                 )}
                                 {getCurrentUser() &&
                                    getCurrentUser().role_name == "Team Leader" ? (
                                    <div className="mb-3 me-2">
                                       <label>Send Notification to:</label>
                                       <AvCheckboxGroup inline name="senior">
                                          <AvCheckbox
                                             label="Administrator"
                                             value="administrator"
                                          ></AvCheckbox>{" "}
                                          {props.lead.leadholder_role == "Team Leader" ? (
                                             ""
                                          ) : (
                                             <AvCheckbox
                                                label="Executive"
                                                value="executive"
                                             ></AvCheckbox>
                                          )}
                                       </AvCheckboxGroup>
                                    </div>
                                 ) : (
                                    ""
                                 )}
                                 {getCurrentUser() &&
                                    getCurrentUser().role_name == "Executive" ? (
                                    <div className="mb-3 me-2">
                                       <label>Send Notification to:</label>
                                       <AvCheckboxGroup inline name="senior">
                                          <AvCheckbox
                                             label="Team Leader"
                                             value="team_leader"
                                          ></AvCheckbox>{" "}
                                          <AvCheckbox
                                             label="Administrator"
                                             value="administrator"
                                          ></AvCheckbox>
                                       </AvCheckboxGroup>
                                    </div>
                                 ) : (
                                    ""
                                 )}
                              </>
                           )}
                           <div
                              className={
                                 (props.lead && props.lead.leadholder_role == null) ||
                                    (props.lead &&
                                       props.lead.leadholder_role == "Administrator")
                                    ? "mb-3"
                                    : "mb-3 ms-5"
                              }
                           >
                              <label>Send</label>
                              <div className="d-flex">
                                 <div className="mb-3 me-2">
                                    <AvField
                                       label="Email"
                                       type="checkbox"
                                       name="send_email"
                                       style={{ background: `${getCurrentUser()?.email_enabled === 1 ? "" : "lightgray"}` }}
                                       disabled={getCurrentUser()?.email_enabled === 1 ? false : true}
                                    />
                                 </div>
                                 <div className="mb-3 ms-4">
                                    <AvField
                                       label="Whatsapp"
                                       type="checkbox"
                                       name="whatsapp"
                                       style={{ background: `${getCurrentUser()?.whatsapp_enabled === 1 ? "" : "lightgray"}` }}
                                       disabled={getCurrentUser()?.whatsapp_enabled === 1 ? false : true}
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  ) : (
                     <>
                        <div className="mb-3 required">
                           <AvField
                              className="select-arrow"
                              label="Lead Status"
                              type="select"
                              required
                              name="type"
                              onChange={e => updateOption(e.target.value, "firstTime")}
                              value={
                                 localStorage.getItem("company_name") == "krishlar"
                                    ? "followup"
                                    : "followup"
                              }
                           >
                              <option value="" hidden>
                                 Select
                              </option>
                              {getCurrentUser() &&
                                 getCurrentUser().role_name === "Executive" ? (
                                 <>
                                    <option value="followup">Followup</option>
                                    <option value="matured">Matured</option>
                                    <option value="dead_request">Dead Request</option>
                                 </>
                              ) : (
                                 <>
                                    <option value="followup">Followup</option>
                                    <option value="matured">Matured</option>
                                    <option value="dead">Dead</option>
                                 </>
                              )}
                           </AvField>
                        </div>
                        {followupSec === true ? (
                           <>
                              <div className="mb-3 required">
                                 <AvField
                                    className="select-arrow"
                                    label="Followup Stages"
                                    type="select"
                                    name="followup_status_id"
                                    value={props.lead?.followup_status_id}
                                 >
                                    <option value="" hidden>
                                       Select
                                    </option>
                                    {allFollowup &&
                                       allFollowup.map((item, i) => (
                                          <option value={item.id} key={i}>
                                             {item.name}
                                          </option>
                                       ))}
                                 </AvField>
                              </div>
                              <div className="d-flex mt-2">
                                 <div className="mb-3 pe-2 required">
                                    <label className="form-group">Next Followup Date</label>
                                    <DatePicker
                                       className="w-100 form-control"
                                       dateFormat="yyyy-MM-dd"
                                       name="next_followup_date"
                                       selected={startDate}
                                       minDate={moment().toDate()}
                                       onChange={date => { setStartDate(date), getFollowupCount(date) }}
                                    />
                                    <span className="fst-italic">{followupMessage?.count !== 0 ? <>
                                       <a href={`/leads?type=followup,matured&nextFollowupDate=${formatDate(startDate)}`} target="_blank" className="text-info">
                                          {followupMessage.count} Followup(s){" "}
                                       </a>
                                       are scheduled for the day.
                                    </> : "No Followup is scheduled for the day."}</span>
                                 </div>
                                 <div className="">
                                    <label className="form-group">
                                       Next Followup Time
                                    </label>
                                    <TimePicker
                                       name="next_followup_time"
                                       // colorPalette="dark"
                                       time={time}
                                       theme="material"
                                       timeMode="12"
                                       onTimeChange={(e) => handleTimeChange(e)}
                                    />
                                 </div>
                              </div>
                           </>
                        ) : (
                           ""
                        )}
                        {matureSec ? (
                           <>
                              <div className="mb-4 required">
                                 <AvField
                                    label="Order Value"
                                    type="number"
                                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false }, setShowOrderValue(true))}
                                    min="0"
                                    name="order_value"
                                    placeholder="Amount"
                                    required
                                 />
                                 <i>*If you have already recieved a new order.</i>
                              </div>
                              <div className="mb-4">
                                 <AvField
                                    label="Payment Recieved"
                                    type="number"
                                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                    min="0"
                                    name="payment_received"
                                    placeholder="Payment Amount"
                                 />
                                 <i>*Payment received against this order.</i>
                              </div>
                              {getCurrentUser().crm_permission ||
                                 getCurrentUser().crmpro_permission == 1 ? (
                                 <div className="mt-2 row">
                                    <div className="mb-3 col-md-4 required">
                                       <label className="form-group">Next Followup Date</label>
                                       <DatePicker
                                          className="w-100 form-control"
                                          dateFormat="yyyy-MM-dd"
                                          name="next_followup_date"
                                          selected={startDate}
                                          minDate={moment().toDate()}
                                          onChange={date => { setStartDate(date), getFollowupCount(date) }}
                                       />
                                       <span className="fst-italic">{followupMessage?.count !== 0 ? <>
                                          <a href={`/leads?type=followup,matured&nextFollowupDate=${formatDate(startDate)}`} target="_blank" className="text-info">
                                             {followupMessage.count} Followup(s){" "}
                                          </a>
                                          are scheduled for the day.
                                       </> : "No Followup is scheduled for the day."}</span>
                                    </div>
                                    <div className="mb-3 col-md-4">
                                       <label className="form-group">
                                          Next Followup Time
                                       </label>
                                       <TimePicker
                                          name="next_followup_time"
                                          // colorPalette="dark"
                                          time={time}
                                          theme="material"
                                          timeMode="12"
                                          onTimeChange={(e) => handleTimeChange(e)}
                                       />
                                    </div>
                                    <div className="mb-3 col-md-4 ">
                                       <AvRadioGroup inline name="mature_status_options">
                                          <AvRadio label="Re-order Date" value="reorder" />
                                          <AvRadio label="Payment Reminder" value="payment_reminder" />
                                       </AvRadioGroup>
                                    </div>
                                 </div>
                              ) : (
                                 <div className="d-flex mt-2 d-none">
                                    <div className="mb-3 pe-2">
                                       <label className="form-group">
                                          Next Followup Date
                                       </label>
                                       <DatePicker
                                          className="w-100 form-control"
                                          dateFormat="yyyy-MM-dd"
                                          name="next_followup_date"
                                          selected={startDate}
                                          onChange={date => { setStartDate(date), getFollowupCount(date) }}
                                       />
                                       <span className="fst-italic">{followupMessage?.count !== 0 ?
                                          <>
                                             <a href={`/leads?type=followup,matured&nextFollowupDate=${formatDate(startDate)}`} target="_blank" className="text-info">
                                                {followupMessage.count} Followup(s){" "}
                                             </a>
                                             are scheduled for the day.
                                          </>
                                          : "No Followup is scheduled for the day."}</span>
                                    </div>
                                    <div className="mb-3">
                                       <label className="form-group">
                                          Next Followup Time
                                       </label>
                                       <TimePicker
                                          name="next_followup_time"
                                          // colorPalette="dark"
                                          time={time}
                                          theme="material"
                                          timeMode="12"
                                          onTimeChange={(e) => handleTimeChange(e)}
                                       />
                                    </div>
                                 </div>
                              )}
                           </>
                        ) : (
                           ""
                        )}
                        {deadSec === true ? (
                           <>
                              <div className="mb-3 required">
                                 <AvField
                                    className="select-arrow"
                                    label="Dead Reason"
                                    type="select"
                                    name="dead_reason_id"
                                 >
                                    <option value="" hidden>
                                       Select
                                    </option>
                                    {allDead &&
                                       allDead.map((item, i) => (
                                          <option value={item.id} key={i}>
                                             {item.reason}
                                          </option>
                                       ))}
                                 </AvField>
                              </div>
                           </>
                        ) : (
                           ""
                        )}
                        <div className="mb-3 required">
                           <AvField
                              label="Message"
                              type="textarea"
                              name="message"
                              placeholder="Message"
                           />
                        </div>
                        {followupSec === true ? (
                           <div className="d-flex mt-2">
                              <div className="mb-3 pe-4">
                                 <AvField label="Priority" type="checkbox" name="priority" />
                              </div>
                              {followupMessage?.follow_event_added === 1 ?
                                 <div className="mb-3">
                                    <AvField label="Send Reminder Message" type="checkbox" name="send_followup_reminder" />
                                 </div>
                                 :
                                 ""
                              }
                           </div>
                        ) : (
                           <>
                              {matureSec ?
                                 <>
                                    {getCurrentUser()?.crm_permission == "1" || getCurrentUser()?.crmpro_permission == "1" ?
                                       <div className="mb-3">
                                          <AvField
                                             label="Priority"
                                             type="checkbox"
                                             name="priority"
                                          />
                                       </div>
                                       :
                                       ""
                                    }
                                 </>
                                 : ""
                              }
                           </>
                        )}
                        <div className="mb-3">
                           <label>Select Action</label>
                           <AvRadioGroup
                              inline
                              name="action"
                              defaultValue="call"
                              required
                           >
                              <AvRadio label="Call" value="call" />
                              <AvRadio label="Meeting" value="meeting" />
                              <AvRadio label="Update" value="update" />
                              <AvRadio label="Whatsapp" value="whatsapp" />
                           </AvRadioGroup>
                        </div>
                        {matureSec ?
                           <div className="d-flex">
                              <div className="mb-3">
                                 <AvField
                                    label="Send Email"
                                    type="checkbox"
                                    name="send_email"
                                    style={{ background: `${getCurrentUser()?.email_enabled === 1 ? "" : "lightgray"}` }}
                                    disabled={getCurrentUser()?.email_enabled === 1 ? false : true}
                                 />
                              </div>
                              <div className="mb-3 ms-3">
                                 <AvField
                                    label="Send Whatsapp"
                                    type="checkbox"
                                    name="whatsapp"
                                    style={{ background: `${getCurrentUser()?.whatsapp_enabled === 1 ? "" : "lightgray"}` }}
                                    disabled={getCurrentUser()?.whatsapp_enabled === 1 ? false : true}
                                 />
                              </div>
                           </div>
                           :
                           ""
                        }
                        {(props.lead && props.lead.leadholder_role == null) ||
                           (props.lead &&
                              props.lead.leadholder_role == "Administrator") ? (
                           ""
                        ) : (
                           <>
                              {getCurrentUser() &&
                                 getCurrentUser().role_name == "Administrator" ? (
                                 <div className="mb-3 me-2">
                                    <label>Send Notification to:</label>
                                    <AvCheckboxGroup inline name="senior">
                                       <AvCheckbox
                                          label="Team Leader"
                                          value="team_leader"
                                       ></AvCheckbox>{" "}
                                       {props.lead.leadholder_role == "Team Leader" ? (
                                          ""
                                       ) : (
                                          <AvCheckbox
                                             label="Executive"
                                             value="executive"
                                          ></AvCheckbox>
                                       )}
                                    </AvCheckboxGroup>
                                 </div>
                              ) : (
                                 ""
                              )}
                              {getCurrentUser() &&
                                 getCurrentUser().role_name == "Team Leader" ? (
                                 <div className="mb-3 me-2">
                                    <label>Send Notification to:</label>
                                    <AvCheckboxGroup inline name="senior">
                                       <AvCheckbox
                                          label="Administrator"
                                          value="administrator"
                                       ></AvCheckbox>{" "}
                                       {props.lead.leadholder_role == "Team Leader" ? (
                                          ""
                                       ) : (
                                          <AvCheckbox
                                             label="Executive"
                                             value="executive"
                                          ></AvCheckbox>
                                       )}
                                    </AvCheckboxGroup>
                                 </div>
                              ) : (
                                 ""
                              )}
                              {getCurrentUser() &&
                                 getCurrentUser().role_name == "Executive" ? (
                                 <div className="mb-3 me-2">
                                    <label>Send Notification to:</label>
                                    <AvCheckboxGroup inline name="senior">
                                       <AvCheckbox
                                          label="Administrator"
                                          value="administrator"
                                       ></AvCheckbox>{" "}
                                       <AvCheckbox
                                          label="Team Leader"
                                          value="team_leader"
                                       ></AvCheckbox>
                                    </AvCheckboxGroup>
                                 </div>
                              ) : (
                                 ""
                              )}
                           </>
                        )}
                     </>
                  )}
                  <div className="modal-footer">
                     <div className="text-center">
                        {loading ? (
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                              disabled
                           >
                              Loading
                           </button>
                        ) : (
                           <button
                              className="btn btn-primary"
                              type="submit"
                           >
                              Update
                           </button>
                        )}
                     </div>
                  </div>
               </AvForm>
            </div>
         </div>
         {/* {loader} */}
      </>
   )
}
export default CupdateTable
