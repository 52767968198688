import PropTypes from 'prop-types'
import React,{useState} from "react"
import MetaTags from 'react-meta-tags';
import PricingTable from '../../components/plans';
import {
  Container,
  Row,
  Col
} from "reactstrap"

const Pricingtable = () => {
	return (
		<React.Fragment>
				<MetaTags>
					<title>Leads | KlikCRM</title>
				</MetaTags>
	        <PricingTable />
    </React.Fragment>
	)
}
export default Pricingtable;