import React, { useState, useEffect } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser } from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import ResendCampaign from "./resendCampaign"
import LongText from "../../MessageField"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

let selectedRoleId = []

const CampaignList = props => {
   const [campaign, setCampaign] = useState(props.data)
   const [confirm_alert, setconfirm_alert] = useState(false)
   const [success_dlg, setsuccess_dlg] = useState(false)
   const [dynamic_title, setdynamic_title] = useState("")
   const [dynamic_description, setdynamic_description] = useState("")
   const [isOpen, setIsOpen] = useState(false)
   const [campaign_model, setcampaign_model] = useState(false)

   const deleteCampaign = e => {
      axios
         .delete(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/campaigns/${e} `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setCampaign("")
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const editCampaign = () => {
      setIsOpen(true)
      setcampaign_model(true)
   }

   useEffect(() => {
      selectedRoleId = []
   }, [])

   const getSelectedId = (e, check) => {
      if (check.target.checked) {
         selectedRoleId.push(e)
         const uniqueID = selectedRoleId.filter((val, id, array) => {
            return array.indexOf(val) == id
         })
         selectedRoleId = uniqueID
         props.setLeadsSelectedIds(selectedRoleId)
      } else {
         selectedRoleId = props.leadsSelectedIds
         var array = selectedRoleId
         var index = array.indexOf(e)
         if (index !== -1) {
            array.splice(index, 1)
         }
         props.setLeadsSelectedIds(selectedRoleId)
      }
      if (selectedRoleId.length == 0) {
         props.setBlur(false)
      } else {
         props.setBlur(true)
      }
   }

   if (campaign === "") {
      return ""
   } else
      return (
         <>
            {isOpen ? (
               <ResendCampaign
                  campaign_model={campaign_model}
                  setcampaign_model={setcampaign_model}
                  campaign={campaign}
                  setCampaign={setCampaign}
                  setIsOpen={setIsOpen}
               />
            ) : null}
            <tr key={props.i}>
               <td>
                  <input
                     onChange={e => getSelectedId(campaign.id, e)}
                     name="chk"
                     value={campaign.id}
                     className="p-0 d-inline-block"
                     type="checkbox"
                  />
               </td>
               <td>{props.counter + props.i}</td>
               <td>{campaign.name}</td>
               <td>
                  <LongText content={campaign.message} />
               </td>
               <td>
                  {campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1)}
               </td>
               <td>
                  {campaign.status === "sent" ?
                     <button
                        className="btn btn-warning"
                        onClick={() => {
                           editCampaign(campaign.id)
                        }}
                     >
                        Resend
                     </button>
                     :
                     ""
                  } 
                  <a
                     className="text-danger ms-2"
                     onClick={() => {
                        setconfirm_alert(true)
                     }}
                  >
                     <i className="fas fa-trash-alt" />
                  </a>
                  <Link className="ms-2" to={`/leads?campaign_id=${campaign.id}`}>
                     <i className="fa fa-eye"></i>
                  </Link>
                  {confirm_alert ? (
                     <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                           setconfirm_alert(false)
                           setsuccess_dlg(true)
                           deleteCampaign(campaign.id)
                           setdynamic_title("Deleted")
                           setdynamic_description("Your file has been deleted.")
                        }}
                        onCancel={() => setconfirm_alert(false)}
                     >
                        You won't be able to revert this!
                     </SweetAlert>
                  ) : null}
               </td>
            </tr>
         </>
      )
}
export default CampaignList
