import React, { useState, useEffect } from "react"
import {
   Row,
   Col,
   Modal,
   Card,
   CardBody,
   Nav,
   NavItem,
   NavLink,
   TabContent,
   TabPane,
} from "reactstrap"
import { getCurrentUser } from "../../../helpers/Utils"
import axios from "axios"
import LongText from "../../MessageField"
import ReminderModal from "../../Reminder"
import classnames from "classnames"
import CcomplaintTable from "../../Customer-Profile/C-complaints"
import CschemeTable from "../../Customer-Profile/C-scheme"
import CproductTable from "../../Customer-Profile/C-product"
import CledgerTable from "../../Customer-Profile/C-ledger"
import CorderTable from "../../Customer-Profile/C-order"
import CQuotationsTable from "../../Customer-Profile/C-Quotations"
import CproformaTable from "../../Customer-Profile/C-proforma"
import CupdateTable from "../../Customer-Profile/C-update"
import ChistoryTable from "../../Customer-Profile/C-history"
import CprofileTable from "../../Customer-Profile/C-profile"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

let selectedRoleId = []

const TodayList = props => {
   const [log, setLog] = useState(props.data)
   const [modal_reminder, setmodal_reminder] = useState(false)
   const [isOpen2, setIsOpen2] = useState(false)
   const [customActiveTab, setCustomActiveTab] = useState(2)
   const [error, setError] = useState("")
   const [historyData, setHistoryData] = useState([])
   const [typeMature, setTypeMature] = useState(
      log.lead?.type.includes("matured") ? true : false
   )
   const [profileData, setProfileData] = useState([])
   const [modal_xl, setmodal_xl] = useState(false)
   const [updateTab, setUpdateTab] = useState(false)
   const [historyTab, setHistoryTab] = useState(false)
   const [productTab, setProductTab] = useState(false)
   const [proformaTab, setProformaTab] = useState(false)
   const [orderTab, setOrderTab] = useState(false)
   const [quotationsTab, setQuotationsTab] = useState(false)
   const [ledgerTab, setLedgerTab] = useState(false)
   const [schemeTab, setSchemeTab] = useState(false)
   const [complaintTab, setComplaintTab] = useState(false)

   const updateReminder = e => {
      setIsOpen2(true)
      setmodal_reminder(true)
   }

   useEffect(() => {
      selectedRoleId = []
   }, [])

   const getSelectedId = (e, check) => {
      if (check.target.checked) {
         selectedRoleId.push(e)
         const uniqueID = selectedRoleId.filter((val, id, array) => {
            return array.indexOf(val) == id
         })
         selectedRoleId = uniqueID
         props.setLeadsSelectedIds(selectedRoleId)
      } else {
         selectedRoleId = props.leadsSelectedIds
         var array = selectedRoleId
         var index = array.indexOf(e)
         if (index !== -1) {
            array.splice(index, 1)
         }
         props.setLeadsSelectedIds(selectedRoleId)
      }
   }

   const getProfileData = e => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead/${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setProfileData(res.data.data)
               setmodal_xl(true)
               setUpdateTab(true)
            } else {
               setError(res.data.message)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const toggleCustom = tab => {
      if (customActiveTab !== tab) {
         setCustomActiveTab(tab)
      }
      if (tab == 2) {
         setUpdateTab(true)
      }
      if (tab == 3) {
         setHistoryTab(true)
      }
      if (tab == 4) {
         setProductTab(true)
      }
      if (tab == 5) {
         setProformaTab(true)
      }
      if (tab == 6) {
         setOrderTab(true)
      }
      if (tab == 7) {
         setQuotationsTab(true)
      }
      if (tab == 8) {
         setLedgerTab(true)
      }
      if (tab == 9) {
         setSchemeTab(true)
      }
      if (tab == 10) {
         setComplaintTab(true)
      }
   }

   useEffect(() => {
      const close = e => {
         if (e.key === "Escape") {
            setModal(false)
            setmodal_xl(false)
         }
      }
      window.addEventListener("keydown", close)
      return () => window.removeEventListener("keydown", close)
   }, [])

   if (log === "") {
      return ""
   } else
      return (
         <>
            {isOpen2 ? (
               <ReminderModal
                  modal_reminder={modal_reminder}
                  setmodal_reminder={setmodal_reminder}
                  lead={log.lead_id}
                  setLead={setLog}
                  setIsOpen2={setIsOpen2}
               />
            ) : null}
            <tr key={props.i}>
               <td>
                  <input
                     onChange={e => getSelectedId(log.id, e)}
                     name="chk"
                     value={log.id}
                     className="p-0 d-inline-block"
                     type="checkbox"
                  />
               </td>
               <td>{props.counter + props.i}</td>
               <td>
                  <span
                     style={{ cursor: "pointer" }}
                     onClick={e => {
                        getProfileData(log.lead_id), setCustomActiveTab(1)
                     }}
                     className="text-info"
                  >
                     {log && log.lead_name}
                  </span>
                  <br />
                  {getCurrentUser().crm_permission == 0 &&
                     getCurrentUser().crmpro_permission == 0 &&
                     log.type == "matured" ? (
                     <span
                        className="btn badge bg-success disabled"
                        onClick={e => {
                           getProfileData(log.lead_id), setCustomActiveTab(2)
                        }}
                     >
                        Update Status
                     </span>
                  ) : (
                     <span
                        className="btn badge bg-success"
                        onClick={e => {
                           getProfileData(log.lead_id), setCustomActiveTab(2)
                        }}
                     >
                        Update Status
                     </span>
                  )}
                  <br />
                  {log.type == "dead" || log.type == "dead_request" ? (
                     ""
                  ) : (
                     localStorage.getItem("company_name") === "demo" ?
                     <span
                        className="btn badge bg-purple"
                        onClick={e => updateReminder(log.lead_id)}
                     >
                        Set Reminder
                     </span>
                     :
                     ""
                  )}
               </td>
               <td>
                  <LongText content={log.message} />
               </td>
               <td>
                  {log.previous_message !== null ? (
                     <LongText content={log.previous_message} />
                  ) : (
                     ""
                  )}
               </td>
               <td>{log.update_by}</td>
               <td>
                  {log.lead?.lead_label?.map((item, i) => (
                     <div key={i}>
                        <span className="badge" style={{ backgroundColor: "#f39c12" }}>
                           {item.name}
                        </span>{" "}
                        <br />
                     </div>
                  ))}
               </td>
               <td>{log.lead_source}</td>
               <td>{log.action}</td>
               <td>
                  {log.type == "new" ? (
                     <span className="badge" style={{ backgroundColor: "#58a0c3" }}>
                        New
                     </span>
                  ) : (
                     ""
                  )}
                  {log.type == "followup" ? (
                     <span className="badge" style={{ backgroundColor: "orange" }}>
                        Followup
                     </span>
                  ) : (
                     ""
                  )}
                  {log.type === "matured" ? (
                     <span className="badge" style={{ backgroundColor: "green" }}>
                        Matured
                     </span>
                  ) : (
                     ""
                  )}
                  {log.type === "dead" ? (
                     <span className="badge" style={{ backgroundColor: "red" }}>
                        Dead
                     </span>
                  ) : (
                     ""
                  )}
                  {log.type === "repeated" ? (
                     <span className="badge" style={{ backgroundColor: "purple" }}>
                        Repeated
                     </span>
                  ) : (
                     ""
                  )}
                  {log.type === "transfer request" ? (
                     <span className="badge" style={{ backgroundColor: "pink" }}>
                        Transfer Request
                     </span>
                  ) : (
                     ""
                  )}
                  {log.type === "dead_request" ? (
                     <span className="badge" style={{ backgroundColor: "purple" }}>
                        Dead Request
                     </span>
                  ) : (
                     ""
                  )}
                  {log.type === "high priority" ? (
                     <span className="badge" style={{ backgroundColor: "green" }}>
                        High Priority
                     </span>
                  ) : (
                     ""
                  )}
                  {log.type === "matured_dead" ? (
                     <span className="badge" style={{ backgroundColor: "#D6BD42" }}>
                        Matured Dead
                     </span>
                  ) : (
                     ""
                  )}
                  <br />
                  <span className="badge" style={{ backgroundColor: "#f39c12" }}>
                     {log.dead_status}
                  </span>{" "}
                  <br />
                  <span className="badge" style={{ backgroundColor: "#045fcf" }}>
                     {log.followup_status}
                  </span>{" "}
                  <br />
                  <span className="badge" style={{ backgroundColor: "green" }}>
                     {log.mature_status}
                  </span>
                  <br />
                  <span className="badge">{log.matured_amount}</span>
               </td>
               <td>{log.updated_at}</td>
            </tr>
            <Modal isOpen={modal_xl} centered={true} size="xl">
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     {profileData.name}'s Profile
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => setmodal_xl(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <Row
                     className="m-0 p-3"
                     style={{
                        background: "#efefef",
                     }}
                  >
                     <Col md={log.lead?.type.includes("matured") ? 4 : 8}>
                        <span>
                           {log.lead?.firm_name == null || log.lead?.firm_name == "" ? (
                              ""
                           ) : (
                              <>
                                 <span>
                                    <span className="fw-bold">Firm name:</span>{" "}
                                    {log.lead?.firm_name}
                                 </span>
                                 <br />
                              </>
                           )}
                        </span>
                        <span>
                           <span className="fw-bold">Email: </span>
                           <a
                              className="text-info"
                              target="_blank"
                              href={`mailto:${log.lead?.email}`}
                           >
                              {log.lead?.email}
                           </a>
                        </span>{" "}
                        <br />
                        <span>
                           <span className="fw-bold">Phone:</span>{" "}
                           {log.lead?.country_code !== null ? (
                              <>{log.lead?.country_code}</>
                           ) : (
                              "+91"
                           )}{" "}
                           <a
                              className="text-info"
                              href={`tel://+91${log.lead?.number}`}
                              target="_blank"
                           >
                              {log.lead?.number}
                           </a>
                        </span>{" "}
                        <a
                           style={{ cursor: "pointer", color: "#08913b" }}
                           target="_blank"
                           href={`https://web.whatsapp.com/send?phone=${log.lead?.number}`}
                        >
                           <i className="fab fa-whatsapp ms-2 fa-lg"></i>
                        </a>{" "}
                        <br />
                        <span>
                           <span className="fw-bold">Alternate Number:</span>{" "}
                           {log.lead?.alternate_number !== null ? (
                              <>
                                 {log.lead?.country_code !== null ? (
                                    <> {log.lead?.country_code}</>
                                 ) : (
                                    "+91"
                                 )}
                                 {" "}
                                 <a
                                    className="text-info"
                                    href={`tel://+91${log.lead?.alternate_number}`}
                                    target="_blank"
                                 >
                                    {log.lead?.alternate_number}
                                 </a>
                                 <a
                                    style={{ cursor: "pointer", color: "#08913b" }}
                                    target="_blank"
                                    href={`https://web.whatsapp.com/send?phone=${log.lead?.alternate_number}`}
                                 >
                                    <i className="fab fa-whatsapp ms-2 fa-lg"></i>
                                 </a>{" "}
                              </>
                           ) : (
                              ""
                           )}
                        </span>{" "}
                        <br />
                        <span>
                           <span className="fw-bold">Location:</span>{" "}
                           {log.lead?.city !== null ? <>{log.lead?.city},</> : ""}{" "}
                           {log.lead?.district !== null ? (
                              <>{log.lead?.district},</>
                           ) : (
                              ""
                           )}{" "}
                           {log.lead?.state !== null ? <>{log.lead?.state},</> : ""}{" "}
                           {log.lead?.country}
                        </span>{" "}
                        <br />
                        <span>
                           {log.lead?.pin_code !== null ? (
                              <>
                                 <span className="fw-bold">PIN:</span> {log.lead?.pin_code}
                              </>
                           ) : (
                              ""
                           )}
                        </span>
                     </Col>
                     <Col md={log.lead?.type.includes("matured") ? 5 : 4}>
                        {log.lead?.matured_date !== null ? (
                           <>
                              <span>
                                 <span className="fw-bold">Customer Since:</span>{" "}
                                 {log.lead?.matured_date}
                              </span>
                              <br />
                              <span className="fw-bold">Products Ordered:</span>
                              <br />
                              <LongText content={log.lead?.products} />
                           </>
                        ) : (
                           ""
                        )}
                        <br />
                        <span>
                           <span className="fw-bold">Last Updated:</span>{" "}
                           {log.lead?.updated_at}
                        </span>
                     </Col>
                     {log.lead?.type.includes("matured") ? (
                        <Col md={3}>
                           <span className="fw-bold d-flex justify-content-center">
                              Business Done/Target
                           </span>
                           <div className="p-2" style={{ border: "gray solid 2px" }}>
                              <span>
                                 <span className="fw-bold">Monthly: </span>
                                 <span
                                    dangerouslySetInnerHTML={{
                                       __html: log.lead?.monthly_bussiness_target,
                                    }}
                                 />
                              </span>{" "}
                              <br />
                              <span>
                                 <span className="fw-bold">Last 3 Months: </span>
                                 <span
                                    dangerouslySetInnerHTML={{
                                       __html: log.lead?.three_months_bussiness,
                                    }}
                                 />
                              </span>{" "}
                              <br />
                              <span>
                                 <span className="fw-bold">Overall: </span>
                                 <span
                                    dangerouslySetInnerHTML={{
                                       __html: log.lead?.overall_bussiness_target,
                                    }}
                                 />
                              </span>
                           </div>
                        </Col>
                     ) : (
                        ""
                     )}
                  </Row>
                  <Nav
                     tabs
                     style={{
                        justifyContent: "center",
                        background: "#efefef",
                     }}
                  >
                     <NavItem>
                        <NavLink
                           style={{ cursor: "pointer" }}
                           className={classnames({ active: customActiveTab === 1 })}
                           onClick={() => {
                              toggleCustom(1)
                           }}
                        >
                           {" "}
                           Profile
                        </NavLink>
                     </NavItem>
                     <NavItem>
                        {getCurrentUser().crm_permission == 0 &&
                           getCurrentUser().crmpro_permission == 0 &&
                           log.lead?.type.includes("matured") ? (
                           <NavLink
                              style={{ pointerEvents: "none", opacity: "0.25" }}
                              className={classnames({ active: customActiveTab === 2 })}
                              onClick={() => {
                                 toggleCustom(2)
                              }}
                           >
                              {" "}
                              Update Here
                           </NavLink>
                        ) : (
                           <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({ active: customActiveTab === 2 })}
                              onClick={() => {
                                 toggleCustom(2)
                              }}
                           >
                              {" "}
                              Update Here
                           </NavLink>
                        )}
                     </NavItem>
                     <NavItem>
                        <NavLink
                           style={{ cursor: "pointer" }}
                           className={classnames({ active: customActiveTab === 3 })}
                           onClick={() => {
                              toggleCustom(3)
                           }}
                        >
                           {" "}
                           Lead History
                        </NavLink>
                     </NavItem>
                     <NavItem>
                        <NavLink
                           style={{ cursor: "pointer" }}
                           className={classnames({ active: customActiveTab === 4 })}
                           onClick={() => {
                              toggleCustom(4)
                           }}
                        >
                           {" "}
                           Products
                        </NavLink>
                     </NavItem>
                     <NavItem>
                        <NavLink
                           style={{ cursor: "pointer" }}
                           className={classnames({ active: customActiveTab === 5 })}
                           onClick={() => {
                              toggleCustom(5)
                           }}
                        >
                           {" "}
                           Proforma
                        </NavLink>
                     </NavItem>
                     {log.lead?.type?.includes("matured") ? (
                        <>
                           <NavItem>
                              <NavLink
                                 style={{ cursor: "pointer" }}
                                 className={classnames({
                                    active: customActiveTab === 6,
                                 })}
                                 onClick={() => {
                                    toggleCustom(6)
                                 }}
                              >
                                 {" "}
                                 Order History
                              </NavLink>
                           </NavItem>
                           {/* <NavItem> */}
                           {/* 	<NavLink */}
                           {/*                     style={{ cursor: "pointer" }} */}
                           {/*                     className={classnames({ active: customActiveTab === 7})} */}
                           {/*                     onClick={() => { */}
                           {/*                       toggleCustom(7) */}
                           {/*                     }} */}
                           {/*                   > Quotations */}
                           {/*                   </NavLink> */}
                           {/* </NavItem> */}
                           <NavItem>
                              <NavLink
                                 style={{ cursor: "pointer" }}
                                 className={classnames({
                                    active: customActiveTab === 8,
                                 })}
                                 onClick={() => {
                                    toggleCustom(8)
                                 }}
                              >
                                 {" "}
                                 Ledger/Payments
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 style={{ cursor: "pointer" }}
                                 className={classnames({
                                    active: customActiveTab === 9,
                                 })}
                                 onClick={() => {
                                    toggleCustom(9)
                                 }}
                              >
                                 {" "}
                                 Scheme
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 style={{ cursor: "pointer" }}
                                 className={classnames({
                                    active: customActiveTab === 10,
                                 })}
                                 onClick={() => {
                                    toggleCustom(10)
                                 }}
                              >
                                 {" "}
                                 Complaints
                              </NavLink>
                           </NavItem>
                        </>
                     ) : (
                        ""
                     )}
                     {getCurrentUser().crmpro_permission == 1 ? (
                        <>
                           {/* <NavItem>
                              <NavLink
                                 style={{ cursor: "pointer" }}
                                 className={classnames({ active: customActiveTab === 7 })}
                                 onClick={() => {
                                    toggleCustom(7)
                                 }}
                              >
                                 {" "}
                                 Quotations
                              </NavLink>
                           </NavItem> */}
                        </>
                     ) : (
                        <>
                           {/* <NavItem>
                              <NavLink
                                 style={{ pointerEvents: "none", opacity: "0.25" }}
                                 className={classnames({ active: customActiveTab === 4 })}
                                 onClick={() => {
                                    toggleCustom(4)
                                 }}
                              >
                                 {" "}
                                 Products
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 style={{ pointerEvents: "none", opacity: "0.25" }}
                                 className={classnames({ active: customActiveTab === 5 })}
                                 onClick={() => {
                                    toggleCustom(5)
                                 }}
                              >
                                 {" "}
                                 Proforma
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 style={{ pointerEvents: "none", opacity: "0.25" }}
                                 className={classnames({ active: customActiveTab === 6 })}
                                 onClick={() => {
                                    toggleCustom(6)
                                 }}
                              >
                                 {" "}
                                 Order History
                              </NavLink>
                           </NavItem> */}
                           {/* <NavItem>
                              <NavLink
                                 style={{ pointerEvents: 'none', opacity: '0.25' }}
                                 className={classnames({ active: customActiveTab === 7 })}
                                 onClick={() => {
                                    toggleCustom(7)
                                 }}
                              > Quotations
                              </NavLink>
                           </NavItem> */}
                           {/* <NavItem>
                              <NavLink
                                 style={{ pointerEvents: "none", opacity: "0.25" }}
                                 className={classnames({ active: customActiveTab === 8 })}
                                 onClick={() => {
                                    toggleCustom(8)
                                 }}
                              >
                                 {" "}
                                 Ledger/Payments
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 style={{ pointerEvents: "none", opacity: "0.25" }}
                                 className={classnames({ active: customActiveTab === 9 })}
                                 onClick={() => {
                                    toggleCustom(9)
                                 }}
                              >
                                 {" "}
                                 Scheme
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 style={{ pointerEvents: "none", opacity: "0.25" }}
                                 className={classnames({ active: customActiveTab === 10 })}
                                 onClick={() => {
                                    toggleCustom(10)
                                 }}
                              >
                                 {" "}
                                 Complaints
                              </NavLink>
                           </NavItem> */}
                        </>
                     )}
                  </Nav>
                  <TabContent activeTab={customActiveTab} className="pt-3">
                     <TabPane tabId={1}>
                        <>
                           <CprofileTable
                              profileData={profileData}
                              leadId={log.lead_id}
                           />
                        </>
                     </TabPane>
                     <TabPane tabId={2}>
                        <>
                           {updateTab ? (
                              <CupdateTable
                                 typeMature={typeMature}
                                 getAllLeads={props.getTodayReport}
                                 setmodal_xl={setmodal_xl}
                                 lead={log.lead}
                              />
                           ) : (
                              ""
                           )}
                        </>
                     </TabPane>
                     <TabPane tabId={3}>
                        <>
                           {historyTab ? (
                              <ChistoryTable
                                 historyData={historyData}
                                 leadId={log.lead_id}
                              />
                           ) : (
                              ""
                           )}
                        </>
                     </TabPane>
                     <TabPane tabId={4}>
                        <>{productTab ? <CproductTable leadId={log.lead_id} /> : ""}</>
                     </TabPane>
                     <TabPane tabId={5}>
                        <>
                           {proformaTab ? <CproformaTable leadId={log.lead_id} /> : ""}
                        </>
                     </TabPane>
                     <TabPane tabId={6}>
                        <>{orderTab ? <CorderTable leadId={log.lead_id} /> : ""}</>
                     </TabPane>
                     <TabPane tabId={7}>
                        <>
                           {quotationsTab ? (
                              <CQuotationsTable leadId={log.lead_id} />
                           ) : (
                              ""
                           )}
                        </>
                     </TabPane>
                     <TabPane tabId={8}>
                        <>{ledgerTab ? <CledgerTable leadId={log.lead_id} /> : ""}</>
                     </TabPane>
                     <TabPane tabId={9}>
                        <>{schemeTab ? <CschemeTable leadId={log.lead_id} /> : ""}</>
                     </TabPane>
                     <TabPane tabId={10}>
                        <>
                           {complaintTab ? <CcomplaintTable leadId={log.lead_id} /> : ""}
                        </>
                     </TabPane>
                  </TabContent>
               </div>
            </Modal>
         </>
      )
}
export default TodayList
