import React, { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser, getPermission } from "../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import LocationModal from "../Admin/Location/LocationModal"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const OccupiedList = props => {
   const [location, setLocation] = useState(props.data)
   const [modal2, setmodal2] = useState(false)
   const [confirm_alert, setconfirm_alert] = useState(false)
   const [success_dlg, setsuccess_dlg] = useState(false)
   const [dynamic_title, setdynamic_title] = useState("")
   const [dynamic_description, setdynamic_description] = useState("")

   const deleteLocation = e => {
      axios
         .delete(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/location/${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLocation("")
               setdynamic_description(res.data.message)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const editLocation = e => {
      setmodal2(true)
   }

   if (location === "") {
      return ""
   } else
      return (
         <>
            {modal2 ? (
               <LocationModal
                  modal2={modal2}
                  setmodal2={setmodal2}
                  location={location}
                  setLocation={setLocation}
               />
            ) : null}
            <tr key={props.i}>
               <td>{props.i + 1}</td>
               <td>{location.state}</td>
               <td>
                  {location.city} <br />
                  {location.district}
               </td>
               <td>{location.info}</td>
               <td>{location.pincode}</td>
               {/* <td>
                  {location.lead_label_name?.map((item, i) => (
                     <div key={i}>
                        <span
                           className="badge"
                           style={{ backgroundColor: "#f39c12" }}
                        >
                           {item.name}
                        </span>{" "}
                        <br />
                     </div>
                  ))}
               </td> */}
               <td>
                  <span className="fw-bold">Name:</span>
                  {location.leads && location.leads.is_clickable === 1 ? (
                     <Link
                        className="text-info"
                        to={`/leads?id=${location.leads && location.leads.id}`}
                     >
                        {" "}
                        {location.leads && location.leads.name}
                     </Link>
                  ) : (
                     <span> {location.leads && location.leads.name}</span>
                  )}
                  <br />
                  {(getCurrentUser()?.name == location.leads &&
                     location.leads.leadholder_name) ||
                     getCurrentUser()?.role_name !== "Executive" ? (
                     <>
                        <span className="fw-bold">Firm name: </span>
                        {location.leads && location.leads.firm_name}
                        <br />
                     </>
                  ) : (
                     ""
                  )}
                  <span className="fw-bold">Leadholder: </span>
                  {location.leads && location.leads.leadholder_name}
                  <br />
                  <span className="fw-bold">Phone: </span>
                  {location.leads && location.leads.leadholder_phone}
                  <br />
                  <span className="fw-bold">Senior: </span>
                  {location.leads && location.leads.senior}
               </td>
               <td>
                  <span className="fw-bold">Customer Since: </span>{" "}
                  {location.leads && location.leads.created_at}
                  <br />
                  <span className="fw-bold">Last 3 months billing:</span>{" "}
                  {location.last_three_month_avg}
                  <br />
                  <span className="fw-bold">Last order date:</span>{" "}
                  {location.leads && location.leads.last_order_date}
                  <br />
                  <span className="fw-bold">Outstanding:</span>{" "}
                  {location.outstanding_amount}
                  <hr />
                  <span className="fw-bold">Info:</span>{" "}
                  {location?.lead_label_name !== null
                     ? location.lead_label_name.map((item, i) => (
                        <>
                           <span
                              key={i}
                              className="badge"
                              style={{ backgroundColor: "#f39c12" }}
                           >
                              {item.name}
                           </span>{" "}
                        </>
                     ))
                     : ""}
               </td>
               <td>
                  {getCurrentUser()?.role_name === "Administrator" || getCurrentUser().id === location?.leads?.leadholder_id ?
                     <a
                        className="text-warning"
                        onClick={() => {
                           editLocation(location.id)
                        }}
                     >
                        <i className="fas fa-pen" />
                     </a>
                     :
                     ""
                  }
                  {getPermission() && getPermission().location.location.delete.is_checked === "yes" ?
                     <a
                        className="text-danger ms-2"
                        onClick={() => {
                           setconfirm_alert(true)
                        }}
                     >
                        <i className="fas fa-trash-alt" />
                     </a>
                     :
                     ""
                  }
                  {confirm_alert ? (
                     <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                           setconfirm_alert(false)
                           setsuccess_dlg(true)
                           deleteLocation(location.id)
                           setdynamic_title("Deleted")
                           setdynamic_description("Your file has been deleted.")
                        }}
                        onCancel={() => setconfirm_alert(false)}
                     >
                        You won't be able to revert this!
                     </SweetAlert>
                  ) : null}
               </td>
            </tr>
         </>
      )
}
export default OccupiedList
