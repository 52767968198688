import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser } from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import Paginations from "../../Pagination"
import Loader from "../../Loader/loader.js"

const CprofileTable = props => {
   const [modal_standard, setmodal_standard] = useState(false)
   const [data, setData] = useState(props.profileData)
   const [error, setError] = useState("")
   const [loader, setLoader] = useState(<Loader />)

   const getAllData = () => {
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/customer-profile?id=${props.leadID}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setError(res.data.message)
               setData(res.data.data)
            } else {
               setLoader("")
               setError(res.data.message)
            }
         })
   }

   // useEffect(() => {
   // 	getAllData()
   // }, [])

   return (
      <>
         {/* <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={12}>
            <h6 className="page-title">{props.profileData.name}</h6>
            <i className="fa fa-mobile"></i>{" "}
            {props.profileData.country_code !== null ? (
              <>{props.profileData.country_code}</>
            ) : (
              "+91"
            )}{" "}
            {props.profileData.number}
            <a
              style={{ cursor: "pointer", color: "#08913b" }}
              target="_blank"
              href={`https://web.whatsapp.com/send?phone=${props.profileData.number}`}
            >
              <i className="fab fa-whatsapp ms-2 fa-lg"></i>
            </a>
          </Col>
        </Row>
      </div> */}
         <div className="card">
            <div className="card-body">
               <div className="lead_table table-responsive">
                  <table style={{ width: "100%" }}>
                     <tbody>
                        <tr>
                           <th>Last Order at</th>
                           <td>{props.profileData.last_order_date}</td>
                        </tr>
                        <tr>
                           <th>Total Orders</th>
                           <td>{props.profileData.order_count}</td>
                        </tr>
                        <tr>
                           <th>Total Business Done</th>
                           <td>&#8377;{props.profileData.total_business}</td>
                        </tr>
                        <tr>
                           <th>Best Time to Talk</th>
                           <td>{props.profileData.best_time}</td>
                        </tr>
                        <tr>
                           <th>Last Updated</th>
                           <td>{props.profileData.updated_at}</td>
                        </tr>
                        <tr>
                           <th>Created at</th>
                           <td>{props.profileData.created_at}</td>
                        </tr>
                        {/* <tr>
                  <th>Interest For</th>
                  <td>{props.profileData.interest_for}</td>
                </tr> */}
                        <tr>
                           <th>Lead Source</th>
                           <td>{props.profileData.lead_source}</td>
                        </tr>
                        <tr>
                           <th>Alternate Number</th>
                           <td>
                              {props.profileData.alternate_number !== null ?
                                 <>
                                    {getCurrentUser().role_name == "Team Leader" && getCurrentUser().name !== props.profileData.leadholder_name ?
                                       <>
                                          **********
                                       </>
                                       :
                                       <>
                                          {props.profileData.alternate_number}
                                       </>
                                    }
                                 </>
                                 :
                                 ""
                              }
                           </td>
                        </tr>
                        <tr>
                           <th>Email</th>
                           <td>
                              {getCurrentUser().role_name == "Team Leader" && getCurrentUser().name !== props.profileData.leadholder_name ?
                                 <>
                                    ***********.gmail.com
                                 </>
                                 :
                                 <>
                                    {props.profileData.email}
                                 </>
                              }
                           </td>
                        </tr>
                        <tr>
                           <th>State</th>
                           <td>{props.profileData.state}</td>
                        </tr>
                        <tr>
                           <th>District</th>
                           <td>{props.profileData.district}</td>
                        </tr>
                        <tr>
                           <th>City</th>
                           <td>{props.profileData.city}</td>
                        </tr>
                        {props.profileData.custom_fields?.map((item, i) => (
                           <tr key={i}>
                              <th>{item.lead_label}</th>
                              <td>{item.value}</td>
                           </tr>
                        ))}
                        <tr>
                           <th>Leadholder</th>
                           <td>{props.profileData.leadholder_name}</td>
                        </tr>
                     </tbody>
                  </table>
                  {error ? (
                     <span className="text-danger mt-3 d-table m-auto" role="alert">
                        {error}
                     </span>
                  ) : (
                     ""
                  )}
               </div>
            </div>
         </div>
         {/* {loader} */}
      </>
   )
}
export default CprofileTable
