import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser } from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import EditRoleModal from "./single-role"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const RoleList = props => {
  const [role, setRole] = useState(props.data)
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")

  const deleteRole = e => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/role/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setRole("")
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const editRole = () => {
    setIsOpen(true)
    setmodal_xlarge(true)
  }

  if (role === "") {
    return ""
  } else
    return (
      <>
        {isOpen ? (
          <EditRoleModal
            modal_xlarge={modal_xlarge}
            setmodal_xlarge={setmodal_xlarge}
            role={role}
            setRole={setRole}
            setIsOpen={setIsOpen}
          />
        ) : null}
        <tr key={props.i}>
          <td>{props.counter + props.i}</td>
          <td>{role.name}</td>
          <td>{role.user_count}</td>
          <td>
            {role.name === "Administrator" ? (
              ""
            ) : (
              <>
                <a
                  className="text-warning"
                  onClick={() => {
                    editRole(role.id)
                  }}
                >
                  <i className="fas fa-pen" />
                </a>
                {/* <a
                           className="text-danger ms-2"
                           onClick={() => {
                              setconfirm_alert(true)
                           }}
                        >
                           <i className="fas fa-trash-alt" />
                        </a> */}
              </>
            )}
            {confirm_alert ? (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  setconfirm_alert(false)
                  setsuccess_dlg(true)
                  deleteRole(role.id)
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
                }}
                onCancel={() => setconfirm_alert(false)}
              >
                You won't be able to revert this!
              </SweetAlert>
            ) : null}
          </td>
        </tr>
      </>
    )
}
export default RoleList
