import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import { connect } from "react-redux"

import { Link, useHistory } from "react-router-dom"

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"
// reactstrap
import {
   Row,
   Col,
   Dropdown,
   DropdownToggle,
   DropdownMenu,
   DropdownItem,
   Spinner,
} from "reactstrap"

// Import menuDropdown
import axios from "axios"
import { getCurrentUser, setPermission, setCurrentUser } from "../../helpers/Utils"
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

//i18n
import { withTranslation } from "react-i18next"

// import images
import logolightImg from "../../assets/images/klikcrm-logo.png"

//modals

import LeadModal from "../Lead/leadmodal"
import CustomerModal from "../Customers/CustomerModal"
import ProductsModal from "../Product/All-products/ProductsModal"
import OfferModal from "../Offer/OfferModal"
import ComplaintModal from "../Complaint/complaintModal"
import LocationModal from "../Admin/Location/LocationModal"
import LabelModal from "../Admin/LeadLabel/LabelModal"
import DeadModal from "../Admin/Dead/DeadModal"
import SourceModal from "../Admin/LeadSource/SourceModal"
import FollowupModal from "../Admin/Followup-Status/FollowupModal"
import MstatusModal from "../Admin/Mature-Status/MstatusModal"
import ProformaModal from "../Proforma/proformaModal"

const Header = props => {
   const [searchBox, setSearchBox] = useState(false)
   const [error, setError] = useState("")
   const [search, setSearch] = useState("")
   const [searchData, setSearchData] = useState([])
   const [nextFollowup, setNextFollowup] = useState(localStorage.getItem("NextFollowup") ? JSON.parse(localStorage.getItem("NextFollowup")) : {})
   const [menu, setMenu] = useState(false)
   const [modal2, setmodal2] = useState(false)
   const [modal_standard, setmodal_standard] = useState(false)
   const [modal_customer, setmodal_customer] = useState(false)
   const [modal_product, setmodal_product] = useState(false)
   const [modal_offer, setmodal_offer] = useState(false)
   const [modal_complaint, setmodal_complaint] = useState(false)
   const [modal_lead_label, setmodal_lead_label] = useState(false)
   const [modal_dead_reason, setmodal_dead_reason] = useState(false)
   const [modal_lead_source, setmodal_lead_source] = useState(false)
   const [modal_followup_status, setmodal_followup_status] = useState(false)
   const [modal_mature_status, setmodal_mature_status] = useState(false)
   const [modal_proforma2, setmodal_proforma2] = useState(false)
   const [loader, setLoader] = useState(false)
   const [timer, setTimer] = useState(null)
   const history = useHistory()

   function toggleFullscreen() {
      if (
         !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
         !document.webkitFullscreenElement
      ) {
         // current working methods
         if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen()
         } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen()
         } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen(
               Element.ALLOW_KEYBOARD_INPUT
            )
         }
      } else {
         if (document.cancelFullScreen) {
            document.cancelFullScreen()
         } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
         } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen()
         }
      }
   }

   const handleKeydown = e => {
      if (e.key === "Enter") {
         e.preventDefault()
         history.push(`/leads?search=${search}`)
      }
   }

   if (searchBox === true) {
      const concernedElement = document.querySelector(".app-search")
      document.addEventListener("mousedown", event => {
         if (concernedElement.contains(event.target)) {
         } else {
            setSearchBox(false)
         }
      })
   }

   const getNextFollowup = async () => {
      await axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/check-login-session`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )

         .then(function (res) {
            if (res.data.status) {
               // setNextFollowup(res.data.data)
               // setPermission(res.data.data.permissions)
            } else {
            }
            if (res.data.message === "Unauthenticated.") {
               setCurrentUser("")
               localStorage.setItem("DashboardData", "")
               localStorage.setItem("LastUpdated", "")
               localStorage.setItem("NavCounters", "")
               history.push("/login")
            } else {
            }
         })
         .catch((error) => {
            if (error.response) {
               if (error.response.status === 401) {
                  setCurrentUser("")
                  localStorage.setItem("DashboardData", "")
                  localStorage.setItem("LastUpdated", "")
                  localStorage.setItem("NavCounters", "")
                  history.push("/login")
               }
            } else if (error.request) {
               console.log("No response received", error.request);
                  setCurrentUser("")
                  localStorage.setItem("DashboardData", "")
                  localStorage.setItem("LastUpdated", "")
                  localStorage.setItem("NavCounters", "")
                  history.push("/login")
            } else {
               console.log("Error:", error.message);
            }
         })
   }

   const searchGlobal = e => {
      setLoader(true)
      clearTimeout(timer)
      const newTimer = setTimeout(() => {
         searchHere(e)
      }, 1000)
      setTimer(newTimer)
   }

   const searchHere = e => {
      setError("")
      setSearchData([])
      setSearchBox(true)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/global-search?search=${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setSearchData(res.data.data)
               setLoader(false)
            } else {
               setError(res.data.message)
               setLoader(false)
            }
         })
   }

   useEffect(() => {
      props.toggleLeftmenu(false)
      getNextFollowup()
      setNextFollowup(localStorage.getItem("NextFollowup") ? JSON.parse(localStorage.getItem("NextFollowup")) : {})
   }, [])

   return (
      <React.Fragment>
         {modal_standard ? (
            <LeadModal
               modal_standard={modal_standard}
               setmodal_standard={setmodal_standard}
            />
         ) : (
            ""
         )}
         {modal_customer ? (
            <CustomerModal
               modal_customer={modal_customer}
               setmodal_customer={setmodal_customer}
            />
         ) : (
            ""
         )}
         {modal_product ? (
            <ProductsModal
               modal_product={modal_product}
               setmodal_product={setmodal_product}
            />
         ) : (
            ""
         )}
         {modal_offer ? (
            <OfferModal modal_offer={modal_offer} setmodal_offer={setmodal_offer} />
         ) : (
            ""
         )}
         {modal_complaint ? (
            <ComplaintModal
               modal_complaint={modal_complaint}
               setmodal_complaint={setmodal_complaint}
            />
         ) : (
            ""
         )}
         {modal2 ? <LocationModal modal2={modal2} setmodal2={setmodal2} /> : ""}
         {modal_lead_label ? (
            <LabelModal
               modal_lead_label={modal_lead_label}
               setmodal_lead_label={setmodal_lead_label}
            />
         ) : (
            ""
         )}
         {modal_dead_reason ? (
            <DeadModal
               modal_dead_reason={modal_dead_reason}
               setmodal_dead_reason={setmodal_dead_reason}
            />
         ) : (
            ""
         )}
         {modal_lead_source ? (
            <SourceModal
               modal_lead_source={modal_lead_source}
               setmodal_lead_source={setmodal_lead_source}
            />
         ) : (
            ""
         )}
         {modal_followup_status ? (
            <FollowupModal
               modal_followup_status={modal_followup_status}
               setmodal_followup_status={setmodal_followup_status}
            />
         ) : (
            ""
         )}
         {modal_mature_status ? (
            <MstatusModal
               modal_mature_status={modal_mature_status}
               setmodal_mature_status={setmodal_mature_status}
            />
         ) : (
            ""
         )}
         {modal_proforma2 ? (
            <ProformaModal
               modal_proforma2={modal_proforma2}
               setmodal_proforma2={setmodal_proforma2}
            />
         ) : (
            ""
         )}
         <header id="page-topbar">
            <div className="navbar-header">
               <div className="d-flex align-items-center">
                  <button
                     type="button"
                     onClick={() => {
                        props.toggleLeftmenu(!props.leftMenu)
                     }}
                     className="btn btn-sm me-2 font-size-24 d-lg-none header-item waves-effect waves-light"
                     id="vertical-menu-btn"
                  >
                     <i className="mdi mdi-menu"></i>
                  </button>
                  <div className="navbar-brand-box">
                     <Link to="/dashboard">
                        <span className="logo-lg logo-img">
                           <img src={getCurrentUser()?.invoice_logo_formatted !== null || getCurrentUser()?.invoice_logo_formatted !== "" ? getCurrentUser()?.invoice_logo_formatted : logolightImg} alt="" height={getCurrentUser()?.invoice_logo_formatted !== null || getCurrentUser()?.invoice_logo_formatted !== "" ? "50" : "30"} />
                        </span>
                     </Link>
                  </div>
               </div>
               <div className="d-flex d-none d-lg-block align-items-center">
                  <form className="app-search">
                     <div className="position-relative">
                        <input
                           type="text"
                           className="form-control"
                           placeholder="Search..."
                           onKeyDown={handleKeydown}
                           onChange={e => {
                              setSearch(e.target.value), searchGlobal(e.target.value)
                           }}
                        />
                        <span className="fa fa-search"></span>
                        {searchBox ? (
                           <div className="searchbox">
                              <ul style={{ listStyle: "none", padding: "0px" }}>
                                 {error ? (
                                    <li>
                                       <p className="text-center text-danger mt-5">
                                          {error}
                                       </p>
                                    </li>
                                 ) : (
                                    <>
                                       {searchData &&
                                          searchData.map(item => (
                                             <li>
                                                <Link
                                                   to={`/leads?id=${item.id}&global_search=1`}
                                                   className="text-reset notification-item"
                                                >
                                                   <div className="d-flex">
                                                      {/* <div className="avatar-xs me-3"> */}
                                                      {/*   <span  */}
                                                      {/*     className="avatar-title rounded-circle font-size-16" */}
                                                      {/*     style={{ backgroundColor: "#bdbcbc" }} */}
                                                      {/*   > */}
                                                      {/*     <img */}
                                                      {/*       className="user-image" */}
                                                      {/*       src={member.profile_pic} */}
                                                      {/*     /> */}
                                                      {/*   </span> */}
                                                      {/* </div> */}
                                                      <div className="flex-1">
                                                         {item.name !== null ? (
                                                            <p className="user-name d-inline">
                                                               {item.name}
                                                            </p>
                                                         ) : (
                                                            ""
                                                         )}
                                                         {/* <div className="font-size-12 text-muted">
                                                            <p className="mb-1">{item.email}</p>
                                                         </div> */}
                                                         <div className="font-size-12 text-muted">
                                                            <p className="mb-1">{item.number}</p>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </Link>
                                             </li>
                                          ))}
                                    </>
                                 )}
                              </ul>
                              {loader ? (
                                 <div className="search__loader">
                                    <Spinner />
                                    <b className="ms-2">Searching...</b>
                                 </div>
                              ) : (
                                 ""
                              )}
                           </div>
                        ) : (
                           ""
                        )}
                     </div>
                  </form>
               </div>
               <div className="d-none d-lg-block ps-3">
                  {getCurrentUser()?.role_name == "Administrator" ?
                     ""
                     :
                     getCurrentUser()?.monthly_business_target == null || getCurrentUser()?.monthly_business_target == "" ||
                        getCurrentUser()?.monthly_business_target == "0/0" ?
                        ""
                        :
                        <>
                           <span
                              className=""
                              style={{ pointerEvents: "none" }}
                           >
                              Target achieved:{" "}
                              {getCurrentUser()?.monthly_business_target}
                           </span>
                           <br />
                        </>
                  }
                  {(nextFollowup && nextFollowup !== "") ||
                     (nextFollowup && nextFollowup !== null) ||
                     (nextFollowup && nextFollowup !== undefined) ? (
                     <span>
                        Next Followup:{" "}
                        <Link
                           className="text-info"
                           to={`/leads?id=${nextFollowup && nextFollowup.id}`}
                        >
                           {" "}
                           {nextFollowup && nextFollowup.name} |{" "}
                           {nextFollowup && nextFollowup.next_followup_date} |{" "}
                           {nextFollowup && nextFollowup.next_followup_time}
                        </Link>
                     </span>
                  ) : (
                     ""
                  )}
               </div>
               <div className="d-flex align-items-center">
                  {getCurrentUser() &&
                     getCurrentUser().role_name == "Administrator" ? (
                     <Dropdown
                        isOpen={menu}
                        toggle={() => setMenu(!menu)}
                        className="d-inline-block d-none d-md-block"
                     >
                        <DropdownToggle
                           className="btn header-item waves-effect"
                           id="page-header-user-dropdown"
                           tag="button"
                           style={{
                              display: "flex",
                              alignItems: "center",
                           }}
                        >
                           <div className="btn btn-success">
                              <i className="fa fa-plus me-2"></i>
                              <span className="fs-6">New</span>
                           </div>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                           <DropdownItem>
                              {" "}
                              <span onClick={() => setmodal_standard(true)}>
                                 <i className="fa fa-plus me-2"></i>
                                 Lead
                              </span>
                           </DropdownItem>
                           <div className="dropdown-divider" />
                           {getCurrentUser().crmpro_permission == 1 ||
                              getCurrentUser().crm_permission == 1 ? (
                              <>
                                 <DropdownItem>
                                    {" "}
                                    <span onClick={() => setmodal_customer(true)}>
                                       <i className="fa fa-plus me-2"></i>
                                       Customer
                                    </span>
                                 </DropdownItem>
                                 <div className="dropdown-divider" />
                              </>
                           ) : (
                              ""
                           )}
                           {getCurrentUser().crmpro_permission == 1 ? (
                              <>
                                 <DropdownItem>
                                    {" "}
                                    <span onClick={() => setmodal_product(true)}>
                                       <i className="fa fa-plus me-2"></i>
                                       Product
                                    </span>
                                 </DropdownItem>
                                 <div className="dropdown-divider" />
                                 <DropdownItem>
                                    {" "}
                                    <span onClick={() => setmodal_offer(true)}>
                                       <i className="fa fa-plus me-2"></i>
                                       Offer
                                    </span>
                                 </DropdownItem>
                                 <div className="dropdown-divider" />
                                 <DropdownItem>
                                    {" "}
                                    <span onClick={() => setmodal_complaint(true)}>
                                       <i className="fa fa-plus me-2"></i>
                                       Complaint
                                    </span>
                                 </DropdownItem>
                                 <div className="dropdown-divider" />
                              </>
                           ) : (
                              ""
                           )}
                           <DropdownItem>
                              {" "}
                              <span onClick={() => setmodal2(true)}>
                                 <i className="fa fa-plus me-2"></i>
                                 Location
                              </span>
                           </DropdownItem>
                           <div className="dropdown-divider" />
                           <DropdownItem>
                              {" "}
                              <span onClick={() => setmodal_lead_label(true)}>
                                 <i className="fa fa-plus me-2"></i>
                                 Label
                              </span>
                           </DropdownItem>
                           <div className="dropdown-divider" />
                           <DropdownItem>
                              {" "}
                              <span onClick={() => setmodal_dead_reason(true)}>
                                 <i className="fa fa-plus me-2"></i>
                                 Dead Reason
                              </span>
                           </DropdownItem>
                           <div className="dropdown-divider" />
                           <DropdownItem>
                              {" "}
                              <span onClick={() => setmodal_lead_source(true)}>
                                 <i className="fa fa-plus me-2"></i>
                                 Sources
                              </span>
                           </DropdownItem>
                           <div className="dropdown-divider" />
                           <DropdownItem>
                              {" "}
                              <span onClick={() => setmodal_followup_status(true)}>
                                 <i className="fa fa-plus me-2"></i>
                                 Followup Stages
                              </span>
                           </DropdownItem>
                           <div className="dropdown-divider" />
                           <DropdownItem>
                              {" "}
                              <span onClick={() => setmodal_mature_status(true)}>
                                 <i className="fa fa-plus me-2"></i>
                                 Mature Stages
                              </span>
                           </DropdownItem>
                           {getCurrentUser().crmpro_permission == 1 ? (
                              <>
                                 <div className="dropdown-divider" />
                                 <DropdownItem>
                                    {" "}
                                    <span onClick={() => setmodal_proforma2(true)}>
                                       <i className="fa fa-plus me-2"></i>
                                       Proforma
                                    </span>
                                 </DropdownItem>
                              </>
                           ) : (
                              ""
                           )}
                        </DropdownMenu>
                     </Dropdown>
                  ) : (
                     ""
                  )}
                  <LanguageDropdown />
                  {/* <div className="dropdown d-none d-lg-inline-block"> */}
                  {/*     <button type="button" className="btn header-item noti-icon waves-effect" onClick={() => { */}
                  {/*         toggleFullscreen() */}
                  {/*       }} data-bs-toggle="fullscreen"> */}
                  {/*         <i className="mdi mdi-fullscreen"></i> */}
                  {/*     </button> */}
                  {/* </div> */}
                  <NotificationDropdown />
                  <ProfileMenu />
                  {/* <div className="dropdown d-inline-block"> */}
                  {/*     <button */}
                  {/*       onClick={() => { */}
                  {/*         props.showRightSidebarAction(!props.showRightSidebar) */}
                  {/*       }} */}
                  {/*       type="button" */}
                  {/*       className="btn header-item noti-icon right-bar-toggle waves-effect" */}
                  {/*     > */}
                  {/*       <i className="mdi mdi-cog-outline"></i> */}
                  {/*     </button> */}
                  {/*   </div> */}
               </div>
            </div>
            {getCurrentUser()?.expiry_message !== null ? (
               <div className="d-flex justify-content-center bg bg-danger">
                  <span
                     className="text-light"
                     dangerouslySetInnerHTML={{
                        __html: getCurrentUser().expiry_message,
                     }}
                  />
               </div>
            ) : (
               ""
            )}
            <div className="">
               <form className="app-search-2">
                  <div
                     className="position-relative"
                     style={{ width: "90%", margin: "0 auto" }}
                  >
                     <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onKeyDown={handleKeydown}
                        onChange={e => {
                           setSearch(e.target.value), searchGlobal(e.target.value)
                        }}
                     />
                     <span className="fa fa-search"></span>
                     {searchBox ? (
                        <div className="searchbox">
                           <ul style={{ listStyle: "none", padding: "0px" }}>
                              {error ? (
                                 <li>
                                    <p className="text-center text-danger mt-5">{error}</p>
                                 </li>
                              ) : (
                                 <>
                                    {searchData &&
                                       searchData.map(item => (
                                          <li>
                                             <Link
                                                to={`/leads?id=${item.id}&global_search=1`}
                                                className="text-reset notification-item"
                                             >
                                                <div className="d-flex">
                                                   {/* <div className="avatar-xs me-3"> */}
                                                   {/*   <span  */}
                                                   {/*     className="avatar-title rounded-circle font-size-16" */}
                                                   {/*     style={{ backgroundColor: "#bdbcbc" }} */}
                                                   {/*   > */}
                                                   {/*     <img */}
                                                   {/*       className="user-image" */}
                                                   {/*       src={member.profile_pic} */}
                                                   {/*     /> */}
                                                   {/*   </span> */}
                                                   {/* </div> */}
                                                   <div className="flex-1">
                                                      {item.name !== null ? (
                                                         <p className="user-name d-inline">
                                                            {item.name}
                                                         </p>
                                                      ) : (
                                                         ""
                                                      )}
                                                      <div className="font-size-12 text-muted">
                                                         <p className="mb-1">{item.number}</p>
                                                      </div>
                                                   </div>
                                                </div>
                                             </Link>
                                          </li>
                                       ))}
                                 </>
                              )}
                           </ul>
                        </div>
                     ) : (
                        ""
                     )}
                  </div>
               </form>
            </div>
         </header>
      </React.Fragment>
   )
}

Header.propTypes = {
   leftMenu: PropTypes.any,
   showRightSidebar: PropTypes.any,
   showRightSidebarAction: PropTypes.func,
   t: PropTypes.any,
   toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
   const { layoutType, showRightSidebar, leftMenu } = state.Layout
   return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
   showRightSidebarAction,
   toggleLeftmenu,
})(withTranslation()(Header))
