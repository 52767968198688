import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import axios from "axios"

import {
   Row, Col, Alert, Card, CardBody, Container
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useHistory } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// import images
import logoSm from "../../assets/images/klikcrm-logo.png";
import bg from "../../assets/images/bg.jpg";

let subDomain

const Recoverpw = () => {
   const [loading, setLoading] = useState(false)
   const [companyName, setCompanyName] = useState("")
   const history = useHistory()

   useEffect(() => {
      subDomain = window.location.host.split('.')[0]
      if (subDomain !== "login") {
         setCompanyName(subDomain)
      }
   }, [])

   const handleValidSubmit = (event, value) => {
      localStorage.setItem("company_name", companyName)
      if (companyName !== "") {
         setLoading(true)
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${companyName}/auth/forget-password`,
               {
                  email: value.email,
                  company_name: companyName,
               }
            )
            .then(res => {
               if (res.data.status) {
                  setLoading(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
      else {
         toast.error("Enter Company Name", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
         })
      }
   }

   return (
      <React.Fragment>
         <MetaTags>
            <title>Forgot Password | KlikCRM</title>
         </MetaTags>
         <div
            className="accountbg"
            style={{
               backgroundSize: "cover",
               backgroundPosition: "center",
               backgroundImage: `url(${bg})`
            }}
         ></div>
         {/* <div className="home-btn d-none d-sm-block">
            <Link to="dashboard" className="text-dark">
               <i className="fas fa-home h2"></i>
            </Link>
         </div> */}
         <div className="account-pages my-5 pt-5">
            <Container>
               <Row className="justify-content-center">
                  <Col md={12} lg={10} xl={8}>
                     <Row className="justify-content-center m-0">
                        <Col md={6} className="p-0">
                           <Card className="overflow-hidden rounded-0 h-100 bg-secondary">
                              <div className="promotional-wrapper h-100">
                                 <div className="text-center">
                                    <img src={logoSm} height="42" alt="logo" />
                                 </div>
                                 <h2 className="heading">Why You will Love KlikCRM!</h2><ul className="advantages"><li><div><i className="fa fa-star"></i></div><div><h2>Easy To Use</h2><p>KlikCRM is easy to use, even for CRM beginners. Just sign up and get going!</p></div></li><li><div><i className="fa fa-user-tie"></i></div><div><h2>Expert Help</h2><p>KlikCRM comes with support that will set you up, help you make the most of the platform and address any issues you may have- all for free!</p></div></li><li><div><i className="fas fa-shield-alt"></i></div><div><h2>Secure Technology</h2><p>KlikCRM is built on a solid technology stack, so your data is in good hands - safe, available at all times and almost impossible to steal!</p></div></li></ul>
                              </div>
                           </Card>
                        </Col>
                        <Col md={6} className="p-0">
                           <Card className="overflow-hidden rounded-0 h-100">
                              <div className="text-center mt-4">
                                 <h2 className="font-size-38 mt-4 text-center">
                                    Forgot your password ?
                                 </h2>
                                 <p className="font-size-14 text-muted text-center p-3">
                                    Don’t worry. We’ll email you a password reset link with instructions.
                                 </p>
                              </div>

                              <CardBody className="p-4">
                                 <div className="p-3">
                                    <AvForm
                                       className="form-horizontal "
                                       onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                                    >
                                       {subDomain == "login" ?
                                          <div className="mb-3">
                                             <label>Domain Name</label>
                                             <div className="input-group mb-3">
                                                <input
                                                   onChange={(e) => setCompanyName(e.target.value)}
                                                   type="text"
                                                   className="form-control"
                                                   placeholder="Enter Domain Name"
                                                   aria-label="Recipient's username"
                                                   aria-describedby="basic-addon2"
                                                   required
                                                />
                                                <span className="input-group-text" id="basic-addon2">
                                                   .klikcrm.com
                                                </span>
                                             </div>
                                          </div>
                                          :
                                          ""
                                       }
                                       <div className="mb-3">
                                          <AvField
                                             name="email"
                                             label="Email"
                                             className="form-control"
                                             placeholder="Enter email"
                                             type="email"
                                             required
                                          />
                                       </div>
                                       <Row className="mb-3">
                                          <Col className="text-end">
                                             {loading ? (
                                                <button

                                                   className="btn btn-primary w-md waves-effect waves-light"
                                                   type="button"
                                                   disabled
                                                >
                                                   Loading
                                                </button>
                                             ) : (
                                                <button
                                                   className="btn btn-primary w-md waves-effect waves-light"
                                                   type="submit"
                                                >
                                                   Reset
                                                </button>
                                             )}
                                          </Col>
                                       </Row>
                                    </AvForm>
                                    <Row className="mt-2 mb-0 row">
                                       <div className="pt-4 text-center">
                                          <p>
                                             Remember It ?{" "}
                                             <Link to="/login"
                                                className="fw-medium text-primary"
                                             >
                                                {" "}
                                                Log In here{" "}
                                             </Link>{" "}
                                          </p>
                                       </div>
                                    </Row>
                                    {/* <div className="mt-3 pt-3 d-flex justify-content-between">
                                       <div>
                                          <label className="">Support</label> <br />
                                          <i className="fas fa-headset fa-lg">
                                          </i>
                                          <a className="text-info ms-2" href="tel://+918054805705">+91-805 4805 705</a>
                                       </div>
                                       <div>
                                          <label className="">Sales</label> <br />
                                          <i className="fas fa-users fa-lg">
                                          </i>
                                          <a className="text-info ms-2" href="tel://+919781239781">+91-9781 23 9781</a>
                                       </div>
                                    </div> */}
                                 </div>
                                 <div className="mt-5 text-center">
                                    {/* <p>
                                       Don't have an account ?{" "}
                                       <Link
                                          to="pages-register"
                                          className="fw-medium text-primary"
                                       >
                                          {" "}
                                          Signup now{" "}
                                       </Link>{" "}
                                    </p> */}
                                    <p className="mb-0">
                                       © {new Date().getFullYear()} KlikCRM.com,{" "}
                                       {/* <i className=""></i>  */}
                                       all rights reserved.
                                    </p>
                                 </div>
                              </CardBody>
                           </Card>
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Container>
         </div> </React.Fragment>
   )
}

export default Recoverpw
