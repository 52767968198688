import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import axios from "axios"
import DatePicker from "react-datepicker"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser, getPermission } from "../../helpers/Utils"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  Modal,
  FormGroup,
  Input,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  Tooltip,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Spinner,
  Badge,
  UncontrolledPopover,
} from "reactstrap"
import Loader from "../../components/Loader/loader.js"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Paginations from "../../components/Pagination"

let name = ""

const LoginLogs = () => {
  const [allLogs, setAllLogs] = useState([])
  const [log, setLog] = useState("")
  const [error, setError] = useState("")
  const [loader, setLoader] = useState(<Loader />)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [allPages, setAllPages] = useState([])
  const [counter, setCounter] = useState("")

  const getAllLogs = e => {
    setAllLogs([])
    // e.preventDefault();
    setLoader(<Loader />)
    let url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
      "company_name"
    )}/login-log?per_page=${
      localStorage.getItem("PerPage") !== null
        ? localStorage.getItem("PerPage")
        : ""
    }`
    if (e === undefined) {
      axios
        .get(`${url}`, {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        })
        .then(function (res) {
          if (res.data.status) {
            setLoader("")
            setError(res.data.message)
            setCounter(res.data.data.from)
            setAllLogs(res.data.data.data)
            setAllPages(res.data.data)
          } else {
            setLoader("")
            setError(res.data.message)
          }
        })
    } else {
      name = e.target.search.value
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/login-log?from_date=${formatDate(startDate)}&to_date=${formatDate(
            endDate
          )}&search=${e.target.search.value}&per_page=${
            localStorage.getItem("PerPage") !== null
              ? localStorage.getItem("PerPage")
              : ""
          }`,
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoader("")
            setError(res.data.message)
            setCounter(res.data.data.from)
            setAllLogs(res.data.data.data)
            setAllPages(res.data.data)
          } else {
            setLoader("")
            setError(res.data.message)
          }
        })
    }
  }

  const formatDate = date => {
    let d = new Date(date)
    let month = (d.getMonth() + 1).toString()
    let day = d.getDate().toString()
    let year = d.getFullYear()
    if (month.length < 2) {
      month = "0" + month
    }
    if (day.length < 2) {
      day = "0" + day
    }
    return [year, month, day].join("-")
  }

  const deleteLog = e => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/login-log/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLog("")
          setdynamic_description(res.data.message)
        } else {
        }
      })
  }

  const paginate = e => {
    setAllLogs([])
    setCounter("")
    setAllPages([])
    setLoader(<Loader />)
    axios
      .get(
        `${e}&from_date=${
          startDate !== "" ? formatDate(startDate) : ""
        }&to_date=${endDate !== "" ? formatDate(endDate) : ""}&search=${
          name !== "" ? name : ""
        }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setAllLogs(res.data.data.data)
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  const clear = () => {
    getAllLogs()
  }

  const exportLead = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/loginlogs-export`,
        {
          ids: "",
        },

        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          // setLeadsSelectedIds([])
          window.location.href = res.data.file_url
          setTimeout(() => {
            dFileName(res.data.file_name)
          }, 5000)
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const dFileName = e => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/delete-loginlogs-export-file`,
        {
          file_name: e,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
        } else {
        }
      })
  }

  useEffect(() => {
    getAllLogs()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Login Logs | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <AvForm
              onValidSubmit={e => {
                getAllLogs(e)
              }}
            >
              <Row>
                <Col md={3}>
                  <h6 className="page-title">Login Logs</h6>
                </Col>
                <Col md={2}>
                  <DatePicker
                    className="w-100 form-control"
                    dateFormat="yyyy-MM-dd"
                    name="from_date"
                    placeholderText="From"
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                  />
                </Col>
                <Col md={2}>
                  <DatePicker
                    className="w-100 form-control"
                    dateFormat="yyyy-MM-dd"
                    name="to_date"
                    placeholderText="To"
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                  />
                </Col>
                <Col md={2}>
                  <AvField
                    type="search"
                    name="search"
                    placeholder="Search by Name"
                  />
                </Col>
                <Col md={3}>
                  <div
                    className="d-flex justify-content-end"
                    style={{ gap: "10px" }}
                  >
                    <button
                      className="btn btn-primary"
                      type="submit"
                      // onClick={(e) => {getAllLogs (e)}}
                    >
                      <i className="mdi mdi-magnify me-2"></i>
                      Search
                    </button>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      onClick={() => {
                        clear(), setStartDate(""), setEndDate("")
                      }}
                    >
                      <i className="mdi mdi-"></i> Clear
                    </button>
                    {getPermission() &&
                    getPermission().lead_export.lead_export.view.is_checked ===
                      "yes" ? (
                      <button
                        className="btn btn-primary me-2"
                        onClick={() => {
                          exportLead()
                        }}
                      >
                        Export
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            </AvForm>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="lead_table table-responsive">
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr className="static-header">
                      <th>#</th>
                      <th>User Name</th>
                      <th>Activity</th>
                      <th>IP</th>
                      <th>Date & Time</th>
                      {/* <th className="text-end">Action</th> */}
                    </tr>
                    {allLogs &&
                      allLogs.map((item, i) => (
                        <tr key={i}>
                          <td>{counter + i}</td>
                          <td>{item.user_name}</td>
                          <td>
                            {item.activity !== "login" ? (
                              <span className="badge bg-danger">
                                {item.activity}
                              </span>
                            ) : (
                              <span className="badge bg-success">
                                {item.activity}
                              </span>
                            )}
                          </td>
                          <td>{item.ip}</td>
                          <td>{item.created_at}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {error ? (
                  <span
                    className="text-danger mt-3 d-table m-auto"
                    role="alert"
                  >
                    <h1>{error}</h1>
                  </span>
                ) : (
                  ""
                )}
              </div>
              {loader}
              {allPages.length ? (
                <Paginations
                  data={allPages}
                  paginate={paginate}
                  perPage={getAllLogs}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default LoginLogs
