import React, { useState, useEffect } from "react"
import {
   Row,
   Col,
   Nav,
   NavItem,
   NavLink,
   Card,
   CardBody,
   Modal
} from "reactstrap"
import {
   AvForm,
   AvField,
   AvRadio,
   AvRadioGroup,
} from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"
import {
   getCurrentUser,
   getPermission,
   getFilterDate,
   setFilterDate,
} from "../../../helpers/Utils"
import { Link, useLocation, useHistory } from "react-router-dom"
import classnames from "classnames"
import axios from "axios"
import Paginations from "../../Pagination"
import Loader from "../../Loader/loader.js"
import TodayList from "./TodayList"
import MultipleChoice from "../../Filter/MultipleChoice"
import InputFilter from "../../Filter/SearchFilter"
import DateFilter from "../../Filter/DateFilter"
import LeadsFilter from "../../LeadFilter"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import AssignLabels from "../../Assign-Labels"
import CampaignModel from "../../Campaigns"

let followupType = ""

const TodayFollowup = () => {
   const [modal_standard, setmodal_standard] = useState(false)
   const [campaign_model, setcampaign_model] = useState(false)
   const [assignLabel, setAssignLabel] = useState(false)
   const [id_filter, setid_filter] = useState(false)
   const [af_name_filter, setaf_name_filter] = useState(false)
   const [af2_name_filter, setaf2_name_filter] = useState(false)
   const [af3_name_filter, setaf3_name_filter] = useState(false)
   const [af4_name_filter, setaf4_name_filter] = useState(false)
   const [af5_name_filter, setaf5_name_filter] = useState(false)
   const [af_contact_filter, setaf_contact_filter] = useState(false)
   const [af2_contact_filter, setaf2_contact_filter] = useState(false)
   const [af3_contact_filter, setaf3_contact_filter] = useState(false)
   const [af4_contact_filter, setaf4_contact_filter] = useState(false)
   const [af5_contact_filter, setaf5_contact_filter] = useState(false)
   const [af_leadsource_filter, setaf_leadsource_filter] = useState(false)
   const [af2_leadsource_filter, setaf2_leadsource_filter] = useState(false)
   const [af3_leadsource_filter, setaf3_leadsource_filter] = useState(false)
   const [af4_leadsource_filter, setaf4_leadsource_filter] = useState(false)
   const [af5_leadsource_filter, setaf5_leadsource_filter] = useState(false)
   const [af_tag_filter, setaf_tag_filter] = useState(false)
   const [af2_tag_filter, setaf2_tag_filter] = useState(false)
   const [af3_tag_filter, setaf3_tag_filter] = useState(false)
   const [af4_tag_filter, setaf4_tag_filter] = useState(false)
   const [af5_tag_filter, setaf5_tag_filter] = useState(false)
   const [af_label_filter, setaf_label_filter] = useState(false)
   const [af2_label_filter, setaf2_label_filter] = useState(false)
   const [af3_label_filter, setaf3_label_filter] = useState(false)
   const [af4_label_filter, setaf4_label_filter] = useState(false)
   const [af5_label_filter, setaf5_label_filter] = useState(false)
   const [af_leadholder_filter, setaf_leadholder_filter] = useState(false)
   const [af2_leadholder_filter, setaf2_leadholder_filter] = useState(false)
   const [af3_leadholder_filter, setaf3_leadholder_filter] = useState(false)
   const [af4_leadholder_filter, setaf4_leadholder_filter] = useState(false)
   const [af5_leadholder_filter, setaf5_leadholder_filter] = useState(false)
   const [af_followups_filter, setaf_followups_filter] = useState(false)
   const [af2_followups_filter, setaf2_followups_filter] = useState(false)
   const [af3_followups_filter, setaf3_followups_filter] = useState(false)
   const [af4_followups_filter, setaf4_followups_filter] = useState(false)
   const [af5_followups_filter, setaf5_followups_filter] = useState(false)
   const [af_date_filter, setaf_date_filter] = useState(false)
   const [af2_date_filter, setaf2_date_filter] = useState(false)
   const [af3_date_filter, setaf3_date_filter] = useState(false)
   const [af4_date_filter, setaf4_date_filter] = useState(false)
   const [af5_date_filter, setaf5_date_filter] = useState(false)
   const [af_message_filter, setaf_message_filter] = useState(false)
   const [af2_message_filter, setaf2_message_filter] = useState(false)
   const [af3_message_filter, setaf3_message_filter] = useState(false)
   const [af4_message_filter, setaf4_message_filter] = useState(false)
   const [af5_message_filter, setaf5_message_filter] = useState(false)
   const [modal2, setModal2] = useState(false)
   const [modal4, setModal4] = useState(false)
   const [searchedCount, setSearchedCount] = useState("")
   const [followupCount, setFollowupCount] = useState("")
   const [followup, setFollowup] = useState([])
   const [error, setError] = useState("")
   const [loader, setLoader] = useState(<Loader />)
   const [loading, setLoading] = useState(false)
   const [allPages, setAllPages] = useState([])
   const [modal3, setModal3] = useState(false)
   const [counter, setCounter] = useState("")
   const [blur, setBlur] = useState(false)
   const [leadsSelectedIds, setLeadsSelectedIds] = useState([])
   const [success_dlg, setsuccess_dlg] = useState(false)
   const [dynamic_title, setdynamic_title] = useState("")
   const [dynamic_description, setdynamic_description] = useState("")
   const [confirm_alert, setconfirm_alert] = useState(false)
   const [confirm_alert2, setconfirm_alert2] = useState(false)
   const [confirm_alert3, setconfirm_alert3] = useState(false)
   const [check, setCheck] = useState(0)
   const [lead_filter, setlead_filter] = useState(false)
   const [customActiveTab, setCustomActiveTab] = useState(1)
   const [transferNew, setTransferNew] = useState("0")
   const [hideHistory, setHideHistory] = useState("0")
   const [convertedTime, setConvertedTime] = useState("")


   const getTodayFollowup = (e, id) => {
      setFollowup([])
      setCounter([])
      setAllPages([])
      setLoader(<Loader />)
      if (e == "my_leads") {
         axios
            .get(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/today-followup?per_page=${localStorage.getItem("PerPage") !== null
                  ? localStorage.getItem("PerPage")
                  : ""
               }&leadholder_id=${getCurrentUser().id}&type=${getCurrentUser().crm_permission == 0 &&
                  getCurrentUser().crmpro_permission == 0
                  ? "followup,new,repeated"
                  : ""
               }&page=${localStorage.getItem(
                  "current_page"
               )}&today_followup_type=${followupType}`,
               {
                  headers: {
                     "content-type": "application/json",
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                        }`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoader("")
                  setCounter(res.data.data.from)
                  setFollowup(res.data.data.data)
                  setAllPages(res.data.data)
                  setBlur(false)
               } else {
                  setLoader("")
                  setError(res.data.message)
               }
            })
      } else {
         followupCounts()
         if (e === "local") {
            if (followupType === "" && localStorage.getItem("leadStatus") === "") {
               if (JSON.parse(localStorage.getItem('All-Followup-Data')).length > 0) {
                  let storedArray = JSON.parse(localStorage.getItem('All-Followup-Data'));
                  const objectIdToDelete = id;
                  const indexToDelete = storedArray.findIndex(obj => obj.id === objectIdToDelete);
                  if (indexToDelete !== -1) {
                     storedArray.splice(indexToDelete, 1);
                     localStorage.setItem('All-Followup-Data', JSON.stringify(storedArray));
                  }
                  const storedTimestamp = localStorage.getItem("All-Followup-LastUpdated");
                  const currentTime = new Date();
                  const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60);
                  setConvertedTime(Math.round(timeDiff));
                  setFollowup(JSON.parse(localStorage.getItem("All-Followup-Data")))
                  setAllPages(JSON.parse(localStorage.getItem("All-Followup-PageData")))
                  setCounter(JSON.parse(localStorage.getItem("All-Followup-PageData")).from)
                  setSearchedCount(JSON.parse(localStorage.getItem("All-Followup-PageData")).total)
                  setLoader("")
                  return false
               }
            } else if (followupType === "" && localStorage.getItem("leadStatus") === "followup,new,repeated") {
               if (JSON.parse(localStorage.getItem('All-Followup-Leads-Data')).length > 0) {
                  let storedArray = JSON.parse(localStorage.getItem('All-Followup-Leads-Data'));
                  const objectIdToDelete = id;
                  const indexToDelete = storedArray.findIndex(obj => obj.id === objectIdToDelete);

                  if (indexToDelete !== -1) {
                     storedArray.splice(indexToDelete, 1);
                     localStorage.setItem('All-Followup-Leads-Data', JSON.stringify(storedArray));
                  }

                  const storedTimestamp = localStorage.getItem("All-Followup-Leads-LastUpdated");
                  const currentTime = new Date();
                  const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60);
                  setConvertedTime(Math.round(timeDiff));
                  setFollowup(JSON.parse(localStorage.getItem("All-Followup-Leads-Data")))
                  setAllPages(JSON.parse(localStorage.getItem("All-Followup-Leads-PageData")))
                  setCounter(JSON.parse(localStorage.getItem("All-Followup-Leads-PageData")).from)
                  setSearchedCount(JSON.parse(localStorage.getItem("All-Followup-Leads-PageData")).total)
                  setLoader("")
                  return false
               }
            } else if (followupType === "" && localStorage.getItem("leadStatus") === "matured") {
               if (JSON.parse(localStorage.getItem('All-Followup-Matured-Data')).length > 0) {
                  let storedArray = JSON.parse(localStorage.getItem('All-Followup-Matured-Data'));
                  const objectIdToDelete = id;
                  const indexToDelete = storedArray.findIndex(obj => obj.id === objectIdToDelete);

                  if (indexToDelete !== -1) {
                     storedArray.splice(indexToDelete, 1);
                     localStorage.setItem('All-Followup-Matured-Data', JSON.stringify(storedArray));
                  }

                  const storedTimestamp = localStorage.getItem("All-Followup-Matured-LastUpdated");
                  const currentTime = new Date();
                  const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60);
                  setConvertedTime(Math.round(timeDiff));
                  setFollowup(JSON.parse(localStorage.getItem("All-Followup-Matured-Data")))
                  setAllPages(JSON.parse(localStorage.getItem("All-Followup-Matured-PageData")))
                  setCounter(JSON.parse(localStorage.getItem("All-Followup-Matured-PageData")).from)
                  setSearchedCount(JSON.parse(localStorage.getItem("All-Followup-Matured-PageData")).total)
                  setLoader("")
                  return false
               }
            } else if (followupType === "today" && localStorage.getItem("leadStatus") === "matured") {
               if (JSON.parse(localStorage.getItem('Today-Followup-Matured-Data')).length > 0) {
                  let storedArray = JSON.parse(localStorage.getItem('Today-Followup-Matured-Data'));
                  const objectIdToDelete = id;
                  const indexToDelete = storedArray.findIndex(obj => obj.id === objectIdToDelete);

                  if (indexToDelete !== -1) {
                     storedArray.splice(indexToDelete, 1);
                     localStorage.setItem('Today-Followup-Matured-Data', JSON.stringify(storedArray));
                  }

                  const storedTimestamp = localStorage.getItem("Today-Followup-Matured-LastUpdated");
                  const currentTime = new Date();
                  const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60);
                  setConvertedTime(Math.round(timeDiff));
                  setFollowup(JSON.parse(localStorage.getItem("Today-Followup-Matured-Data")))
                  setAllPages(JSON.parse(localStorage.getItem("Today-Followup-Matured-PageData")))
                  setCounter(JSON.parse(localStorage.getItem("Today-Followup-Matured-PageData")).from)
                  setSearchedCount(JSON.parse(localStorage.getItem("Today-Followup-Matured-PageData")).total)
                  setLoader("")
                  return false
               }
            } else if (followupType === "today" && localStorage.getItem("leadStatus") === "followup,new,repeated") {
               if (JSON.parse(localStorage.getItem('Today-Followup-Leads-Data')).length > 0) {
                  let storedArray = JSON.parse(localStorage.getItem('Today-Followup-Leads-Data'));
                  const objectIdToDelete = id;
                  const indexToDelete = storedArray.findIndex(obj => obj.id === objectIdToDelete);

                  if (indexToDelete !== -1) {
                     storedArray.splice(indexToDelete, 1);
                     localStorage.setItem('Today-Followup-Leads-Data', JSON.stringify(storedArray));
                  }

                  const storedTimestamp = localStorage.getItem("Today-Followup-Leads-LastUpdated");
                  const currentTime = new Date();
                  const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60);
                  setConvertedTime(Math.round(timeDiff));
                  setFollowup(JSON.parse(localStorage.getItem("Today-Followup-Leads-Data")))
                  setAllPages(JSON.parse(localStorage.getItem("Today-Followup-Leads-PageData")))
                  setCounter(JSON.parse(localStorage.getItem("Today-Followup-Leads-PageData")).from)
                  setSearchedCount(JSON.parse(localStorage.getItem("Today-Followup-Leads-PageData")).total)
                  setLoader("")
                  return false
               }
            } else if (followupType === "today" && localStorage.getItem("leadStatus") === "") {
               if (JSON.parse(localStorage.getItem('Today-Followup-Data')).length > 0) {
                  let storedArray = JSON.parse(localStorage.getItem('Today-Followup-Data'));
                  const objectIdToDelete = id;
                  const indexToDelete = storedArray.findIndex(obj => obj.id === objectIdToDelete);

                  if (indexToDelete !== -1) {
                     storedArray.splice(indexToDelete, 1);
                     localStorage.setItem('Today-Followup-Data', JSON.stringify(storedArray));
                  }

                  const storedTimestamp = localStorage.getItem("Today-Followup-LastUpdated");
                  const currentTime = new Date();
                  const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60);
                  setConvertedTime(Math.round(timeDiff));
                  setFollowup(JSON.parse(localStorage.getItem("Today-Followup-Data")))
                  setAllPages(JSON.parse(localStorage.getItem("Today-Followup-PageData")))
                  setCounter(JSON.parse(localStorage.getItem("Today-Followup-PageData")).from)
                  setSearchedCount(JSON.parse(localStorage.getItem("Today-Followup-PageData")).total)
                  setLoader("")
                  return false
               }
            } else if (followupType === "pending" && localStorage.getItem("leadStatus") === "") {
               if (JSON.parse(localStorage.getItem('Pending-Followup-Data')).length > 0) {
                  let storedArray = JSON.parse(localStorage.getItem('Pending-Followup-Data'));
                  const objectIdToDelete = id;
                  const indexToDelete = storedArray.findIndex(obj => obj.id === objectIdToDelete);

                  if (indexToDelete !== -1) {
                     storedArray.splice(indexToDelete, 1);
                     localStorage.setItem('Pending-Followup-Data', JSON.stringify(storedArray));
                  }

                  const storedTimestamp = localStorage.getItem("Pending-Followup-LastUpdated");
                  const currentTime = new Date();
                  const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60);
                  setConvertedTime(Math.round(timeDiff));
                  setFollowup(JSON.parse(localStorage.getItem("Pending-Followup-Data")))
                  setAllPages(JSON.parse(localStorage.getItem("Pending-Followup-PageData")))
                  setCounter(JSON.parse(localStorage.getItem("Pending-Followup-PageData")).from)
                  setSearchedCount(JSON.parse(localStorage.getItem("Pending-Followup-PageData")).total)
                  setLoader("")
                  return false
               }
            } else if (followupType === "pending" && localStorage.getItem("leadStatus") === "followup,new,repeated") {
               if (JSON.parse(localStorage.getItem('Pending-Followup-Leads-Data')).length > 0) {
                  let storedArray = JSON.parse(localStorage.getItem('Pending-Followup-Leads-Data'));
                  const objectIdToDelete = id;
                  const indexToDelete = storedArray.findIndex(obj => obj.id === objectIdToDelete);

                  if (indexToDelete !== -1) {
                     storedArray.splice(indexToDelete, 1);
                     localStorage.setItem('Pending-Followup-Leads-Data', JSON.stringify(storedArray));
                  }

                  const storedTimestamp = localStorage.getItem("Pending-Followup-Leads-LastUpdated");
                  const currentTime = new Date();
                  const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60);
                  setConvertedTime(Math.round(timeDiff));
                  setFollowup(JSON.parse(localStorage.getItem("Pending-Followup-Leads-Data")))
                  setAllPages(JSON.parse(localStorage.getItem("Pending-Followup-Leads-PageData")))
                  setCounter(JSON.parse(localStorage.getItem("Pending-Followup-Leads-PageData")).from)
                  setSearchedCount(JSON.parse(localStorage.getItem("Pending-Followup-Leads-PageData")).total)
                  setLoader("")
                  return false
               }
            } else if (followupType === "pending" && localStorage.getItem("leadStatus") === "matured") {
               if (JSON.parse(localStorage.getItem('Pending-Followup-Matured-Data')).length > 0) {
                  let storedArray = JSON.parse(localStorage.getItem('Pending-Followup-Matured-Data'));
                  const objectIdToDelete = id;
                  const indexToDelete = storedArray.findIndex(obj => obj.id === objectIdToDelete);

                  if (indexToDelete !== -1) {
                     storedArray.splice(indexToDelete, 1);
                     localStorage.setItem('Pending-Followup-Matured-Data', JSON.stringify(storedArray));
                  }

                  const storedTimestamp = localStorage.getItem("Pending-Followup-Matured-LastUpdated");
                  const currentTime = new Date();
                  const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60);
                  setConvertedTime(Math.round(timeDiff));
                  setFollowup(JSON.parse(localStorage.getItem("Pending-Followup-Matured-Data")))
                  setAllPages(JSON.parse(localStorage.getItem("Pending-Followup-Matured-PageData")))
                  setCounter(JSON.parse(localStorage.getItem("Pending-Followup-Matured-PageData")).from)
                  setSearchedCount(JSON.parse(localStorage.getItem("Pending-Followup-Matured-PageData")).total)
                  setLoader("")
                  return false
               }
            }
         } else if (e !== "refresh") {
            if (followupType === "" && localStorage.getItem("leadStatus") === "") {
               if (localStorage.getItem('All-Followup-LastUpdated') !== null) {
                  const storedTimestamp = localStorage.getItem("All-Followup-LastUpdated");
                  const currentTime = new Date();
                  const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60);
                  if (timeDiff < 60) {
                     setConvertedTime(Math.round(timeDiff));
                     setFollowup(JSON.parse(localStorage.getItem("All-Followup-Data")))
                     setAllPages(JSON.parse(localStorage.getItem("All-Followup-PageData")))
                     setCounter(JSON.parse(localStorage.getItem("All-Followup-PageData")).from)
                     setSearchedCount(JSON.parse(localStorage.getItem("All-Followup-PageData")).total)
                     setLoader("")
                     return false
                  }
               }
            } else if (followupType === "" && localStorage.getItem("leadStatus") === "followup,new,repeated") {
               if (localStorage.getItem('All-Followup-Leads-LastUpdated') !== null) {
                  const storedTimestamp = localStorage.getItem("All-Followup-Leads-LastUpdated");
                  const currentTime = new Date();
                  const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60);
                  if (timeDiff < 60) {
                     setConvertedTime(Math.round(timeDiff));
                     setFollowup(JSON.parse(localStorage.getItem("All-Followup-Leads-Data")))
                     setAllPages(JSON.parse(localStorage.getItem("All-Followup-Leads-PageData")))
                     setCounter(JSON.parse(localStorage.getItem("All-Followup-Leads-PageData")).from)
                     setSearchedCount(JSON.parse(localStorage.getItem("All-Followup-Leads-PageData")).total)
                     setTimeout(() => {
                        setLoader("")
                     }, 1000);
                     return false
                  }
               }
            } else if (followupType === "" && localStorage.getItem("leadStatus") === "matured") {
               if (localStorage.getItem('All-Followup-Matured-LastUpdated') !== null) {
                  const storedTimestamp = localStorage.getItem("All-Followup-Matured-LastUpdated");
                  const currentTime = new Date();
                  const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60);
                  if (timeDiff < 60) {
                     setConvertedTime(Math.round(timeDiff));
                     setFollowup(JSON.parse(localStorage.getItem("All-Followup-Matured-Data")))
                     setAllPages(JSON.parse(localStorage.getItem("All-Followup-Matured-PageData")))
                     setCounter(JSON.parse(localStorage.getItem("All-Followup-Matured-PageData")).from)
                     setSearchedCount(JSON.parse(localStorage.getItem("All-Followup-Matured-PageData")).total)
                     setLoader("")
                     return false
                  }
               }
            } else if (followupType === "today" && localStorage.getItem("leadStatus") === "matured") {
               if (localStorage.getItem('Today-Followup-Matured-LastUpdated') !== null) {
                  const storedTimestamp = localStorage.getItem("Today-Followup-Matured-LastUpdated");
                  const currentTime = new Date();
                  const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60);
                  if (timeDiff < 60) {
                     setConvertedTime(Math.round(timeDiff));
                     setFollowup(JSON.parse(localStorage.getItem("Today-Followup-Matured-Data")))
                     setAllPages(JSON.parse(localStorage.getItem("Today-Followup-Matured-PageData")))
                     setCounter(JSON.parse(localStorage.getItem("Today-Followup-Matured-PageData")).from)
                     setSearchedCount(JSON.parse(localStorage.getItem("Today-Followup-Matured-PageData")).total)
                     setLoader("")
                     return false
                  }
               }
            } else if (followupType === "today" && localStorage.getItem("leadStatus") === "followup,new,repeated") {
               if (localStorage.getItem('Today-Followup-Leads-LastUpdated') !== null) {
                  const storedTimestamp = localStorage.getItem("Today-Followup-Leads-LastUpdated");
                  const currentTime = new Date();
                  const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60);
                  if (timeDiff < 60) {
                     setConvertedTime(Math.round(timeDiff));
                     setFollowup(JSON.parse(localStorage.getItem("Today-Followup-Leads-Data")))
                     setAllPages(JSON.parse(localStorage.getItem("Today-Followup-Leads-PageData")))
                     setCounter(JSON.parse(localStorage.getItem("Today-Followup-Leads-PageData")).from)
                     setSearchedCount(JSON.parse(localStorage.getItem("Today-Followup-Leads-PageData")).total)
                     setLoader("")
                     return false
                  }
               }
            } else if (followupType === "today" && localStorage.getItem("leadStatus") === "") {
               if (localStorage.getItem('Today-Followup-LastUpdated') !== null) {
                  const storedTimestamp = localStorage.getItem("Today-Followup-LastUpdated");
                  const currentTime = new Date();
                  const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60);
                  if (timeDiff < 60) {
                     setConvertedTime(Math.round(timeDiff));
                     setFollowup(JSON.parse(localStorage.getItem("Today-Followup-Data")))
                     setAllPages(JSON.parse(localStorage.getItem("Today-Followup-PageData")))
                     setCounter(JSON.parse(localStorage.getItem("Today-Followup-PageData")).from)
                     setSearchedCount(JSON.parse(localStorage.getItem("Today-Followup-PageData")).total)
                     setLoader("")
                     return false
                  }
               }
            } else if (followupType === "pending" && localStorage.getItem("leadStatus") === "") {
               if (localStorage.getItem('Pending-Followup-LastUpdated') !== null) {
                  const storedTimestamp = localStorage.getItem("Pending-Followup-LastUpdated");
                  const currentTime = new Date();
                  const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60);
                  if (timeDiff < 60) {
                     setConvertedTime(Math.round(timeDiff));
                     setFollowup(JSON.parse(localStorage.getItem("Pending-Followup-Data")))
                     setAllPages(JSON.parse(localStorage.getItem("Pending-Followup-PageData")))
                     setCounter(JSON.parse(localStorage.getItem("Pending-Followup-PageData")).from)
                     setSearchedCount(JSON.parse(localStorage.getItem("Pending-Followup-PageData")).total)
                     setLoader("")
                     return false
                  }
               }
            } else if (followupType === "pending" && localStorage.getItem("leadStatus") === "followup,new,repeated") {
               if (localStorage.getItem('Pending-Followup-Leads-LastUpdated') !== null) {
                  const storedTimestamp = localStorage.getItem("Pending-Followup-Leads-LastUpdated");
                  const currentTime = new Date();
                  const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60);
                  if (timeDiff < 60) {
                     setConvertedTime(Math.round(timeDiff));
                     setFollowup(JSON.parse(localStorage.getItem("Pending-Followup-Leads-Data")))
                     setAllPages(JSON.parse(localStorage.getItem("Pending-Followup-Leads-PageData")))
                     setCounter(JSON.parse(localStorage.getItem("Pending-Followup-Leads-PageData")).from)
                     setSearchedCount(JSON.parse(localStorage.getItem("Pending-Followup-Leads-PageData")).total)
                     setLoader("")
                     return false
                  }
               }
            } else if (followupType === "pending" && localStorage.getItem("leadStatus") === "matured") {
               if (localStorage.getItem('Pending-Followup-Matured-LastUpdated') !== null) {
                  const storedTimestamp = localStorage.getItem("Pending-Followup-Matured-LastUpdated");
                  const currentTime = new Date();
                  const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60);
                  if (timeDiff < 60) {
                     setConvertedTime(Math.round(timeDiff));
                     setFollowup(JSON.parse(localStorage.getItem("Pending-Followup-Matured-Data")))
                     setAllPages(JSON.parse(localStorage.getItem("Pending-Followup-Matured-PageData")))
                     setCounter(JSON.parse(localStorage.getItem("Pending-Followup-Matured-PageData")).from)
                     setSearchedCount(JSON.parse(localStorage.getItem("Pending-Followup-Matured-PageData")).total)
                     setLoader("")
                     return false
                  }
               }
            }
         }
         axios
            .get(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/today-followup?per_page=${localStorage.getItem("PerPage") !== null
                  ? localStorage.getItem("PerPage")
                  : ""
               }&type=${getCurrentUser().crm_permission == 0 &&
                  getCurrentUser().crmpro_permission == 0
                  ? "followup,new,repeated"
                  : localStorage.getItem("leadStatus") !== null
                     ? localStorage.getItem("leadStatus")
                     : ""
               }&page=${localStorage.getItem(
                  "current_page"
               )}&today_followup_type=${followupType}`,
               {
                  headers: {
                     "content-type": "application/json",
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                        }`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoader("")
                  setCounter(res.data.data.from)
                  setFollowup(res.data.data.data)
                  setAllPages(res.data.data)
                  if (followupType === "" && localStorage.getItem("leadStatus") === "") {
                     localStorage.setItem('All-Followup-LastUpdated', new Date().getTime());
                     localStorage.setItem("All-Followup-Data", JSON.stringify(res.data.data.data))
                     localStorage.setItem("All-Followup-PageData", JSON.stringify(res.data.data))
                  } else if (followupType === "" && localStorage.getItem("leadStatus") === "followup,new,repeated") {
                     localStorage.setItem('All-Followup-Leads-LastUpdated', new Date().getTime());
                     localStorage.setItem("All-Followup-Leads-Data", JSON.stringify(res.data.data.data))
                     localStorage.setItem("All-Followup-Leads-PageData", JSON.stringify(res.data.data))
                  } else if (followupType === "" && localStorage.getItem("leadStatus") === "matured") {
                     localStorage.setItem('All-Followup-Matured-LastUpdated', new Date().getTime());
                     localStorage.setItem("All-Followup-Matured-Data", JSON.stringify(res.data.data.data))
                     localStorage.setItem("All-Followup-Matured-PageData", JSON.stringify(res.data.data))
                  } else if (followupType === "today" && localStorage.getItem("leadStatus") === "matured") {
                     localStorage.setItem('Today-Followup-Matured-LastUpdated', new Date().getTime());
                     localStorage.setItem("Today-Followup-Matured-Data", JSON.stringify(res.data.data.data))
                     localStorage.setItem("Today-Followup-Matured-PageData", JSON.stringify(res.data.data))
                  } else if (followupType === "today" && localStorage.getItem("leadStatus") === "followup,new,repeated") {
                     localStorage.setItem('Today-Followup-Leads-LastUpdated', new Date().getTime());
                     localStorage.setItem("Today-Followup-Leads-Data", JSON.stringify(res.data.data.data))
                     localStorage.setItem("Today-Followup-Leads-PageData", JSON.stringify(res.data.data))
                  } else if (followupType === "today" && localStorage.getItem("leadStatus") === "") {
                     localStorage.setItem('Today-Followup-LastUpdated', new Date().getTime());
                     localStorage.setItem("Today-Followup-Data", JSON.stringify(res.data.data.data))
                     localStorage.setItem("Today-Followup-PageData", JSON.stringify(res.data.data))
                  } else if (followupType === "pending" && localStorage.getItem("leadStatus") === "") {
                     localStorage.setItem('Pending-Followup-LastUpdated', new Date().getTime());
                     localStorage.setItem("Pending-Followup-Data", JSON.stringify(res.data.data.data))
                     localStorage.setItem("Pending-Followup-PageData", JSON.stringify(res.data.data))
                  } else if (followupType === "pending" && localStorage.getItem("leadStatus") === "followup,new,repeated") {
                     localStorage.setItem('Pending-Followup-Leads-LastUpdated', new Date().getTime());
                     localStorage.setItem("Pending-Followup-Leads-Data", JSON.stringify(res.data.data.data))
                     localStorage.setItem("Pending-Followup-Leads-PageData", JSON.stringify(res.data.data))
                  } else if (followupType === "pending" && localStorage.getItem("leadStatus") === "matured") {
                     localStorage.setItem('Pending-Followup-Matured-LastUpdated', new Date().getTime());
                     localStorage.setItem("Pending-Followup-Matured-Data", JSON.stringify(res.data.data.data))
                     localStorage.setItem("Pending-Followup-Matured-PageData", JSON.stringify(res.data.data))
                  }
                  localStorage.setItem(`isFollowupFiltered-${customActiveTab}`, false);
                  setConvertedTime(0)
                  setSearchedCount("")
               } else {
                  setLoader("")
                  setError(res.data.message)
               }
               setTimeout(() => {
                  setError("")
               }, 10000);
            })
      }
   }

   const followupCounts = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/today-followup-counts?type=${getCurrentUser().crm_permission == 0 &&
               getCurrentUser().crmpro_permission == 0
               ? "followup,new,repeated"
               : localStorage.getItem("leadStatus") !== null
                  ? localStorage.getItem("leadStatus")
                  : ""
            }
            &today_followup_type=${followupType}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            setFollowupCount(res.data.data)
         })
         .catch(err => {
            console.log("err", err)
         })
   }

   const toggleCustom = tab => {
      if (customActiveTab !== tab) {
         setCustomActiveTab(tab)
      }
      if (tab == 1) {
         getFollowupType("")
         localStorage.setItem("leadStatus", "")
      }
      if (tab == 2) {
         getFollowupType("today")
      }
      if (tab == 3) {
         getFollowupType("pending")
      }
      if (tab == 4) {
         localStorage.setItem("leadStatus", "followup,new,repeated")
         getFollowupType(followupType)
      }
      if (tab == 5) {
         localStorage.setItem("leadStatus", "matured")
         getFollowupType(followupType)
      }
   }

   // const getCurrentdayFollowups = () => {
   //    setFollowup([])
   //    setCounter([])
   //    setAllPages([])
   //    setLoader(<Loader />)
   //    axios
   //       .get(
   //          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
   //             "company_name"
   //          )}/current-day-followup?per_page=${localStorage.getItem("PerPage") !== null
   //             ? localStorage.getItem("PerPage")
   //             : ""
   //          }&type=${getCurrentUser().crm_permission == 0 &&
   //             getCurrentUser().crmpro_permission == 0 ? "followup,new,repeated" : ""}`,
   //          {
   //             headers: {
   //                "content-type": "application/json",
   //                Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
   //             },
   //          }
   //       )
   //       .then(function (res) {
   //          if (res.data.status) {
   //             setLoader("")
   //             setCounter(res.data.data.from)
   //             setFollowup(res.data.data.data)
   //             setAllPages(res.data.data)
   //          } else {
   //             setLoader("")
   //             setError(res.data.message)
   //          }
   //       })
   // }

   const getFollowupType = e => {
      followupType = e
      setTimeout(() => {
         getTodayFollowup()
      }, 1000)
   }

   const paginate = e => {
      setFollowup([])
      setAllPages([])
      setCounter("")
      setLoader(<Loader />)
      axios
         .get(
            `${e}&id=${localStorage.getItem("leadID") !== null
               ? localStorage.getItem("leadID")
               : ""
            }
            &followup_status_id=${localStorage.getItem(`followupStatus-${customActiveTab}`) !== null
               ? localStorage.getItem(`followupStatus-${customActiveTab}`)
               : ""
            }
            &leadholder_id=${localStorage.getItem(`leadHolder-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadHolder-${customActiveTab}`)
               : ""
            }
            &contact=${localStorage.getItem(`leadContact-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadContact-${customActiveTab}`)
               : ""
            } 
            &name=${localStorage.getItem(`leadName-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadName-${customActiveTab}`)
               : ""
            }
            &lead_label_id=${localStorage.getItem(`leadLabel-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadLabel-${customActiveTab}`)
               : ""
            }
            &tag_id=${localStorage.getItem(`leadTag-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadTag-${customActiveTab}`)
               : ""
            }
            &lead_source_id=${localStorage.getItem(`leadSource-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadSource-${customActiveTab}`)
               : ""
            }
            &followup_start=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
            &followup_to=${getFilterDate() && getFilterDate().to !== null
               ? getFilterDate().to
               : ""
            }
            &lastmessage=${localStorage.getItem(`leadMessage-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadMessage-${customActiveTab}`)
               : ""
            }
            &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }
            &firm_name=${localStorage.getItem("leadFirmName") !== null
               ? localStorage.getItem("leadFirmName")
               : ""
            }
             &no_updation_since=${localStorage.getItem("leadUpdateSince") !== null
               ? localStorage.getItem("leadUpdateSince")
               : ""
            }
             &flag=${localStorage.getItem("leadFlag") !== null
               ? localStorage.getItem("leadFlag")
               : ""
            }&company_pool=${localStorage.getItem("CompanyPool") !== null
               ? localStorage.getItem("CompanyPool")
               : ""
            }&custom_filters=${localStorage.getItem("leadfields_filter") !== null
               ? localStorage.getItem("leadfields_filter")
               : ""
            }
            &assigned=${localStorage.getItem("leadAssigned") !== null
               ? localStorage.getItem("leadAssigned")
               : ""
            }
             &transfer_request=${localStorage.getItem("leadTransfer") !== null
               ? localStorage.getItem("leadTransfer")
               : ""
            }
             &from_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
               : ""
            }
         &to_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
               : ""
            }
            &mature_start_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
               : ""
            }
         &mature_end_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
               : ""
            }
             &type=${getCurrentUser().crm_permission == 0 &&
               getCurrentUser().crmpro_permission == 0
               ? "followup,new,repeated"
               : ""
            }
               &today_followup_type=${followupType}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setCounter(res.data.data.from)
               setAllPages(res.data.data)
               setFollowup(res.data.data.data)
               localStorage.setItem("current_page", res.data.data.current_page)
               if (followupType === "" && localStorage.getItem("leadStatus") === "") {
                  localStorage.setItem('All-Followup-LastUpdated', new Date().getTime());
                  localStorage.setItem("All-Followup-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("All-Followup-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "" && localStorage.getItem("leadStatus") === "followup,new,repeated") {
                  localStorage.setItem('All-Followup-Leads-LastUpdated', new Date().getTime());
                  localStorage.setItem("All-Followup-Leads-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("All-Followup-Leads-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "" && localStorage.getItem("leadStatus") === "matured") {
                  localStorage.setItem('All-Followup-Matured-LastUpdated', new Date().getTime());
                  localStorage.setItem("All-Followup-Matured-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("All-Followup-Matured-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "today" && localStorage.getItem("leadStatus") === "matured") {
                  localStorage.setItem('Today-Followup-Matured-LastUpdated', new Date().getTime());
                  localStorage.setItem("Today-Followup-Matured-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("Today-Followup-Matured-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "today" && localStorage.getItem("leadStatus") === "followup,new,repeated") {
                  localStorage.setItem('Today-Followup-Leads-LastUpdated', new Date().getTime());
                  localStorage.setItem("Today-Followup-Leads-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("Today-Followup-Leads-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "today" && localStorage.getItem("leadStatus") === "") {
                  localStorage.setItem('Today-Followup-LastUpdated', new Date().getTime());
                  localStorage.setItem("Today-Followup-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("Today-Followup-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "pending" && localStorage.getItem("leadStatus") === "") {
                  localStorage.setItem('Pending-Followup-LastUpdated', new Date().getTime());
                  localStorage.setItem("Pending-Followup-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("Pending-Followup-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "pending" && localStorage.getItem("leadStatus") === "followup,new,repeated") {
                  localStorage.setItem('Pending-Followup-Leads-LastUpdated', new Date().getTime());
                  localStorage.setItem("Pending-Followup-Leads-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("Pending-Followup-Leads-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "pending" && localStorage.getItem("leadStatus") === "matured") {
                  localStorage.setItem('Pending-Followup-Matured-LastUpdated', new Date().getTime());
                  localStorage.setItem("Pending-Followup-Matured-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("Pending-Followup-Matured-PageData", JSON.stringify(res.data.data))
               }
               setConvertedTime(0)
            } else {
               setError(res.data.message)
               setLoader("")
            }
         })
   }

   const getReminderLeads = e => {
      setFollowup([])
      setAllPages([])
      setCounter("")
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/today-followup?mature_status_options=${e}
             &type=matured&today_followup_type=${followupType}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setError("")
               setCounter(res.data.data.from)
               setAllPages(res.data.data)
               setFollowup(res.data.data.data)
               setSearchedCount(res.data.data.total)
               localStorage.setItem(`isFollowupFiltered-${customActiveTab}`, true);
            } else {
               setError(res.data.message)
               setLoader("")
            }
         })
   }

   const getSearchedLeads = (e, type) => {
      setError("")
      setLoader(<Loader />)
      if (type == "id") {
         localStorage.setItem("leadID", e)
      }
      if (type == "name") {
         localStorage.setItem(`leadName-${customActiveTab}`, e)
      }
      if (type == "contact") {
         localStorage.setItem(`leadContact-${customActiveTab}`, e)
      }
      if (type == "leadsource") {
         localStorage.setItem(`leadSource-${customActiveTab}`, e)
      }
      if (type == "tag") {
         localStorage.setItem(`leadTag-${customActiveTab}`, e)
      }
      if (type == "label") {
         localStorage.setItem(`leadLabel-${customActiveTab}`, e)
      }
      if (type == "leadholder") {
         localStorage.setItem(`leadHolder-${customActiveTab}`, e)
      }
      if (type == "f-status") {
         localStorage.setItem(`followupStatus-${customActiveTab}`, e)
      }
      if (type == "date") {
         setFilterDate(e)
      }
      if (type == "message") {
         localStorage.setItem(`leadMessage-${customActiveTab}`, e)
      }
      if (type == "sort") {
         localStorage.setItem("leadSort", e)
      }
      if (type == "mature_status") {
         localStorage.setItem("matureStatus", e)
      }
      setSearchedCount("")
      setFollowup([])
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/today-followup?id=${localStorage.getItem("leadID") !== null
               ? localStorage.getItem("leadID")
               : ""
            }
         &followup_status_id=${localStorage.getItem(`followupStatus-${customActiveTab}`) !== null
               ? localStorage.getItem(`followupStatus-${customActiveTab}`)
               : ""
            }
         &mature_status_id=${localStorage.getItem("matureStatus") !== null
               ? localStorage.getItem("matureStatus")
               : ""
            }
         &leadholder_id=${localStorage.getItem(`leadHolder-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadHolder-${customActiveTab}`)
               : ""
            }
         &contact=${localStorage.getItem(`leadContact-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadContact-${customActiveTab}`)
               : ""
            } 
         &name=${localStorage.getItem(`leadName-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadName-${customActiveTab}`)
               : ""
            }
         &lead_label_id=${localStorage.getItem(`leadLabel-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadLabel-${customActiveTab}`)
               : ""
            }
         &tag_id=${localStorage.getItem(`leadTag-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadTag-${customActiveTab}`)
               : ""
            }
         &lead_source_id=${localStorage.getItem(`leadSource-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadSource-${customActiveTab}`)
               : ""
            }
         &followup_start=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
         &followup_to=${getFilterDate() && getFilterDate().to !== null
               ? getFilterDate().to
               : ""
            }
         &lastmessage=${localStorage.getItem(`leadMessage-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadMessage-${customActiveTab}`)
               : ""
            }
         &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }
         &firm_name=${localStorage.getItem("leadFirmName") !== null
               ? localStorage.getItem("leadFirmName")
               : ""
            }
         &no_updation_since=${localStorage.getItem("leadUpdateSince") !== null
               ? localStorage.getItem("leadUpdateSince")
               : ""
            }
         &flag=${localStorage.getItem("leadFlag") !== null
               ? localStorage.getItem("leadFlag")
               : ""
            }&company_pool=${localStorage.getItem("CompanyPool") !== null
               ? localStorage.getItem("CompanyPool")
               : ""
            }&custom_filters=${localStorage.getItem("leadfields_filter") !== null
               ? localStorage.getItem("leadfields_filter")
               : ""
            }
            &assigned=${localStorage.getItem("leadAssigned") !== null
               ? localStorage.getItem("leadAssigned")
               : ""
            }
         &transfer_request=${localStorage.getItem("leadTransfer") !== null
               ? localStorage.getItem("leadTransfer")
               : ""
            }
         &from_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
               : ""
            }
         &to_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
               : ""
            }
            &mature_start_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
               : ""
            }
         &mature_end_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
               : ""
            }
         &per_page=${localStorage.getItem("PerPage") !== null
               ? localStorage.getItem("PerPage")
               : ""
            }
         &type=${getCurrentUser().crm_permission == 0 &&
               getCurrentUser().crmpro_permission == 0
               ? "followup,new,repeated"
               : localStorage.getItem("leadStatus") !== null
                  ? localStorage.getItem("leadStatus")
                  : ""
            }
            &today_followup_type=${followupType}
         `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            setLoader("")
            if (res.data.status) {
               setError("")
               setCounter(res.data.data.from)
               setFollowup(res.data.data.data)
               setAllPages(res.data.data)
               setSearchedCount(res.data.data.total)
               setid_filter(false)
               setaf_name_filter(false)
               setaf2_name_filter(false)
               setaf3_name_filter(false)
               setaf4_name_filter(false)
               setaf5_name_filter(false)
               setaf_contact_filter(false)
               setaf2_contact_filter(false)
               setaf3_contact_filter(false)
               setaf4_contact_filter(false)
               setaf5_contact_filter(false)
               setaf_leadsource_filter(false)
               setaf2_leadsource_filter(false)
               setaf3_leadsource_filter(false)
               setaf4_leadsource_filter(false)
               setaf5_leadsource_filter(false)
               setaf_tag_filter(false)
               setaf2_tag_filter(false)
               setaf3_tag_filter(false)
               setaf4_tag_filter(false)
               setaf5_tag_filter(false)
               setaf_label_filter(false)
               setaf2_label_filter(false)
               setaf3_label_filter(false)
               setaf4_label_filter(false)
               setaf5_label_filter(false)
               setaf_date_filter(false)
               setaf2_date_filter(false)
               setaf3_date_filter(false)
               setaf4_date_filter(false)
               setaf5_date_filter(false)
               setaf_message_filter(false)
               setaf2_message_filter(false)
               setaf3_message_filter(false)
               setaf4_message_filter(false)
               setaf5_message_filter(false)
               setaf_leadholder_filter(false)
               setaf2_leadholder_filter(false)
               setaf3_leadholder_filter(false)
               setaf4_leadholder_filter(false)
               setaf5_leadholder_filter(false)
               setaf_followups_filter(false)
               setaf2_followups_filter(false)
               setaf3_followups_filter(false)
               setaf4_followups_filter(false)
               setaf5_followups_filter(false)
               setlead_filter(false)
               localStorage.setItem(`isFollowupFiltered-${customActiveTab}`, true);
               if (followupType === "" && localStorage.getItem("leadStatus") === "") {
                  localStorage.setItem('All-Followup-LastUpdated', new Date().getTime());
                  localStorage.setItem("All-Followup-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("All-Followup-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "" && localStorage.getItem("leadStatus") === "followup,new,repeated") {
                  localStorage.setItem('All-Followup-Leads-LastUpdated', new Date().getTime());
                  localStorage.setItem("All-Followup-Leads-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("All-Followup-Leads-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "" && localStorage.getItem("leadStatus") === "matured") {
                  localStorage.setItem('All-Followup-Matured-LastUpdated', new Date().getTime());
                  localStorage.setItem("All-Followup-Matured-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("All-Followup-Matured-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "today" && localStorage.getItem("leadStatus") === "matured") {
                  localStorage.setItem('Today-Followup-Matured-LastUpdated', new Date().getTime());
                  localStorage.setItem("Today-Followup-Matured-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("Today-Followup-Matured-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "today" && localStorage.getItem("leadStatus") === "followup,new,repeated") {
                  localStorage.setItem('Today-Followup-Leads-LastUpdated', new Date().getTime());
                  localStorage.setItem("Today-Followup-Leads-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("Today-Followup-Leads-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "today" && localStorage.getItem("leadStatus") === "") {
                  localStorage.setItem('Today-Followup-LastUpdated', new Date().getTime());
                  localStorage.setItem("Today-Followup-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("Today-Followup-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "pending" && localStorage.getItem("leadStatus") === "") {
                  localStorage.setItem('Pending-Followup-LastUpdated', new Date().getTime());
                  localStorage.setItem("Pending-Followup-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("Pending-Followup-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "pending" && localStorage.getItem("leadStatus") === "followup,new,repeated") {
                  localStorage.setItem('Pending-Followup-Leads-LastUpdated', new Date().getTime());
                  localStorage.setItem("Pending-Followup-Leads-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("Pending-Followup-Leads-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "pending" && localStorage.getItem("leadStatus") === "matured") {
                  localStorage.setItem('Pending-Followup-Matured-LastUpdated', new Date().getTime());
                  localStorage.setItem("Pending-Followup-Matured-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("Pending-Followup-Matured-PageData", JSON.stringify(res.data.data))
               }
               followupCounts()
            } else {
               setError(res.data.message)
               setlead_filter(false)
            }
         })
   }

   const getFilteredLeads = () => {
      setError("")
      setSearchedCount("")
      setFollowup([])
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/today-followup?id=${localStorage.getItem("leadID") !== null
               ? localStorage.getItem("leadID")
               : ""
            }
         &followup_status_id=${localStorage.getItem(`followupStatus-${customActiveTab}`) !== null
               ? localStorage.getItem(`followupStatus-${customActiveTab}`)
               : ""
            }
         &leadholder_id=${localStorage.getItem(`leadHolder-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadHolder-${customActiveTab}`)
               : ""
            }
         &contact=${localStorage.getItem(`leadContact-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadContact-${customActiveTab}`)
               : ""
            } 
         &name=${localStorage.getItem(`leadName-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadName-${customActiveTab}`)
               : ""
            }
         &lead_label_id=${localStorage.getItem(`leadLabel-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadLabel-${customActiveTab}`)
               : ""
            }
         &tag_id=${localStorage.getItem(`leadTag-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadTag-${customActiveTab}`)
               : ""
            }
         &lead_source_id=${localStorage.getItem(`leadSource-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadSource-${customActiveTab}`)
               : ""
            }
         &followup_start=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
         &followup_to=${getFilterDate() && getFilterDate().to !== null
               ? getFilterDate().to
               : ""
            }
         &lastmessage=${localStorage.getItem(`leadMessage-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadMessage-${customActiveTab}`)
               : ""
            }
         &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }
         &firm_name=${localStorage.getItem("leadFirmName") !== null
               ? localStorage.getItem("leadFirmName")
               : ""
            }
         &no_updation_since=${localStorage.getItem("leadUpdateSince") !== null
               ? localStorage.getItem("leadUpdateSince")
               : ""
            }
         &flag=${localStorage.getItem("leadFlag") !== null
               ? localStorage.getItem("leadFlag")
               : ""
            }&company_pool=${localStorage.getItem("CompanyPool") !== null
               ? localStorage.getItem("CompanyPool")
               : ""
            }&custom_filters=${localStorage.getItem("leadfields_filter") !== null
               ? localStorage.getItem("leadfields_filter")
               : ""
            }
            &assigned=${localStorage.getItem("leadAssigned") !== null
               ? localStorage.getItem("leadAssigned")
               : ""
            }
         &transfer_request=${localStorage.getItem("leadTransfer") !== null
               ? localStorage.getItem("leadTransfer")
               : ""
            }
         &from_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
               : ""
            }
         &to_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
               : ""
            }
            &mature_start_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
               : ""
            }
         &mature_end_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
               : ""
            }
         &per_page=${localStorage.getItem("PerPage") !== null
               ? localStorage.getItem("PerPage")
               : ""
            }
         &type=${getCurrentUser().crm_permission == 0 &&
               getCurrentUser().crmpro_permission == 0
               ? "followup,new,repeated"
               : localStorage.getItem("leadStatus") !== null
                  ? localStorage.getItem("leadStatus")
                  : ""
            }
            &today_followup_type=${followupType}
            &page=${localStorage.getItem(
               "current_page"
            )}
         `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            setLoader("")
            if (res.data.status) {
               setCounter(res.data.data.from)
               setFollowup(res.data.data.data)
               setAllPages(res.data.data)
               setSearchedCount(res.data.data.total)
               setError("")
               followupCounts()
               if (followupType === "" && localStorage.getItem("leadStatus") === "") {
                  localStorage.setItem('All-Followup-LastUpdated', new Date().getTime());
                  localStorage.setItem("All-Followup-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("All-Followup-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "" && localStorage.getItem("leadStatus") === "followup,new,repeated") {
                  localStorage.setItem('All-Followup-Leads-LastUpdated', new Date().getTime());
                  localStorage.setItem("All-Followup-Leads-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("All-Followup-Leads-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "" && localStorage.getItem("leadStatus") === "matured") {
                  localStorage.setItem('All-Followup-Matured-LastUpdated', new Date().getTime());
                  localStorage.setItem("All-Followup-Matured-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("All-Followup-Matured-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "today" && localStorage.getItem("leadStatus") === "matured") {
                  localStorage.setItem('Today-Followup-Matured-LastUpdated', new Date().getTime());
                  localStorage.setItem("Today-Followup-Matured-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("Today-Followup-Matured-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "today" && localStorage.getItem("leadStatus") === "followup,new,repeated") {
                  localStorage.setItem('Today-Followup-Leads-LastUpdated', new Date().getTime());
                  localStorage.setItem("Today-Followup-Leads-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("Today-Followup-Leads-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "today" && localStorage.getItem("leadStatus") === "") {
                  localStorage.setItem('Today-Followup-LastUpdated', new Date().getTime());
                  localStorage.setItem("Today-Followup-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("Today-Followup-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "pending" && localStorage.getItem("leadStatus") === "") {
                  localStorage.setItem('Pending-Followup-LastUpdated', new Date().getTime());
                  localStorage.setItem("Pending-Followup-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("Pending-Followup-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "pending" && localStorage.getItem("leadStatus") === "followup,new,repeated") {
                  localStorage.setItem('Pending-Followup-Leads-LastUpdated', new Date().getTime());
                  localStorage.setItem("Pending-Followup-Leads-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("Pending-Followup-Leads-PageData", JSON.stringify(res.data.data))
               } else if (followupType === "pending" && localStorage.getItem("leadStatus") === "matured") {
                  localStorage.setItem('Pending-Followup-Matured-LastUpdated', new Date().getTime());
                  localStorage.setItem("Pending-Followup-Matured-Data", JSON.stringify(res.data.data.data))
                  localStorage.setItem("Pending-Followup-Matured-PageData", JSON.stringify(res.data.data))
               }
            } else {
               setError(res.data.message)
            }
         })
   }

   const selectAllCheck = check => {
      if (check.checked) {
         var ele = document.getElementsByName("chk")
         for (var i = 0; i < ele.length; i++) {
            if (ele[i].type == "checkbox") {
               ele[i].checked = true
               leadsSelectedIds.push(parseInt(ele[i].value))
            }
         }
      } else {
         var ele = document.getElementsByName("chk")
         for (var i = 0; i < ele.length; i++) {
            if (ele[i].type == "checkbox") {
               ele[i].checked = false
               var array = leadsSelectedIds
               var index = array.indexOf(parseInt(ele[i].value))
               if (index !== -1) {
                  array.splice(index, 1)
               }
            }
         }
      }
      if (leadsSelectedIds.length === 0) {
         setBlur(false)
      } else {
         setBlur(true)
      }
   }

   const getCheckValue = check => {
      if (check.checked) {
         setTransferNew("1")
      } else {
         setTransferNew("0")
         setHideHistory("0")
      }
   }

   const getCheckValue2 = check => {
      if (check.checked) {
         setHideHistory("1")
      } else {
         setHideHistory("0")
      }
   }

   const transferLeads = (e, value) => {
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead-transfer`,
            {
               lead_ids: leadsSelectedIds,
               user_id: value.user_id,
               transfer_as_new: transferNew,
               hide_lead_history: hideHistory,
               type: value.type,
            },
            {
               headers: {
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               getFilteredLeads()
               setLeadsSelectedIds([])
               setTransferNew("0")
               setHideHistory("0")
               setLoading(false)
               setModal2(false)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               setLoading(false)
               setTransferNew("0")
               setHideHistory("0")
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const transferRequest = (e, value) => {
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead-transfer-request`,
            {
               lead_ids: leadsSelectedIds,
               user_id: value.user_id,
            },
            {
               headers: {
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               getFilteredLeads()
               setLeadsSelectedIds([])
               setLoading(false)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const deleteLeads = () => {
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/multiple-deleted`,
            {
               ids: leadsSelectedIds,
               is_deleted: check,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setCheck(0)
               setLeadsSelectedIds([])
               getSearchedLeads()
               setdynamic_description(res.data.message)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const exportLead = e => {
      let type = "today"
      if (followupType === "") {
         type = "today"
      }
      if (followupType === "today") {
         type = "today_followup"
      }
      if (followupType === "pending") {
         type = "pending_followup"
      }
      let Ids = []
      let url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
         "company_name"
      )}/lead-export`
      if (e == "All") {
         Ids = []
      } else if (e == "Selected") {
         Ids = leadsSelectedIds
      } else {
         Ids = []
         url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
         )}/lead-export?id=${localStorage.getItem("leadID") !== null
            ? localStorage.getItem("leadID")
            : ""
            }
   &followup_status_id=${localStorage.getItem(`followupStatus-${customActiveTab}`) !== null
               ? localStorage.getItem(`followupStatus-${customActiveTab}`)
               : ""
            }
   &leadholder_id=${localStorage.getItem(`leadHolder-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadHolder-${customActiveTab}`)
               : ""
            }
   &contact=${localStorage.getItem(`leadContact-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadContact-${customActiveTab}`)
               : ""
            } 
   &name=${localStorage.getItem(`leadName-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadName-${customActiveTab}`)
               : ""
            }
   &lead_label_id=${localStorage.getItem(`leadLabel-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadLabel-${customActiveTab}`)
               : ""
            }
   &tag_id=${localStorage.getItem(`leadTag-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadTag-${customActiveTab}`)
               : ""
            }
   &lead_source_id=${localStorage.getItem(`leadSource-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadSource-${customActiveTab}`)
               : ""
            }
   &followup_start=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
   &followup_to=${getFilterDate() && getFilterDate().to !== null ? getFilterDate().to : ""
            }
   &lastmessage=${localStorage.getItem(`leadMessage-${customActiveTab}`) !== null
               ? localStorage.getItem(`leadMessage-${customActiveTab}`)
               : ""
            }
   &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }
   &firm_name=${localStorage.getItem("leadFirmName") !== null
               ? localStorage.getItem("leadFirmName")
               : ""
            }
   &no_updation_since=${localStorage.getItem("leadUpdateSince") !== null
               ? localStorage.getItem("leadUpdateSince")
               : ""
            }
   &flag=${localStorage.getItem("leadFlag") !== null
               ? localStorage.getItem("leadFlag")
               : ""
            }&company_pool=${localStorage.getItem("CompanyPool") !== null
               ? localStorage.getItem("CompanyPool")
               : ""
            }&custom_filters=${localStorage.getItem("leadfields_filter") !== null
               ? localStorage.getItem("leadfields_filter")
               : ""
            }
            &assigned=${localStorage.getItem("leadAssigned") !== null
               ? localStorage.getItem("leadAssigned")
               : ""
            }
   &transfer_request=${localStorage.getItem("leadTransfer") !== null
               ? localStorage.getItem("leadTransfer")
               : ""
            }
   &from_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
               : ""
            }
         &to_date=${localStorage.getItem("leadCreated_at") !== ""
               ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
               : ""
            }
            &mature_start_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
               : ""
            }
         &mature_end_date=${localStorage.getItem("leadMatured_at") !== ""
               ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
               : ""
            }&today_followup_type=${followupType}`
      }
      axios
         .post(
            `${url}`,
            {
               ids: Ids,
               followup_type: type,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLeadsSelectedIds([])
               window.location.href = res.data.file_url
               setTimeout(() => {
                  dFileName(res.data.file_name)
               }, 5000)
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const dFileName = e => {
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/delete-file`,
            {
               file_name: e,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
            } else {
            }
         })
   }

   const updateFlag = () => {
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/update-flag`,
            {
               ids: leadsSelectedIds,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLeadsSelectedIds([])
               getFilteredLeads()
               setdynamic_description(res.data.message)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const moveToDead = () => {
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/move-to-dead`,
            {
               ids: leadsSelectedIds,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLeadsSelectedIds([])
               getFilteredLeads()
               setdynamic_description(res.data.message)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const getAllUsers = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/dashboard-data`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               localStorage.setItem('LastUpdated', new Date().getTime());
               localStorage.setItem("DashboardData", JSON.stringify(res.data.data))
               localStorage.setItem("NavCounters", JSON.stringify(res.data.data?.all_counters))
               localStorage.setItem("AllSource", JSON.stringify(res.data.data?.sources))
               localStorage.setItem("AllLabels", JSON.stringify(res.data.data?.labels))
               localStorage.setItem("AllFollowups", JSON.stringify(res.data.data?.followup_statuses))
               localStorage.setItem("AllTags", JSON.stringify(res.data.data?.tag))
               localStorage.setItem("All-User", JSON.stringify(res.data.data?.users))
               localStorage.setItem("AllUsers", JSON.stringify(res.data.data?.lead_holders))
               localStorage.setItem("NextFollowup", JSON.stringify(res.data.data?.next_followup))
               localStorage.setItem("Noti-Count", res.data.data?.notification_count)
            }
         })
         .catch(err => {
         })
   }

   const clearLocal = () => {
      localStorage.setItem(`leadHolder-${customActiveTab}`, "")
      localStorage.setItem(`leadLabel-${customActiveTab}`, "")
      localStorage.setItem(`leadTag-${customActiveTab}`, "")
      localStorage.setItem(`leadName-${customActiveTab}`, "")
      localStorage.setItem(`leadContact-${customActiveTab}`, "")
      localStorage.setItem(`leadSource-${customActiveTab}`, "")
      localStorage.setItem("leadAmount", "")
      localStorage.setItem(`isFollowupFiltered-${customActiveTab}`, false);
      setFilterDate({
         from: "",
         to: "",
      })
      localStorage.setItem(`leadMessage-${customActiveTab}`, "")
      localStorage.setItem(`followupStatus-${customActiveTab}`, "")
      localStorage.setItem("matureStatus", "")
      localStorage.setItem("deadReason", "")
      localStorage.setItem("deadStatus", "")
      localStorage.setItem("latestMessage", "")
      localStorage.setItem("leadRole", "")
      localStorage.setItem("leadEmail", "")
      localStorage.setItem("leadStatus2", "")
      localStorage.setItem("leadDay", "")
      localStorage.setItem("leadStatus3", "")
      localStorage.setItem("leadmaturedby", "")
      localStorage.setItem("ledgerType", "")
      localStorage.setItem("offerType", "")
      localStorage.setItem("PrductGST", "")
      localStorage.setItem("OrderStatus", "")
      localStorage.setItem("OrderPstatus", "")
      localStorage.setItem("leadSort", "")
      localStorage.setItem("leadID", "")
      localStorage.setItem("leadTransfer", "")
      localStorage.setItem("leadFlag", "")
      localStorage.setItem("leadUpdateSince", "")
      localStorage.setItem("leadOrderSince", "")
      localStorage.setItem("leadFirmName", "")
      localStorage.setItem("leadCreated_at", "")
      localStorage.setItem("leadMatured_at", "")
      localStorage.setItem("current_page", "")
      localStorage.setItem("leadAssigned", "")
      localStorage.setItem("CompanyPool", "")
   }

   useEffect(() => {
      getTodayFollowup()
      if (getCurrentUser()?.role_name !== "Administrator") {
         const storedData = localStorage.getItem('DashboardData');
         const storedTimestamp = localStorage.getItem('LastUpdated') ? localStorage.getItem('LastUpdated') : 0;
         const currentTime = new Date();
         const timeDiff = (currentTime - parseInt(storedTimestamp, 10)) / (1000 * 60);
         if (storedData !== "" && timeDiff < 30) {

         } else {
            getAllUsers()
         }
      }
   }, [])

   return (
      <>
         {lead_filter ? (
            <LeadsFilter
               lead_filter={lead_filter}
               setlead_filter={setlead_filter}
               getSearchedLeads={getSearchedLeads}
               type={"Status"}
            />
         ) : (
            ""
         )}
         {campaign_model ? (
            <CampaignModel
               campaign_model={campaign_model}
               setcampaign_model={setcampaign_model}
               leadsSelectedIds={leadsSelectedIds}
               setLeadsSelectedIds={setLeadsSelectedIds}
               getAllLeads={getFilteredLeads}
            />
         ) : (
            ""
         )}
         {assignLabel ? (
            <AssignLabels
               assignLabel={assignLabel}
               setAssignLabel={setAssignLabel}
               leadsSelectedIds={leadsSelectedIds}
               setLeadsSelectedIds={setLeadsSelectedIds}
               getAllLeads={getFilteredLeads}
            />
         ) : (
            ""
         )}
         {confirm_alert ? (
            <SweetAlert
               title="Are you sure?"
               warning
               showCancel
               confirmButtonText="Yes, delete it!"
               confirmBtnBsStyle="success"
               cancelBtnBsStyle="danger"
               onConfirm={() => {
                  setconfirm_alert(false)
                  setsuccess_dlg(true)
                  deleteLeads()
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
               }}
               onCancel={() => setconfirm_alert(false)}
            >
               <input
                  onChange={() => setCheck(1)}
                  type="checkbox"
                  name="is_deleted"
               />
               {"  "}
               <span className="text-danger">Delete it from server as well?</span>
            </SweetAlert>
         ) : null}
         {confirm_alert2 ? (
            <SweetAlert
               title="Are you sure?"
               warning
               showCancel
               confirmButtonText="Yes, delete it!"
               confirmBtnBsStyle="success"
               cancelBtnBsStyle="danger"
               onConfirm={() => {
                  setconfirm_alert2(false)
                  setsuccess_dlg(true)
                  updateFlag()
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
               }}
               onCancel={() => setconfirm_alert2(false)}
            >
               <span className="text-danger">
                  Do you want to update star on selected leads?
               </span>
            </SweetAlert>
         ) : null}
         {confirm_alert3 ? (
            <SweetAlert
               title="Are you sure?"
               warning
               showCancel
               confirmButtonText="Yes, delete it!"
               confirmBtnBsStyle="success"
               cancelBtnBsStyle="danger"
               onConfirm={() => {
                  setconfirm_alert3(false)
                  setsuccess_dlg(true)
                  moveToDead()
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
               }}
               onCancel={() => setconfirm_alert3(false)}
            >
               <span className="text-danger">
                  Do you want to move selected leads to Dead?
               </span>
            </SweetAlert>
         ) : null}
         {modal3 ? (
            <Card style={{ marginTop: "25px" }}>
               <CardBody>
                  <div className="mb-4">
                     <div>
                        <h2 className="card-title mb-4 border-bottom pb-2">
                           Followup Stages
                        </h2>
                     </div>
                     <div className="fs-h3">
                        {JSON.parse(localStorage.getItem("DashboardData")).followup_statuses &&
                           JSON.parse(localStorage.getItem("DashboardData")).followup_statuses.map((item, i) => (
                              <h3 className="mx-1" key={i}>
                                 <button
                                    className="pt-2 p-2 badge"
                                    onClick={() => { getSearchedLeads(item.id, "f-status"), setModal3(false) }}
                                    style={{ backgroundColor: "orange", border: "none" }}
                                 >
                                    {item.name} - {item.count}
                                 </button>
                              </h3>
                           ))}
                     </div>
                  </div>
                  <div className="mb-4">
                     <div>
                        <h2 className="card-title mb-4 border-bottom pb-2">
                           Mature Stages
                        </h2>
                     </div>
                     <div className="fs-h3">
                        {JSON.parse(localStorage.getItem("DashboardData")).mature_status &&
                           JSON.parse(localStorage.getItem("DashboardData")).mature_status.map((item, i) => (
                              <h3 className="mx-1" key={i}>
                                 <button
                                    onClick={() => { getSearchedLeads(item.id, "mature_status"), setModal3(false) }}
                                    className="pt-2 p-2 badge"
                                    style={{ backgroundColor: "orange", border: "none" }}
                                 >
                                    {item.name} - {item.lead_count}
                                 </button>
                              </h3>
                           ))}
                     </div>
                  </div>
                  <div className="mb-4">
                     <div>
                        <h2 className="card-title mb-4 border-bottom pb-2">
                           Reminders
                        </h2>
                     </div>
                     <div className="fs-h3">
                        <h3 className="mx-2">
                           <button
                              className="pt-2 p-2 badge bg-primary"
                              style={{ border: "none" }}
                              onClick={() => { getReminderLeads("reorder"), setModal3(false) }}
                           >
                              Re Order - {JSON.parse(localStorage.getItem("DashboardData")).reminders?.reorder}
                           </button>
                        </h3>
                        <h3 className="mx-2">
                           <button
                              className="pt-2 p-2 badge bg-primary"
                              style={{ border: "none" }}
                              onClick={() => { getReminderLeads("payment_reminder"), setModal3(false) }}
                           >
                              Payment Reminder - {JSON.parse(localStorage.getItem("DashboardData")).reminders?.payment_reminder}
                           </button>
                        </h3>
                     </div>
                  </div>
               </CardBody>
            </Card>
         ) : (
            ""
         )}
         <div className="page-title-box">
            {getCurrentUser()?.user_schemes.length > 0 ?
               <div className="">
                  {getCurrentUser()?.user_schemes?.map((item) => (
                     <>
                        <span className="text-white fs-5 bg-warning p-2">
                           <span style={{ fontWeight: "500" }}>{item.message}</span>
                           . - Expiring on
                           <span style={{ fontWeight: "500" }}> {item.end_date}</span>
                        </span>
                        <br />
                        <br />
                     </>
                  ))}
               </div>
               :
               ""
            }
            <Row className="align-items-center">
               <Col md={2}>
                  <h6 className="page-title">
                     {followupType === "today"
                        ? "Today's Followups"
                        : followupType === "pending"
                           ? "Missed Followups"
                           : "All Followups"}
                     {followupCount?.all !== "" ? <span> ({followupCount?.all})</span> : ""}
                  </h6>
               </Col>
               <Col md={10}>
                  <h6 className="page-title float-end">
                     {getCurrentUser().crm_permission ||
                        getCurrentUser().crmpro_permission === 1 ?
                        <>
                           {followupType === "today" ? (
                              <button
                                 className="btn btn-primary submit__button me-2 mb-2"
                                 onClick={() => {
                                    getFollowupType("")
                                 }}
                              >
                                 All Followups
                                 {followupCount?.all !== "" ? <span> ({followupCount?.all})</span> : ""}
                              </button>
                           ) : (
                              <button
                                 className="btn btn-primary submit__button me-2 mb-2"
                                 onClick={() => {
                                    getFollowupType("today")
                                 }}
                              >
                                 Today's Followups
                                 {followupCount?.today !== "" ? <span> ({followupCount?.today})</span> : ""}
                              </button>
                           )}
                           {followupType === "pending" ? (
                              <button
                                 className="btn btn-primary submit__button me-2 mb-2"
                                 onClick={() => {
                                    getFollowupType("")
                                 }}
                              >
                                 All Followups
                                 {followupCount?.all !== "" ? <span> ({followupCount?.all})</span> : ""}
                              </button>
                           ) : (
                              <button
                                 className="btn btn-primary submit__button me-2 mb-2"
                                 onClick={() => {
                                    getFollowupType("pending")
                                 }}
                              >
                                 Missed Followups
                                 {followupCount?.pending !== "" ? <span> ({followupCount?.pending})</span> : ""}
                              </button>
                           )}
                        </>
                        : null}
                     {getCurrentUser() && getCurrentUser().tag == 1 ? (
                        <>
                           {JSON.parse(localStorage.getItem("AllTags")) &&
                              JSON.parse(localStorage.getItem("AllTags")).map(item => (
                                 <button
                                    key={item.id}
                                    className="btn btn-primary submit__button me-2 mb-2"
                                    onClick={() => {
                                       getSearchedLeads(item.id, "tag")
                                    }}
                                 >
                                    {item.name}
                                 </button>
                              ))}
                        </>
                     ) : (
                        ""
                     )}
                     <div className="btn-group me-2 mb-2">
                        <button type="button" className="btn btn-primary">
                           Sort by
                        </button>
                        <button
                           type="button"
                           className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                           data-bs-toggle="dropdown"
                           aria-expanded="false"
                        >
                           <i className="fa fa-caret-down"></i>
                        </button>
                        <ul className="dropdown-menu">
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => getSearchedLeads("name,asc", "sort")}
                              >
                                 A to Z
                              </span>
                           </li>
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => getSearchedLeads("name,desc", "sort")}
                              >
                                 Z to A
                              </span>
                           </li>
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => getSearchedLeads("id,desc", "sort")}
                              >
                                 Latest Added
                              </span>
                           </li>
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => getSearchedLeads("updated_at,desc", "sort")}
                              >
                                 Latest Updated
                              </span>
                           </li>
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => getSearchedLeads(
                                    "followup_counter,desc",
                                    "sort"
                                 )}
                              >
                                 Most Followups
                              </span>
                           </li>
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => getSearchedLeads(
                                    "followup_counter,asc",
                                    "sort"
                                 )}
                              >
                                 Least Followups
                              </span>
                           </li>
                           <li>
                              <span
                                 className="dropdown-item"
                                 style={{ cursor: "pointer" }}
                                 onClick={() => getSearchedLeads(
                                    "flag_date_time,ASC",
                                    "sort"
                                 )}
                              >
                                 Sort by Star
                              </span>
                           </li>
                        </ul>
                     </div>
                     {getCurrentUser() &&
                        getCurrentUser().role_name !== "Executive" ? (
                        <button
                           className="btn btn-primary submit__button me-2 mb-2"
                           onClick={() => {
                              getTodayFollowup("my_leads")
                           }}
                        >
                           My Leads
                        </button>
                     ) : (
                        ""
                     )}
                     <button
                        className="btn btn-primary submit__button me-2 mb-2"
                        onClick={() => {
                           setlead_filter(true)
                        }}
                     >
                        <i className="fa fa-filter me-2"></i>
                        Filter
                     </button>
                     {modal3 ? (
                        <button
                           className="btn btn-primary submit__button me-2 mb-2"
                           onClick={() => {
                              setModal3(false)
                           }}
                        >
                           Leads Counter
                           <i className="fa fa-arrow-up ms-2"></i>
                        </button>
                     ) : (
                        <button
                           className="btn btn-primary submit__button me-2 mb-2"
                           onClick={() => {
                              setModal3(true)
                           }}
                        >
                           Leads Counter
                           <i className="fa fa-arrow-down ms-2"></i>
                        </button>
                     )}
                     {getPermission() &&
                        getPermission().lead_export.lead_export.view.is_checked ===
                        "yes" ? (
                        <div className="btn-group me-2 mb-2">
                           <button type="button" className="btn btn-primary">
                              Export Leads
                           </button>
                           <button
                              type="button"
                              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                           >
                              <i className="fa fa-caret-down"></i>
                           </button>
                           <ul className="dropdown-menu">
                              <li>
                                 <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => exportLead("All")}
                                 >
                                    All
                                 </span>
                              </li>
                              <li>
                                 <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => exportLead("Filtered")}
                                 >
                                    Filtered
                                 </span>
                              </li>
                              {blur ? (
                                 <li>
                                    <span
                                       className="dropdown-item"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => exportLead("Selected")}
                                    >
                                       Selected
                                    </span>
                                 </li>
                              ) : (
                                 <li>
                                    <span
                                       className="dropdown-item disabled"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => exportLead("Selected")}
                                    >
                                       Selected
                                    </span>
                                 </li>
                              )}
                           </ul>
                        </div>
                     ) : (
                        ""
                     )}
                     {blur ? (
                        <div className="btn-group me-2 mb-2">
                           <button type="button" className="btn btn-primary">
                              Action
                           </button>
                           <button
                              type="button"
                              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                           >
                              <i className="fa fa-caret-down"></i>
                           </button>
                           <ul className="dropdown-menu">
                              {getPermission() &&
                                 getPermission().lead.lead.delete.is_checked === "yes" ? (
                                 <li>
                                    <span
                                       className="dropdown-item"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => setconfirm_alert(true)}
                                    >
                                       Batch delete
                                    </span>
                                 </li>
                              ) : (
                                 <li>
                                    <span
                                       className="dropdown-item disabled"
                                       onClick={() => setconfirm_alert(true)}
                                    >
                                       Batch delete
                                    </span>
                                 </li>
                              )}
                              {getPermission() &&
                                 getPermission().lead_transfer.lead_transfer.view
                                    .is_checked === "yes" ? (
                                 <>
                                    {getCurrentUser().role_name === "Executive" ?
                                       getPermission() &&
                                          getPermission().transfer_request.transfer_request.view
                                             .is_checked === "yes" ?
                                          <li>
                                             <span
                                                className="dropdown-item"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                   setModal4(true)
                                                }}
                                             >
                                                Change Leadholder
                                             </span>
                                          </li>
                                          :
                                          <li>
                                             <span
                                                className="dropdown-item disabled"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                   setModal4(true)
                                                }}
                                             >
                                                Change Leadholder
                                             </span>
                                          </li>
                                       :
                                       <li>
                                          <span
                                             className="dropdown-item"
                                             style={{ cursor: "pointer" }}
                                             onClick={() => {
                                                setModal2(true)
                                             }}
                                          >
                                             Change Leadholder
                                          </span>
                                       </li>
                                    }
                                 </>
                              ) : (
                                 <li>
                                    <span
                                       className="dropdown-item disabled"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => {
                                          setModal2(true)
                                       }}
                                    >
                                       Change Leadholder
                                    </span>
                                 </li>
                              )}
                              <li>
                                 <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                       setconfirm_alert2(true)
                                    }}
                                 >
                                    Update Star
                                 </span>
                              </li>
                              {getCurrentUser() &&
                                 getCurrentUser().role_name !== "Executive" ? (
                                 <>
                                    <li>
                                       <span
                                          className="dropdown-item"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setconfirm_alert3(true)
                                          }}
                                       >
                                          Move to dead
                                       </span>
                                    </li>
                                    <li>
                                       <span
                                          className="dropdown-item"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setAssignLabel(true)
                                          }}
                                       >
                                          Assign Labels
                                       </span>
                                    </li>
                                 </>
                              ) : (
                                 ""
                              )}
                              <li>
                                 <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setcampaign_model(true)}
                                 >
                                    Send Whatsapp
                                 </span>
                              </li>
                           </ul>
                        </div>
                     ) : (
                        <div className="btn-group me-2 mb-2">
                           <button type="button" className="btn btn-primary" disabled>
                              Action
                           </button>
                           <button
                              type="button"
                              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                              disabled
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                           >
                              <i className="fa fa-caret-down"></i>
                           </button>
                           <ul className="dropdown-menu">
                           </ul>
                        </div>
                     )}
                     {/* <button
								className="btn btn-primary submit__button me-2 mb-2"
								onClick={() => {
									setModal3(true)
								}}
							>
                        <i className="fa fa-filter me-2"></i>
								Filter
							</button> */}
                  </h6>
               </Col>
            </Row>
            <Row>
               <Col md={7}>
                  {localStorage.getItem(`isFollowupFiltered-${customActiveTab}`) === "true" ? (
                     <h6 className="">
                        Searched Followup
                        <span> ({searchedCount})</span>
                        <button
                           className="btn btn-success ms-2"
                           style={{
                              cursor: "pointer",
                           }}
                           onClick={() => {
                              getTodayFollowup("refresh"), clearLocal()
                           }}
                        >
                           <i className="fa me-2" aria-hidden="true">
                              &#xf021;
                           </i>
                           Reset Filters
                        </button>
                     </h6>
                  ) : (
                     ""
                  )}
               </Col>
               <Col md={5}>
                  {convertedTime !== "" ? (
                     <h6 className="float-end">
                        <span
                           className="fw-bold btn btn-secondary me-2"
                           style={{ pointerEvents: "none" }}
                        >
                           Last Updated {convertedTime} mins ago
                        </span>
                        <button
                           className="btn btn-primary submit__button"
                           type="button"
                           onClick={() => getTodayFollowup("refresh")}
                           title="Sync Followups"
                        >
                           <i className="fa" aria-hidden="true">
                              &#xf021;
                           </i>
                        </button>
                     </h6>
                  ) : (
                     ""
                  )}
               </Col>
            </Row>
            <Row>
               <Col md={12}>
                  <Nav tabs>
                     <NavItem>
                        <NavLink
                           style={{ cursor: "pointer" }}
                           className={classnames({ active: customActiveTab === 1 })}
                           onClick={() => {
                              toggleCustom(1)
                           }}
                        >
                           {" "}
                           All
                           {followupCount?.all !== "" ? <span> ({followupCount?.all_leads})</span> : ""}
                        </NavLink>
                     </NavItem>
                     {getCurrentUser().crm_permission ||
                        getCurrentUser().crmpro_permission === 1 ?
                        <>
                           <NavItem>
                              <NavLink
                                 style={{ cursor: "pointer" }}
                                 className={classnames({ active: customActiveTab === 4 })}
                                 onClick={() => {
                                    toggleCustom(4)
                                 }}
                              >
                                 {" "}
                                 Leads
                                 {followupCount?.leads !== "" ? <span> ({followupCount?.leads})</span> : ""}
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 style={{ cursor: "pointer" }}
                                 className={classnames({ active: customActiveTab === 5 })}
                                 onClick={() => {
                                    toggleCustom(5)
                                 }}
                              >
                                 {" "}
                                 Customers
                                 {followupCount?.customers !== "" ? <span> ({followupCount?.customers})</span> : ""}
                              </NavLink>
                           </NavItem>
                        </>
                        :
                        <>
                           <NavItem>
                              <NavLink
                                 style={{ cursor: "pointer" }}
                                 className={classnames({ active: customActiveTab === 2 })}
                                 onClick={() => {
                                    toggleCustom(2)
                                 }}
                              >
                                 {" "}
                                 Today
                                 {followupCount?.today !== "" ? <span> ({followupCount?.today})</span> : ""}
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 style={{ cursor: "pointer" }}
                                 className={classnames({ active: customActiveTab === 3 })}
                                 onClick={() => {
                                    toggleCustom(3)
                                 }}
                              >
                                 {" "}
                                 Pending
                                 {followupCount?.pending !== "" ? <span> ({followupCount?.pending})</span> : ""}
                              </NavLink>
                           </NavItem>
                        </>
                     }
                  </Nav>
               </Col>
            </Row>
         </div>
         <Modal isOpen={modal2} centered={true}>
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  transferLeads(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Change Leadholder
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => setModal2(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <div className="mb-3">
                     <AvField
                        className="select-arrow"
                        label="Users"
                        type="select"
                        name="user_id"
                     >
                        <option value="" hidden>
                           Select User
                        </option>
                        <option value="0">Company Pool</option>
                        {JSON.parse(localStorage.getItem("AllUsers")) &&
                           JSON.parse(localStorage.getItem("AllUsers")).map((item, i) => (
                              <option value={item.id} key={i}>
                                 {item.name}
                              </option>
                           ))}
                     </AvField>
                  </div>
                  <div className="d-flex">
                     <div className="mb-3 me-2">
                        <AvField
                           label="Transfer as New"
                           type="checkbox"
                           name="transfer_as_new"
                           onClick={e => getCheckValue(e.target)}
                        />
                     </div>
                     {transferNew == "1" ? (
                        <div className="mb-3 ms-4">
                           <AvField
                              label="Hide Lead History"
                              type="checkbox"
                              name="hide_lead_history"
                              onClick={e => getCheckValue2(e.target)}
                           />
                        </div>
                     ) : (
                        ""
                     )}
                  </div>
                  <div className="mb-3">
                     <label>Select Option:</label>
                     <AvRadioGroup inline name="type">
                        <AvRadio label="Today's Priority" value="priority" />
                        <AvRadio label="Tomorrow's Followup" value="followup" />
                     </AvRadioGroup>
                  </div>
                  <div className="modal-footer">
                     <div className="text-center">
                        {loading ? (
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                              disabled
                           >
                              Loading
                           </button>
                        ) : (
                           <button
                              className="btn btn-primary"
                              type="submit"
                           // onClick={() => setModal2(false)}
                           >
                              Submit
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </AvForm>
         </Modal>
         <Modal isOpen={modal4} centered={true}>
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  transferRequest(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Change Leadholder Request
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => setModal4(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <div className="mb-3">
                     <AvField
                        className="select-arrow"
                        label="Users"
                        type="select"
                        name="user_id"
                     >
                        <option value="" hidden>
                           Select User
                        </option>
                        <option value="0">Company Pool</option>
                        {JSON.parse(localStorage.getItem("All-User")) &&
                           JSON.parse(localStorage.getItem("All-User")).map((item, i) => (
                              getCurrentUser()?.id !== item.id ?
                                 <option value={item.id} key={i}>
                                    {item.name}
                                 </option>
                                 :
                                 ""
                           ))}
                     </AvField>
                  </div>
                  <div className="modal-footer">
                     <div className="text-center">
                        {loading ? (
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                              disabled
                           >
                              Loading
                           </button>
                        ) : (
                           <button
                              className="btn btn-primary"
                              type="submit"
                              onClick={() => setModal4(false)}
                           >
                              Submit
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </AvForm>
         </Modal>
         <div className="card">
            <div className="card-body">
               <div className="lead_table table table-sm table-bordered table-striped table-responsive">
                  <table style={{ width: "100%" }}>
                     <thead>
                        {customActiveTab === 5 ?
                           <tr className="static-header">
                              <th>
                                 <input
                                    onClick={e => selectAllCheck(e.target)}
                                    className="p-0 d-inline-block"
                                    type="checkbox"
                                 />
                              </th>
                              <th>#</th>
                              <th>
                                 <div
                                    className="position-relative"
                                    style={{ whiteSpace: "nowrap" }}
                                 >
                                    Name
                                    {af5_name_filter ? (
                                       <>
                                          <span
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setaf5_name_filter(false)}
                                          >
                                             <i className="fa fa-times fa-lg ms-2"></i>
                                          </span>
                                       </>
                                    ) : (
                                       <>
                                          {localStorage.getItem(`leadName-${customActiveTab}`) == "" || localStorage.getItem(`leadName-${customActiveTab}`) == null ? (
                                             <span
                                                className=""
                                                style={{ cursor: "pointer" }}
                                                onClick={() => setaf5_name_filter(true)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          ) : (
                                             <span
                                                className=""
                                                style={{ cursor: "pointer", color: "orange" }}
                                                onClick={() => setaf5_name_filter(true)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          )}
                                       </>
                                    )}
                                    <InputFilter
                                       af5_name_filter={af5_name_filter}
                                       setaf5_name_filter={setaf5_name_filter}
                                       customActiveTab={customActiveTab}
                                       getSearchedLeads={getSearchedLeads}
                                    />
                                 </div>
                              </th>
                              <th>
                                 <div
                                    className="position-relative"
                                    style={{ whiteSpace: "nowrap" }}
                                 >
                                    Contact
                                    {af5_contact_filter ? (
                                       <>
                                          <span
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setaf5_contact_filter(false)}
                                          >
                                             <i className="fa fa-times fa-lg ms-2"></i>
                                          </span>
                                       </>
                                    ) : (
                                       <>
                                          {localStorage.getItem(`leadContact-${customActiveTab}`) == "" || localStorage.getItem(`leadContact-${customActiveTab}`) == null ? (
                                             <span
                                                className=""
                                                style={{ cursor: "pointer" }}
                                                onClick={() => setaf5_contact_filter(true)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          ) : (
                                             <span
                                                className=""
                                                style={{ cursor: "pointer", color: "orange" }}
                                                onClick={() => setaf5_contact_filter(true)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          )}
                                       </>
                                    )}
                                    <InputFilter
                                       af5_contact_filter={af5_contact_filter}
                                       setaf5_contact_filter={setaf5_contact_filter}
                                       customActiveTab={customActiveTab}
                                       getSearchedLeads={getSearchedLeads}
                                    />
                                 </div>
                              </th>
                              <th>
                                 <div
                                    className="position-relative"
                                    style={{ whiteSpace: "nowrap" }}
                                 >
                                    Lead Source
                                    {af5_leadsource_filter ? (
                                       <>
                                          <span
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setaf5_leadsource_filter(false)}
                                          >
                                             <i className="fa fa-times fa-lg ms-2"></i>
                                          </span>
                                       </>
                                    ) : (
                                       <>
                                          {localStorage.getItem(`leadSource-${customActiveTab}`) == "" || localStorage.getItem(`leadSource-${customActiveTab}`) == null ? (
                                             <span
                                                className=""
                                                style={{ cursor: "pointer" }}
                                                onClick={() => setaf5_leadsource_filter(true)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          ) : (
                                             <span
                                                className=""
                                                style={{ cursor: "pointer", color: "orange" }}
                                                onClick={() => setaf5_leadsource_filter(true)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          )}
                                       </>
                                    )}
                                    <MultipleChoice
                                       af5_leadsource_filter={af5_leadsource_filter}
                                       setaf5_leadsource_filter={setaf5_leadsource_filter}
                                       customActiveTab={customActiveTab}
                                       getSearchedLeads={getSearchedLeads}
                                    />
                                 </div>
                              </th>
                              {getCurrentUser() && getCurrentUser().tag == 1 ? (
                                 <th>
                                    <div
                                       className="position-relative"
                                       style={{ whiteSpace: "nowrap" }}
                                    >
                                       Tag
                                       {af5_tag_filter ? (
                                          <>
                                             <span
                                                style={{ cursor: "pointer", color: "orange" }}
                                                onClick={() => setaf5_tag_filter(false)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          </>
                                       ) : (
                                          <>
                                             {localStorage.getItem(`leadTag-${customActiveTab}`) == "" || localStorage.getItem(`leadTag-${customActiveTab}`) == null ? (
                                                <span
                                                   className=""
                                                   style={{ cursor: "pointer" }}
                                                   onClick={() => setaf5_tag_filter(true)}
                                                >
                                                   <i className="fa fa-filter ms-2"></i>
                                                </span>
                                             ) : (
                                                <span
                                                   className=""
                                                   style={{ cursor: "pointer", color: "orange" }}
                                                   onClick={() => setaf5_tag_filter(true)}
                                                >
                                                   <i className="fa fa-filter ms-2"></i>
                                                </span>
                                             )}
                                          </>
                                       )}
                                       <MultipleChoice
                                          af5_tag_filter={af5_tag_filter}
                                          setaf5_tag_filter={setaf5_tag_filter}
                                          customActiveTab={customActiveTab}
                                          getSearchedLeads={getSearchedLeads}
                                       />
                                    </div>
                                 </th>
                              ) : (
                                 ""
                              )}
                              <th>
                                 <div
                                    className="position-relative"
                                    style={{ whiteSpace: "nowrap" }}
                                 >
                                    Label
                                    {af5_label_filter ? (
                                       <>
                                          <span
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setaf5_label_filter(false)}
                                          >
                                             <i className="fa fa-times fa-lg ms-2"></i>
                                          </span>
                                       </>
                                    ) : (
                                       <>
                                          {localStorage.getItem(`leadLabel-${customActiveTab}`) == "" || localStorage.getItem(`leadLabel-${customActiveTab}`) == null ? (
                                             <span
                                                className=""
                                                style={{ cursor: "pointer" }}
                                                onClick={() => setaf5_label_filter(true)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          ) : (
                                             <span
                                                className=""
                                                style={{ cursor: "pointer", color: "orange" }}
                                                onClick={() => setaf5_label_filter(true)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          )}
                                       </>
                                    )}
                                    <MultipleChoice
                                       af5_label_filter={af5_label_filter}
                                       setaf5_label_filter={setaf5_label_filter}
                                       customActiveTab={customActiveTab}
                                       getSearchedLeads={getSearchedLeads}
                                    />
                                 </div>
                              </th>
                              {getCurrentUser().role_name == "Executive" ? (
                                 ""
                              ) : (
                                 <th>
                                    <div
                                       className="position-relative"
                                       style={{ whiteSpace: "nowrap" }}
                                    >
                                       Leadholder
                                       {af5_leadholder_filter ? (
                                          <>
                                             <span
                                                style={{ cursor: "pointer", color: "orange" }}
                                                onClick={() => setaf5_leadholder_filter(false)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          </>
                                       ) : (
                                          <>
                                             {localStorage.getItem(`leadHolder-${customActiveTab}`) == "" || localStorage.getItem(`leadHolder-${customActiveTab}`) == null ? (
                                                <span
                                                   className=""
                                                   style={{ cursor: "pointer" }}
                                                   onClick={() => setaf5_leadholder_filter(true)}
                                                >
                                                   <i className="fa fa-filter ms-2"></i>
                                                </span>
                                             ) : (
                                                <span
                                                   className=""
                                                   style={{ cursor: "pointer", color: "orange" }}
                                                   onClick={() => setaf5_leadholder_filter(true)}
                                                >
                                                   <i className="fa fa-filter ms-2"></i>
                                                </span>
                                             )}
                                          </>
                                       )}
                                       <MultipleChoice
                                          af5_leadholder_filter={af5_leadholder_filter}
                                          setaf5_leadholder_filter={setaf5_leadholder_filter}
                                          getSearchedLeads={getSearchedLeads}
                                       />
                                    </div>
                                 </th>
                              )}
                              <th>
                                 <div
                                    className="position-relative"
                                    style={{ whiteSpace: "nowrap" }}
                                 >
                                    Status
                                    {af5_followups_filter ? (
                                       <>
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setaf5_followups_filter(false)}
                                          >
                                             <i className="fa fa-times fa-lg ms-2"></i>
                                          </span>
                                       </>
                                    ) : (
                                       <>
                                          {localStorage.getItem(`followupStatus-${customActiveTab}`) == null || localStorage.getItem(`followupStatus-${customActiveTab}`) == "" ? (
                                             <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() => setaf5_followups_filter(true)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          ) : (
                                             <span
                                                style={{ cursor: "pointer", color: "orange" }}
                                                onClick={() => setaf5_followups_filter(true)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          )}
                                       </>
                                    )}
                                    <MultipleChoice
                                       af5_followups_filter={af5_followups_filter}
                                       setaf5_followups_filter={setaf5_followups_filter}
                                       customActiveTab={customActiveTab}
                                       getSearchedLeads={getSearchedLeads}
                                    />
                                 </div>
                              </th>
                              <th>
                                 <div
                                    className="position-relative"
                                    style={{ whiteSpace: "nowrap" }}
                                 >
                                    Followup
                                    {af5_date_filter ? (
                                       <>
                                          <span
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setaf5_date_filter(false)}
                                          >
                                             <i className="fa fa-times fa-lg ms-2"></i>
                                          </span>
                                       </>
                                    ) : (
                                       <>
                                          {getFilterDate() && getFilterDate().from == "" ? (
                                             <span
                                                className=""
                                                style={{ cursor: "pointer" }}
                                                onClick={() => setaf5_date_filter(true)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          ) : (
                                             <span
                                                className=""
                                                style={{ cursor: "pointer", color: "orange" }}
                                                onClick={() => setaf5_date_filter(true)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          )}
                                       </>
                                    )}
                                    <DateFilter
                                       af5_date_filter={af5_date_filter}
                                       setaf5_date_filter={setaf5_date_filter}
                                       customActiveTab={customActiveTab}
                                       getSearchedLeads={getSearchedLeads}
                                    />
                                 </div>
                              </th>
                              <th>
                                 <div
                                    className="position-relative"
                                    style={{ whiteSpace: "nowrap" }}
                                 >
                                    Last Message
                                    {af5_message_filter ? (
                                       <>
                                          <span
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setaf5_message_filter(false)}
                                          >
                                             <i className="fa fa-times fa-lg ms-2"></i>
                                          </span>
                                       </>
                                    ) : (
                                       <>
                                          {localStorage.getItem(`leadMessage-${customActiveTab}`) == null || localStorage.getItem(`leadMessage-${customActiveTab}`) == "" ? (
                                             <span
                                                className=""
                                                style={{ cursor: "pointer" }}
                                                onClick={() => setaf5_message_filter(true)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          ) : (
                                             <span
                                                className=""
                                                style={{ cursor: "pointer", color: "orange" }}
                                                onClick={() => setaf5_message_filter(true)}
                                             >
                                                <i className="fa fa-filter ms-2"></i>
                                             </span>
                                          )}
                                       </>
                                    )}
                                    <InputFilter
                                       af5_message_filter={af5_message_filter}
                                       setaf5_message_filter={setaf5_message_filter}
                                       customActiveTab={customActiveTab}
                                       getSearchedLeads={getSearchedLeads}
                                    />
                                 </div>
                              </th>
                              <th>Action</th>
                           </tr>
                           :
                           customActiveTab === 4 ?
                              <tr className="static-header">
                                 <th>
                                    <input
                                       onClick={e => selectAllCheck(e.target)}
                                       className="p-0 d-inline-block"
                                       type="checkbox"
                                    />
                                 </th>
                                 <th>#</th>
                                 <th>
                                    <div
                                       className="position-relative"
                                       style={{ whiteSpace: "nowrap" }}
                                    >
                                       Name
                                       {af4_name_filter ? (
                                          <>
                                             <span
                                                style={{ cursor: "pointer", color: "orange" }}
                                                onClick={() => setaf4_name_filter(false)}
                                             >
                                                <i className="fa fa-times fa-lg ms-2"></i>
                                             </span>
                                          </>
                                       ) : (
                                          <>
                                             {localStorage.getItem(`leadName-${customActiveTab}`) == "" || localStorage.getItem(`leadName-${customActiveTab}`) == null ? (
                                                <span
                                                   className=""
                                                   style={{ cursor: "pointer" }}
                                                   onClick={() => setaf4_name_filter(true)}
                                                >
                                                   <i className="fa fa-filter ms-2"></i>
                                                </span>
                                             ) : (
                                                <span
                                                   className=""
                                                   style={{ cursor: "pointer", color: "orange" }}
                                                   onClick={() => setaf4_name_filter(true)}
                                                >
                                                   <i className="fa fa-filter ms-2"></i>
                                                </span>
                                             )}
                                          </>
                                       )}
                                       <InputFilter
                                          af4_name_filter={af4_name_filter}
                                          setaf4_name_filter={setaf4_name_filter}
                                          customActiveTab={customActiveTab}
                                          getSearchedLeads={getSearchedLeads}
                                       />
                                    </div>
                                 </th>
                                 <th>
                                    <div
                                       className="position-relative"
                                       style={{ whiteSpace: "nowrap" }}
                                    >
                                       Contact
                                       {af4_contact_filter ? (
                                          <>
                                             <span
                                                style={{ cursor: "pointer", color: "orange" }}
                                                onClick={() => setaf4_contact_filter(false)}
                                             >
                                                <i className="fa fa-times fa-lg ms-2"></i>
                                             </span>
                                          </>
                                       ) : (
                                          <>
                                             {localStorage.getItem(`leadContact-${customActiveTab}`) == "" || localStorage.getItem(`leadContact-${customActiveTab}`) == null ? (
                                                <span
                                                   className=""
                                                   style={{ cursor: "pointer" }}
                                                   onClick={() => setaf4_contact_filter(true)}
                                                >
                                                   <i className="fa fa-filter ms-2"></i>
                                                </span>
                                             ) : (
                                                <span
                                                   className=""
                                                   style={{ cursor: "pointer", color: "orange" }}
                                                   onClick={() => setaf4_contact_filter(true)}
                                                >
                                                   <i className="fa fa-filter ms-2"></i>
                                                </span>
                                             )}
                                          </>
                                       )}
                                       <InputFilter
                                          af4_contact_filter={af4_contact_filter}
                                          setaf4_contact_filter={setaf4_contact_filter}
                                          customActiveTab={customActiveTab}
                                          getSearchedLeads={getSearchedLeads}
                                       />
                                    </div>
                                 </th>
                                 <th>
                                    <div
                                       className="position-relative"
                                       style={{ whiteSpace: "nowrap" }}
                                    >
                                       Lead Source
                                       {af4_leadsource_filter ? (
                                          <>
                                             <span
                                                style={{ cursor: "pointer", color: "orange" }}
                                                onClick={() => setaf4_leadsource_filter(false)}
                                             >
                                                <i className="fa fa-times fa-lg ms-2"></i>
                                             </span>
                                          </>
                                       ) : (
                                          <>
                                             {localStorage.getItem(`leadSource-${customActiveTab}`) == "" || localStorage.getItem(`leadSource-${customActiveTab}`) == null ? (
                                                <span
                                                   className=""
                                                   style={{ cursor: "pointer" }}
                                                   onClick={() => setaf4_leadsource_filter(true)}
                                                >
                                                   <i className="fa fa-filter ms-2"></i>
                                                </span>
                                             ) : (
                                                <span
                                                   className=""
                                                   style={{ cursor: "pointer", color: "orange" }}
                                                   onClick={() => setaf4_leadsource_filter(true)}
                                                >
                                                   <i className="fa fa-filter ms-2"></i>
                                                </span>
                                             )}
                                          </>
                                       )}
                                       <MultipleChoice
                                          af4_leadsource_filter={af4_leadsource_filter}
                                          setaf4_leadsource_filter={setaf4_leadsource_filter}
                                          customActiveTab={customActiveTab}
                                          getSearchedLeads={getSearchedLeads}
                                       />
                                    </div>
                                 </th>
                                 {getCurrentUser() && getCurrentUser().tag == 1 ? (
                                    <th>
                                       <div
                                          className="position-relative"
                                          style={{ whiteSpace: "nowrap" }}
                                       >
                                          Tag
                                          {af4_tag_filter ? (
                                             <>
                                                <span
                                                   style={{ cursor: "pointer", color: "orange" }}
                                                   onClick={() => setaf4_tag_filter(false)}
                                                >
                                                   <i className="fa fa-filter ms-2"></i>
                                                </span>
                                             </>
                                          ) : (
                                             <>
                                                {localStorage.getItem(`leadTag-${customActiveTab}`) == "" || localStorage.getItem(`leadTag-${customActiveTab}`) == null ? (
                                                   <span
                                                      className=""
                                                      style={{ cursor: "pointer" }}
                                                      onClick={() => setaf4_tag_filter(true)}
                                                   >
                                                      <i className="fa fa-filter ms-2"></i>
                                                   </span>
                                                ) : (
                                                   <span
                                                      className=""
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf4_tag_filter(true)}
                                                   >
                                                      <i className="fa fa-filter ms-2"></i>
                                                   </span>
                                                )}
                                             </>
                                          )}
                                          <MultipleChoice
                                             af4_tag_filter={af4_tag_filter}
                                             setaf4_tag_filter={setaf4_tag_filter}
                                             customActiveTab={customActiveTab}
                                             getSearchedLeads={getSearchedLeads}
                                          />
                                       </div>
                                    </th>
                                 ) : (
                                    ""
                                 )}
                                 <th>
                                    <div
                                       className="position-relative"
                                       style={{ whiteSpace: "nowrap" }}
                                    >
                                       Label
                                       {af4_label_filter ? (
                                          <>
                                             <span
                                                style={{ cursor: "pointer", color: "orange" }}
                                                onClick={() => setaf4_label_filter(false)}
                                             >
                                                <i className="fa fa-times fa-lg ms-2"></i>
                                             </span>
                                          </>
                                       ) : (
                                          <>
                                             {localStorage.getItem(`leadLabel-${customActiveTab}`) == "" || localStorage.getItem(`leadLabel-${customActiveTab}`) == null ? (
                                                <span
                                                   className=""
                                                   style={{ cursor: "pointer" }}
                                                   onClick={() => setaf4_label_filter(true)}
                                                >
                                                   <i className="fa fa-filter ms-2"></i>
                                                </span>
                                             ) : (
                                                <span
                                                   className=""
                                                   style={{ cursor: "pointer", color: "orange" }}
                                                   onClick={() => setaf4_label_filter(true)}
                                                >
                                                   <i className="fa fa-filter ms-2"></i>
                                                </span>
                                             )}
                                          </>
                                       )}
                                       <MultipleChoice
                                          af4_label_filter={af4_label_filter}
                                          setaf4_label_filter={setaf4_label_filter}
                                          customActiveTab={customActiveTab}
                                          getSearchedLeads={getSearchedLeads}
                                       />
                                    </div>
                                 </th>
                                 {getCurrentUser().role_name == "Executive" ? (
                                    ""
                                 ) : (
                                    <th>
                                       <div
                                          className="position-relative"
                                          style={{ whiteSpace: "nowrap" }}
                                       >
                                          Leadholder
                                          {af4_leadholder_filter ? (
                                             <>
                                                <span
                                                   style={{ cursor: "pointer", color: "orange" }}
                                                   onClick={() => setaf4_leadholder_filter(false)}
                                                >
                                                   <i className="fa fa-filter ms-2"></i>
                                                </span>
                                             </>
                                          ) : (
                                             <>
                                                {localStorage.getItem(`leadHolder-${customActiveTab}`) == "" || localStorage.getItem(`leadHolder-${customActiveTab}`) == null ? (
                                                   <span
                                                      className=""
                                                      style={{ cursor: "pointer" }}
                                                      onClick={() => setaf4_leadholder_filter(true)}
                                                   >
                                                      <i className="fa fa-filter ms-2"></i>
                                                   </span>
                                                ) : (
                                                   <span
                                                      className=""
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf4_leadholder_filter(true)}
                                                   >
                                                      <i className="fa fa-filter ms-2"></i>
                                                   </span>
                                                )}
                                             </>
                                          )}
                                          <MultipleChoice
                                             af4_leadholder_filter={af4_leadholder_filter}
                                             setaf4_leadholder_filter={setaf4_leadholder_filter}
                                             customActiveTab={customActiveTab}
                                             getSearchedLeads={getSearchedLeads}
                                          />
                                       </div>
                                    </th>
                                 )}
                                 <th>
                                    <div
                                       className="position-relative"
                                       style={{ whiteSpace: "nowrap" }}
                                    >
                                       Status
                                       {af4_followups_filter ? (
                                          <>
                                             <span
                                                className=""
                                                style={{ cursor: "pointer", color: "orange" }}
                                                onClick={() => setaf4_followups_filter(false)}
                                             >
                                                <i className="fa fa-times fa-lg ms-2"></i>
                                             </span>
                                          </>
                                       ) : (
                                          <>
                                             {localStorage.getItem(`followupStatus-${customActiveTab}`) == null || localStorage.getItem(`followupStatus-${customActiveTab}`) == "" ? (
                                                <span
                                                   style={{ cursor: "pointer" }}
                                                   onClick={() => setaf4_followups_filter(true)}
                                                >
                                                   <i className="fa fa-filter ms-2"></i>
                                                </span>
                                             ) : (
                                                <span
                                                   style={{ cursor: "pointer", color: "orange" }}
                                                   onClick={() => setaf4_followups_filter(true)}
                                                >
                                                   <i className="fa fa-filter ms-2"></i>
                                                </span>
                                             )}
                                          </>
                                       )}
                                       <MultipleChoice
                                          af4_followups_filter={af4_followups_filter}
                                          setaf4_followups_filter={setaf4_followups_filter}
                                          getSearchedLeads={getSearchedLeads}
                                       />
                                    </div>
                                 </th>
                                 <th>
                                    <div
                                       className="position-relative"
                                       style={{ whiteSpace: "nowrap" }}
                                    >
                                       Followup
                                       {af4_date_filter ? (
                                          <>
                                             <span
                                                style={{ cursor: "pointer", color: "orange" }}
                                                onClick={() => setaf4_date_filter(false)}
                                             >
                                                <i className="fa fa-times fa-lg ms-2"></i>
                                             </span>
                                          </>
                                       ) : (
                                          <>
                                             {getFilterDate() && getFilterDate().from == "" ? (
                                                <span
                                                   className=""
                                                   style={{ cursor: "pointer" }}
                                                   onClick={() => setaf4_date_filter(true)}
                                                >
                                                   <i className="fa fa-filter ms-2"></i>
                                                </span>
                                             ) : (
                                                <span
                                                   className=""
                                                   style={{ cursor: "pointer", color: "orange" }}
                                                   onClick={() => setaf4_date_filter(true)}
                                                >
                                                   <i className="fa fa-filter ms-2"></i>
                                                </span>
                                             )}
                                          </>
                                       )}
                                       <DateFilter
                                          af4_date_filter={af4_date_filter}
                                          setaf4_date_filter={setaf4_date_filter}
                                          customActiveTab={customActiveTab}
                                          getSearchedLeads={getSearchedLeads}
                                       />
                                    </div>
                                 </th>
                                 <th>
                                    <div
                                       className="position-relative"
                                       style={{ whiteSpace: "nowrap" }}
                                    >
                                       Last Message
                                       {af4_message_filter ? (
                                          <>
                                             <span
                                                style={{ cursor: "pointer", color: "orange" }}
                                                onClick={() => setaf4_message_filter(false)}
                                             >
                                                <i className="fa fa-times fa-lg ms-2"></i>
                                             </span>
                                          </>
                                       ) : (
                                          <>
                                             {localStorage.getItem(`leadMessage-${customActiveTab}`) == null || localStorage.getItem(`leadMessage-${customActiveTab}`) == "" ? (
                                                <span
                                                   className=""
                                                   style={{ cursor: "pointer" }}
                                                   onClick={() => setaf4_message_filter(true)}
                                                >
                                                   <i className="fa fa-filter ms-2"></i>
                                                </span>
                                             ) : (
                                                <span
                                                   className=""
                                                   style={{ cursor: "pointer", color: "orange" }}
                                                   onClick={() => setaf4_message_filter(true)}
                                                >
                                                   <i className="fa fa-filter ms-2"></i>
                                                </span>
                                             )}
                                          </>
                                       )}
                                       <InputFilter
                                          af4_message_filter={af4_message_filter}
                                          setaf4_message_filter={setaf4_message_filter}
                                          customActiveTab={customActiveTab}
                                          getSearchedLeads={getSearchedLeads}
                                       />
                                    </div>
                                 </th>
                                 <th>Action</th>
                              </tr>
                              :
                              customActiveTab === 3 ?
                                 <tr className="static-header">
                                    <th>
                                       <input
                                          onClick={e => selectAllCheck(e.target)}
                                          className="p-0 d-inline-block"
                                          type="checkbox"
                                       />
                                    </th>
                                    <th>#</th>
                                    <th>
                                       <div
                                          className="position-relative"
                                          style={{ whiteSpace: "nowrap" }}
                                       >
                                          Name
                                          {af3_name_filter ? (
                                             <>
                                                <span
                                                   style={{ cursor: "pointer", color: "orange" }}
                                                   onClick={() => setaf3_name_filter(false)}
                                                >
                                                   <i className="fa fa-times fa-lg ms-2"></i>
                                                </span>
                                             </>
                                          ) : (
                                             <>
                                                {localStorage.getItem(`leadName-${customActiveTab}`) == "" || localStorage.getItem(`leadName-${customActiveTab}`) == null ? (
                                                   <span
                                                      className=""
                                                      style={{ cursor: "pointer" }}
                                                      onClick={() => setaf3_name_filter(true)}
                                                   >
                                                      <i className="fa fa-filter ms-2"></i>
                                                   </span>
                                                ) : (
                                                   <span
                                                      className=""
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf3_name_filter(true)}
                                                   >
                                                      <i className="fa fa-filter ms-2"></i>
                                                   </span>
                                                )}
                                             </>
                                          )}
                                          <InputFilter
                                             af3_name_filter={af3_name_filter}
                                             setaf3_name_filter={setaf3_name_filter}
                                             customActiveTab={customActiveTab}
                                             getSearchedLeads={getSearchedLeads}
                                          />
                                       </div>
                                    </th>
                                    <th>
                                       <div
                                          className="position-relative"
                                          style={{ whiteSpace: "nowrap" }}
                                       >
                                          Contact
                                          {af3_contact_filter ? (
                                             <>
                                                <span
                                                   style={{ cursor: "pointer", color: "orange" }}
                                                   onClick={() => setaf3_contact_filter(false)}
                                                >
                                                   <i className="fa fa-times fa-lg ms-2"></i>
                                                </span>
                                             </>
                                          ) : (
                                             <>
                                                {localStorage.getItem(`leadContact-${customActiveTab}`) == "" || localStorage.getItem(`leadContact-${customActiveTab}`) == null ? (
                                                   <span
                                                      className=""
                                                      style={{ cursor: "pointer" }}
                                                      onClick={() => setaf3_contact_filter(true)}
                                                   >
                                                      <i className="fa fa-filter ms-2"></i>
                                                   </span>
                                                ) : (
                                                   <span
                                                      className=""
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf3_contact_filter(true)}
                                                   >
                                                      <i className="fa fa-filter ms-2"></i>
                                                   </span>
                                                )}
                                             </>
                                          )}
                                          <InputFilter
                                             af3_contact_filter={af3_contact_filter}
                                             setaf3_contact_filter={setaf3_contact_filter}
                                             customActiveTab={customActiveTab}
                                             getSearchedLeads={getSearchedLeads}
                                          />
                                       </div>
                                    </th>
                                    <th>
                                       <div
                                          className="position-relative"
                                          style={{ whiteSpace: "nowrap" }}
                                       >
                                          Lead Source
                                          {af3_leadsource_filter ? (
                                             <>
                                                <span
                                                   style={{ cursor: "pointer", color: "orange" }}
                                                   onClick={() => setaf3_leadsource_filter(false)}
                                                >
                                                   <i className="fa fa-times fa-lg ms-2"></i>
                                                </span>
                                             </>
                                          ) : (
                                             <>
                                                {localStorage.getItem(`leadSource-${customActiveTab}`) == "" || localStorage.getItem(`leadSource-${customActiveTab}`) == null ? (
                                                   <span
                                                      className=""
                                                      style={{ cursor: "pointer" }}
                                                      onClick={() => setaf3_leadsource_filter(true)}
                                                   >
                                                      <i className="fa fa-filter ms-2"></i>
                                                   </span>
                                                ) : (
                                                   <span
                                                      className=""
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf3_leadsource_filter(true)}
                                                   >
                                                      <i className="fa fa-filter ms-2"></i>
                                                   </span>
                                                )}
                                             </>
                                          )}
                                          <MultipleChoice
                                             af3_leadsource_filter={af3_leadsource_filter}
                                             setaf3_leadsource_filter={setaf3_leadsource_filter}
                                             customActiveTab={customActiveTab}
                                             getSearchedLeads={getSearchedLeads}
                                          />
                                       </div>
                                    </th>
                                    {getCurrentUser() && getCurrentUser().tag == 1 ? (
                                       <th>
                                          <div
                                             className="position-relative"
                                             style={{ whiteSpace: "nowrap" }}
                                          >
                                             Tag
                                             {af3_tag_filter ? (
                                                <>
                                                   <span
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf3_tag_filter(false)}
                                                   >
                                                      <i className="fa fa-filter ms-2"></i>
                                                   </span>
                                                </>
                                             ) : (
                                                <>
                                                   {localStorage.getItem(`leadTag-${customActiveTab}`) == "" || localStorage.getItem(`leadTag-${customActiveTab}`) == null ? (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer" }}
                                                         onClick={() => setaf3_tag_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   ) : (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer", color: "orange" }}
                                                         onClick={() => setaf3_tag_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   )}
                                                </>
                                             )}
                                             <MultipleChoice
                                                af3_tag_filter={af3_tag_filter}
                                                setaf3_tag_filter={setaf3_tag_filter}
                                                customActiveTab={customActiveTab}
                                                getSearchedLeads={getSearchedLeads}
                                             />
                                          </div>
                                       </th>
                                    ) : (
                                       ""
                                    )}
                                    <th>
                                       <div
                                          className="position-relative"
                                          style={{ whiteSpace: "nowrap" }}
                                       >
                                          Label
                                          {af3_label_filter ? (
                                             <>
                                                <span
                                                   style={{ cursor: "pointer", color: "orange" }}
                                                   onClick={() => setaf3_label_filter(false)}
                                                >
                                                   <i className="fa fa-times fa-lg ms-2"></i>
                                                </span>
                                             </>
                                          ) : (
                                             <>
                                                {localStorage.getItem(`leadLabel-${customActiveTab}`) == "" || localStorage.getItem(`leadLabel-${customActiveTab}`) == null ? (
                                                   <span
                                                      className=""
                                                      style={{ cursor: "pointer" }}
                                                      onClick={() => setaf3_label_filter(true)}
                                                   >
                                                      <i className="fa fa-filter ms-2"></i>
                                                   </span>
                                                ) : (
                                                   <span
                                                      className=""
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf3_label_filter(true)}
                                                   >
                                                      <i className="fa fa-filter ms-2"></i>
                                                   </span>
                                                )}
                                             </>
                                          )}
                                          <MultipleChoice
                                             af3_label_filter={af3_label_filter}
                                             setaf3_label_filter={setaf3_label_filter}
                                             customActiveTab={customActiveTab}
                                             getSearchedLeads={getSearchedLeads}
                                          />
                                       </div>
                                    </th>
                                    {getCurrentUser().role_name == "Executive" ? (
                                       ""
                                    ) : (
                                       <th>
                                          <div
                                             className="position-relative"
                                             style={{ whiteSpace: "nowrap" }}
                                          >
                                             Leadholder
                                             {af3_leadholder_filter ? (
                                                <>
                                                   <span
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf3_leadholder_filter(false)}
                                                   >
                                                      <i className="fa fa-filter ms-2"></i>
                                                   </span>
                                                </>
                                             ) : (
                                                <>
                                                   {localStorage.getItem(`leadHolder-${customActiveTab}`) == "" || localStorage.getItem(`leadHolder-${customActiveTab}`) == null ? (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer" }}
                                                         onClick={() => setaf3_leadholder_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   ) : (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer", color: "orange" }}
                                                         onClick={() => setaf3_leadholder_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   )}
                                                </>
                                             )}
                                             <MultipleChoice
                                                af3_leadholder_filter={af3_leadholder_filter}
                                                setaf3_leadholder_filter={setaf3_leadholder_filter}
                                                customActiveTab={customActiveTab}
                                                getSearchedLeads={getSearchedLeads}
                                             />
                                          </div>
                                       </th>
                                    )}
                                    <th>
                                       <div
                                          className="position-relative"
                                          style={{ whiteSpace: "nowrap" }}
                                       >
                                          Status
                                          {af3_followups_filter ? (
                                             <>
                                                <span
                                                   className=""
                                                   style={{ cursor: "pointer", color: "orange" }}
                                                   onClick={() => setaf3_followups_filter(false)}
                                                >
                                                   <i className="fa fa-times fa-lg ms-2"></i>
                                                </span>
                                             </>
                                          ) : (
                                             <>
                                                {localStorage.getItem(`followupStatus-${customActiveTab}`) == null || localStorage.getItem(`followupStatus-${customActiveTab}`) == "" ? (
                                                   <span
                                                      style={{ cursor: "pointer" }}
                                                      onClick={() => setaf3_followups_filter(true)}
                                                   >
                                                      <i className="fa fa-filter ms-2"></i>
                                                   </span>
                                                ) : (
                                                   <span
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf3_followups_filter(true)}
                                                   >
                                                      <i className="fa fa-filter ms-2"></i>
                                                   </span>
                                                )}
                                             </>
                                          )}
                                          <MultipleChoice
                                             af3_followups_filter={af3_followups_filter}
                                             setaf3_followups_filter={setaf3_followups_filter}
                                             customActiveTab={customActiveTab}
                                             getSearchedLeads={getSearchedLeads}
                                          />
                                       </div>
                                    </th>
                                    <th>
                                       <div
                                          className="position-relative"
                                          style={{ whiteSpace: "nowrap" }}
                                       >
                                          Followup
                                          {af3_date_filter ? (
                                             <>
                                                <span
                                                   style={{ cursor: "pointer", color: "orange" }}
                                                   onClick={() => setaf3_date_filter(false)}
                                                >
                                                   <i className="fa fa-times fa-lg ms-2"></i>
                                                </span>
                                             </>
                                          ) : (
                                             <>
                                                {getFilterDate() && getFilterDate().from == "" ? (
                                                   <span
                                                      className=""
                                                      style={{ cursor: "pointer" }}
                                                      onClick={() => setaf3_date_filter(true)}
                                                   >
                                                      <i className="fa fa-filter ms-2"></i>
                                                   </span>
                                                ) : (
                                                   <span
                                                      className=""
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf3_date_filter(true)}
                                                   >
                                                      <i className="fa fa-filter ms-2"></i>
                                                   </span>
                                                )}
                                             </>
                                          )}
                                          <DateFilter
                                             af3_date_filter={af3_date_filter}
                                             setaf3_date_filter={setaf3_date_filter}
                                             customActiveTab={customActiveTab}
                                             getSearchedLeads={getSearchedLeads}
                                          />
                                       </div>
                                    </th>
                                    <th>
                                       <div
                                          className="position-relative"
                                          style={{ whiteSpace: "nowrap" }}
                                       >
                                          Last Message
                                          {af3_message_filter ? (
                                             <>
                                                <span
                                                   style={{ cursor: "pointer", color: "orange" }}
                                                   onClick={() => setaf3_message_filter(false)}
                                                >
                                                   <i className="fa fa-times fa-lg ms-2"></i>
                                                </span>
                                             </>
                                          ) : (
                                             <>
                                                {localStorage.getItem(`leadMessage-${customActiveTab}`) == null || localStorage.getItem(`leadMessage-${customActiveTab}`) == "" ? (
                                                   <span
                                                      className=""
                                                      style={{ cursor: "pointer" }}
                                                      onClick={() => setaf3_message_filter(true)}
                                                   >
                                                      <i className="fa fa-filter ms-2"></i>
                                                   </span>
                                                ) : (
                                                   <span
                                                      className=""
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf3_message_filter(true)}
                                                   >
                                                      <i className="fa fa-filter ms-2"></i>
                                                   </span>
                                                )}
                                             </>
                                          )}
                                          <InputFilter
                                             af3_message_filter={af3_message_filter}
                                             setaf3_message_filter={setaf3_message_filter}
                                             customActiveTab={customActiveTab}
                                             getSearchedLeads={getSearchedLeads}
                                          />
                                       </div>
                                    </th>
                                    <th>Action</th>
                                 </tr>
                                 :
                                 customActiveTab === 2 ?
                                    <tr className="static-header">
                                       <th>
                                          <input
                                             onClick={e => selectAllCheck(e.target)}
                                             className="p-0 d-inline-block"
                                             type="checkbox"
                                          />
                                       </th>
                                       <th>#</th>
                                       <th>
                                          <div
                                             className="position-relative"
                                             style={{ whiteSpace: "nowrap" }}
                                          >
                                             Name
                                             {af2_name_filter ? (
                                                <>
                                                   <span
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf2_name_filter(false)}
                                                   >
                                                      <i className="fa fa-times fa-lg ms-2"></i>
                                                   </span>
                                                </>
                                             ) : (
                                                <>
                                                   {localStorage.getItem(`leadName-${customActiveTab}`) == "" || localStorage.getItem(`leadName-${customActiveTab}`) == null ? (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer" }}
                                                         onClick={() => setaf2_name_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   ) : (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer", color: "orange" }}
                                                         onClick={() => setaf2_name_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   )}
                                                </>
                                             )}
                                             <InputFilter
                                                af2_name_filter={af2_name_filter}
                                                setaf2_name_filter={setaf2_name_filter}
                                                customActiveTab={customActiveTab}
                                                getSearchedLeads={getSearchedLeads}
                                             />
                                          </div>
                                       </th>
                                       <th>
                                          <div
                                             className="position-relative"
                                             style={{ whiteSpace: "nowrap" }}
                                          >
                                             Contact
                                             {af2_contact_filter ? (
                                                <>
                                                   <span
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf2_contact_filter(false)}
                                                   >
                                                      <i className="fa fa-times fa-lg ms-2"></i>
                                                   </span>
                                                </>
                                             ) : (
                                                <>
                                                   {localStorage.getItem(`leadContact-${customActiveTab}`) == "" || localStorage.getItem(`leadContact-${customActiveTab}`) == null ? (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer" }}
                                                         onClick={() => setaf2_contact_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   ) : (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer", color: "orange" }}
                                                         onClick={() => setaf2_contact_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   )}
                                                </>
                                             )}
                                             <InputFilter
                                                af2_contact_filter={af2_contact_filter}
                                                setaf2_contact_filter={setaf2_contact_filter}
                                                customActiveTab={customActiveTab}
                                                getSearchedLeads={getSearchedLeads}
                                             />
                                          </div>
                                       </th>
                                       <th>
                                          <div
                                             className="position-relative"
                                             style={{ whiteSpace: "nowrap" }}
                                          >
                                             Lead Source
                                             {af2_leadsource_filter ? (
                                                <>
                                                   <span
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf2_leadsource_filter(false)}
                                                   >
                                                      <i className="fa fa-times fa-lg ms-2"></i>
                                                   </span>
                                                </>
                                             ) : (
                                                <>
                                                   {localStorage.getItem(`leadSource-${customActiveTab}`) == "" || localStorage.getItem(`leadSource-${customActiveTab}`) == null ? (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer" }}
                                                         onClick={() => setaf2_leadsource_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   ) : (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer", color: "orange" }}
                                                         onClick={() => setaf2_leadsource_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   )}
                                                </>
                                             )}
                                             <MultipleChoice
                                                af2_leadsource_filter={af2_leadsource_filter}
                                                setaf2_leadsource_filter={setaf2_leadsource_filter}
                                                customActiveTab={customActiveTab}
                                                getSearchedLeads={getSearchedLeads}
                                             />
                                          </div>
                                       </th>
                                       {getCurrentUser() && getCurrentUser().tag == 1 ? (
                                          <th>
                                             <div
                                                className="position-relative"
                                                style={{ whiteSpace: "nowrap" }}
                                             >
                                                Tag
                                                {af2_tag_filter ? (
                                                   <>
                                                      <span
                                                         style={{ cursor: "pointer", color: "orange" }}
                                                         onClick={() => setaf2_tag_filter(false)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   </>
                                                ) : (
                                                   <>
                                                      {localStorage.getItem(`leadTag-${customActiveTab}`) == "" || localStorage.getItem(`leadTag-${customActiveTab}`) == null ? (
                                                         <span
                                                            className=""
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => setaf2_tag_filter(true)}
                                                         >
                                                            <i className="fa fa-filter ms-2"></i>
                                                         </span>
                                                      ) : (
                                                         <span
                                                            className=""
                                                            style={{ cursor: "pointer", color: "orange" }}
                                                            onClick={() => setaf2_tag_filter(true)}
                                                         >
                                                            <i className="fa fa-filter ms-2"></i>
                                                         </span>
                                                      )}
                                                   </>
                                                )}
                                                <MultipleChoice
                                                   af2_tag_filter={af2_tag_filter}
                                                   setaf2_tag_filter={setaf2_tag_filter}
                                                   customActiveTab={customActiveTab}
                                                   getSearchedLeads={getSearchedLeads}
                                                />
                                             </div>
                                          </th>
                                       ) : (
                                          ""
                                       )}
                                       <th>
                                          <div
                                             className="position-relative"
                                             style={{ whiteSpace: "nowrap" }}
                                          >
                                             Label
                                             {af2_label_filter ? (
                                                <>
                                                   <span
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf2_label_filter(false)}
                                                   >
                                                      <i className="fa fa-times fa-lg ms-2"></i>
                                                   </span>
                                                </>
                                             ) : (
                                                <>
                                                   {localStorage.getItem(`leadLabel-${customActiveTab}`) == "" || localStorage.getItem(`leadLabel-${customActiveTab}`) == null ? (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer" }}
                                                         onClick={() => setaf2_label_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   ) : (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer", color: "orange" }}
                                                         onClick={() => setaf2_label_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   )}
                                                </>
                                             )}
                                             <MultipleChoice
                                                af2_label_filter={af2_label_filter}
                                                setaf2_label_filter={setaf2_label_filter}
                                                customActiveTab={customActiveTab}
                                                getSearchedLeads={getSearchedLeads}
                                             />
                                          </div>
                                       </th>
                                       {getCurrentUser().role_name == "Executive" ? (
                                          ""
                                       ) : (
                                          <th>
                                             <div
                                                className="position-relative"
                                                style={{ whiteSpace: "nowrap" }}
                                             >
                                                Leadholder
                                                {af2_leadholder_filter ? (
                                                   <>
                                                      <span
                                                         style={{ cursor: "pointer", color: "orange" }}
                                                         onClick={() => setaf2_leadholder_filter(false)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   </>
                                                ) : (
                                                   <>
                                                      {localStorage.getItem(`leadHolder-${customActiveTab}`) == "" || localStorage.getItem(`leadHolder-${customActiveTab}`) == null ? (
                                                         <span
                                                            className=""
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => setaf2_leadholder_filter(true)}
                                                         >
                                                            <i className="fa fa-filter ms-2"></i>
                                                         </span>
                                                      ) : (
                                                         <span
                                                            className=""
                                                            style={{ cursor: "pointer", color: "orange" }}
                                                            onClick={() => setaf2_leadholder_filter(true)}
                                                         >
                                                            <i className="fa fa-filter ms-2"></i>
                                                         </span>
                                                      )}
                                                   </>
                                                )}
                                                <MultipleChoice
                                                   af2_leadholder_filter={af2_leadholder_filter}
                                                   setaf2_leadholder_filter={setaf2_leadholder_filter}
                                                   customActiveTab={customActiveTab}
                                                   getSearchedLeads={getSearchedLeads}
                                                />
                                             </div>
                                          </th>
                                       )}
                                       <th>
                                          <div
                                             className="position-relative"
                                             style={{ whiteSpace: "nowrap" }}
                                          >
                                             Status
                                             {af2_followups_filter ? (
                                                <>
                                                   <span
                                                      className=""
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf2_followups_filter(false)}
                                                   >
                                                      <i className="fa fa-times fa-lg ms-2"></i>
                                                   </span>
                                                </>
                                             ) : (
                                                <>
                                                   {localStorage.getItem(`followupStatus-${customActiveTab}`) == null || localStorage.getItem(`followupStatus-${customActiveTab}`) == "" ? (
                                                      <span
                                                         style={{ cursor: "pointer" }}
                                                         onClick={() => setaf2_followups_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   ) : (
                                                      <span
                                                         style={{ cursor: "pointer", color: "orange" }}
                                                         onClick={() => setaf2_followups_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   )}
                                                </>
                                             )}
                                             <MultipleChoice
                                                af2_followups_filter={af2_followups_filter}
                                                setaf2_followups_filter={setaf2_followups_filter}
                                                customActiveTab={customActiveTab}
                                                getSearchedLeads={getSearchedLeads}
                                             />
                                          </div>
                                       </th>
                                       <th>
                                          <div
                                             className="position-relative"
                                             style={{ whiteSpace: "nowrap" }}
                                          >
                                             Followup
                                             {af2_date_filter ? (
                                                <>
                                                   <span
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf2_date_filter(false)}
                                                   >
                                                      <i className="fa fa-times fa-lg ms-2"></i>
                                                   </span>
                                                </>
                                             ) : (
                                                <>
                                                   {getFilterDate() && getFilterDate().from == "" ? (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer" }}
                                                         onClick={() => setaf2_date_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   ) : (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer", color: "orange" }}
                                                         onClick={() => setaf2_date_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   )}
                                                </>
                                             )}
                                             <DateFilter
                                                af2_date_filter={af2_date_filter}
                                                setaf2_date_filter={setaf2_date_filter}
                                                customActiveTab={customActiveTab}
                                                getSearchedLeads={getSearchedLeads}
                                             />
                                          </div>
                                       </th>
                                       <th>
                                          <div
                                             className="position-relative"
                                             style={{ whiteSpace: "nowrap" }}
                                          >
                                             Last Message
                                             {af2_message_filter ? (
                                                <>
                                                   <span
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf2_message_filter(false)}
                                                   >
                                                      <i className="fa fa-times fa-lg ms-2"></i>
                                                   </span>
                                                </>
                                             ) : (
                                                <>
                                                   {localStorage.getItem(`leadMessage-${customActiveTab}`) == null || localStorage.getItem(`leadMessage-${customActiveTab}`) == "" ? (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer" }}
                                                         onClick={() => setaf2_message_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   ) : (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer", color: "orange" }}
                                                         onClick={() => setaf2_message_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   )}
                                                </>
                                             )}
                                             <InputFilter
                                                af2_message_filter={af2_message_filter}
                                                setaf2_message_filter={setaf2_message_filter}
                                                customActiveTab={customActiveTab}
                                                getSearchedLeads={getSearchedLeads}
                                             />
                                          </div>
                                       </th>
                                       <th>Action</th>
                                    </tr>
                                    :
                                    <tr className="static-header">
                                       <th>
                                          <input
                                             onClick={e => selectAllCheck(e.target)}
                                             className="p-0 d-inline-block"
                                             type="checkbox"
                                          />
                                       </th>
                                       <th>#</th>
                                       <th>
                                          <div
                                             className="position-relative"
                                             style={{ whiteSpace: "nowrap" }}
                                          >
                                             Name
                                             {af_name_filter ? (
                                                <>
                                                   <span
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf_name_filter(false)}
                                                   >
                                                      <i className="fa fa-times fa-lg ms-2"></i>
                                                   </span>
                                                </>
                                             ) : (
                                                <>
                                                   {localStorage.getItem(`leadName-${customActiveTab}`) == "" || localStorage.getItem(`leadName-${customActiveTab}`) == null ? (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer" }}
                                                         onClick={() => setaf_name_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   ) : (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer", color: "orange" }}
                                                         onClick={() => setaf_name_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   )}
                                                </>
                                             )}
                                             <InputFilter
                                                af_name_filter={af_name_filter}
                                                setaf_name_filter={setaf_name_filter}
                                                customActiveTab={customActiveTab}
                                                getSearchedLeads={getSearchedLeads}
                                             />
                                          </div>
                                       </th>
                                       <th>
                                          <div
                                             className="position-relative"
                                             style={{ whiteSpace: "nowrap" }}
                                          >
                                             Contact
                                             {af_contact_filter ? (
                                                <>
                                                   <span
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf_contact_filter(false)}
                                                   >
                                                      <i className="fa fa-times fa-lg ms-2"></i>
                                                   </span>
                                                </>
                                             ) : (
                                                <>
                                                   {localStorage.getItem(`leadContact-${customActiveTab}`) == "" || localStorage.getItem(`leadContact-${customActiveTab}`) == null ? (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer" }}
                                                         onClick={() => setaf_contact_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   ) : (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer", color: "orange" }}
                                                         onClick={() => setaf_contact_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   )}
                                                </>
                                             )}
                                             <InputFilter
                                                af_contact_filter={af_contact_filter}
                                                setaf_contact_filter={setaf_contact_filter}
                                                customActiveTab={customActiveTab}
                                                getSearchedLeads={getSearchedLeads}
                                             />
                                          </div>
                                       </th>
                                       <th>
                                          <div
                                             className="position-relative"
                                             style={{ whiteSpace: "nowrap" }}
                                          >
                                             Lead Source
                                             {af_leadsource_filter ? (
                                                <>
                                                   <span
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf_leadsource_filter(false)}
                                                   >
                                                      <i className="fa fa-times fa-lg ms-2"></i>
                                                   </span>
                                                </>
                                             ) : (
                                                <>
                                                   {localStorage.getItem(`leadSource-${customActiveTab}`) == "" || localStorage.getItem(`leadSource-${customActiveTab}`) == null ? (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer" }}
                                                         onClick={() => setaf_leadsource_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   ) : (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer", color: "orange" }}
                                                         onClick={() => setaf_leadsource_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   )}
                                                </>
                                             )}
                                             <MultipleChoice
                                                af_leadsource_filter={af_leadsource_filter}
                                                setaf_leadsource_filter={setaf_leadsource_filter}
                                                customActiveTab={customActiveTab}
                                                getSearchedLeads={getSearchedLeads}
                                             />
                                          </div>
                                       </th>
                                       {getCurrentUser() && getCurrentUser().tag == 1 ? (
                                          <th>
                                             <div
                                                className="position-relative"
                                                style={{ whiteSpace: "nowrap" }}
                                             >
                                                Tag
                                                {af_tag_filter ? (
                                                   <>
                                                      <span
                                                         style={{ cursor: "pointer", color: "orange" }}
                                                         onClick={() => setaf_tag_filter(false)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   </>
                                                ) : (
                                                   <>
                                                      {localStorage.getItem(`leadTag-${customActiveTab}`) == "" || localStorage.getItem(`leadTag-${customActiveTab}`) == null ? (
                                                         <span
                                                            className=""
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => setaf_tag_filter(true)}
                                                         >
                                                            <i className="fa fa-filter ms-2"></i>
                                                         </span>
                                                      ) : (
                                                         <span
                                                            className=""
                                                            style={{ cursor: "pointer", color: "orange" }}
                                                            onClick={() => setaf_tag_filter(true)}
                                                         >
                                                            <i className="fa fa-filter ms-2"></i>
                                                         </span>
                                                      )}
                                                   </>
                                                )}
                                                <MultipleChoice
                                                   af_tag_filter={af_tag_filter}
                                                   setaf_tag_filter={setaf_tag_filter}
                                                   customActiveTab={customActiveTab}
                                                   getSearchedLeads={getSearchedLeads}
                                                />
                                             </div>
                                          </th>
                                       ) : (
                                          ""
                                       )}
                                       <th>
                                          <div
                                             className="position-relative"
                                             style={{ whiteSpace: "nowrap" }}
                                          >
                                             Label
                                             {af_label_filter ? (
                                                <>
                                                   <span
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf_label_filter(false)}
                                                   >
                                                      <i className="fa fa-times fa-lg ms-2"></i>
                                                   </span>
                                                </>
                                             ) : (
                                                <>
                                                   {localStorage.getItem(`leadLabel-${customActiveTab}`) == "" || localStorage.getItem(`leadLabel-${customActiveTab}`) == null ? (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer" }}
                                                         onClick={() => setaf_label_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   ) : (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer", color: "orange" }}
                                                         onClick={() => setaf_label_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   )}
                                                </>
                                             )}
                                             <MultipleChoice
                                                af_label_filter={af_label_filter}
                                                setaf_label_filter={setaf_label_filter}
                                                customActiveTab={customActiveTab}
                                                getSearchedLeads={getSearchedLeads}
                                             />
                                          </div>
                                       </th>
                                       {getCurrentUser().role_name == "Executive" ? (
                                          ""
                                       ) : (
                                          <th>
                                             <div
                                                className="position-relative"
                                                style={{ whiteSpace: "nowrap" }}
                                             >
                                                Leadholder
                                                {af_leadholder_filter ? (
                                                   <>
                                                      <span
                                                         style={{ cursor: "pointer", color: "orange" }}
                                                         onClick={() => setaf_leadholder_filter(false)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   </>
                                                ) : (
                                                   <>
                                                      {localStorage.getItem(`leadHolder-${customActiveTab}`) == "" || localStorage.getItem(`leadHolder-${customActiveTab}`) == null ? (
                                                         <span
                                                            className=""
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => setaf_leadholder_filter(true)}
                                                         >
                                                            <i className="fa fa-filter ms-2"></i>
                                                         </span>
                                                      ) : (
                                                         <span
                                                            className=""
                                                            style={{ cursor: "pointer", color: "orange" }}
                                                            onClick={() => setaf_leadholder_filter(true)}
                                                         >
                                                            <i className="fa fa-filter ms-2"></i>
                                                         </span>
                                                      )}
                                                   </>
                                                )}
                                                <MultipleChoice
                                                   af_leadholder_filter={af_leadholder_filter}
                                                   setaf_leadholder_filter={setaf_leadholder_filter}
                                                   customActiveTab={customActiveTab}
                                                   getSearchedLeads={getSearchedLeads}
                                                />
                                             </div>
                                          </th>
                                       )}
                                       <th>
                                          <div
                                             className="position-relative"
                                             style={{ whiteSpace: "nowrap" }}
                                          >
                                             Status
                                             {af_followups_filter ? (
                                                <>
                                                   <span
                                                      className=""
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf_followups_filter(false)}
                                                   >
                                                      <i className="fa fa-times fa-lg ms-2"></i>
                                                   </span>
                                                </>
                                             ) : (
                                                <>
                                                   {localStorage.getItem(`followupStatus-${customActiveTab}`) == null || localStorage.getItem(`followupStatus-${customActiveTab}`) == "" ? (
                                                      <span
                                                         style={{ cursor: "pointer" }}
                                                         onClick={() => setaf_followups_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   ) : (
                                                      <span
                                                         style={{ cursor: "pointer", color: "orange" }}
                                                         onClick={() => setaf_followups_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   )}
                                                </>
                                             )}
                                             <MultipleChoice
                                                af_followups_filter={af_followups_filter}
                                                setaf_followups_filter={setaf_followups_filter}
                                                customActiveTab={customActiveTab}
                                                getSearchedLeads={getSearchedLeads}
                                             />
                                          </div>
                                       </th>
                                       <th>
                                          <div
                                             className="position-relative"
                                             style={{ whiteSpace: "nowrap" }}
                                          >
                                             Followup
                                             {af_date_filter ? (
                                                <>
                                                   <span
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf_date_filter(false)}
                                                   >
                                                      <i className="fa fa-times fa-lg ms-2"></i>
                                                   </span>
                                                </>
                                             ) : (
                                                <>
                                                   {getFilterDate() && getFilterDate().from == "" ? (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer" }}
                                                         onClick={() => setaf_date_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   ) : (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer", color: "orange" }}
                                                         onClick={() => setaf_date_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   )}
                                                </>
                                             )}
                                             <DateFilter
                                                af_date_filter={af_date_filter}
                                                setaf_date_filter={setaf_date_filter}
                                                customActiveTab={customActiveTab}
                                                getSearchedLeads={getSearchedLeads}
                                             />
                                          </div>
                                       </th>
                                       <th>
                                          <div
                                             className="position-relative"
                                             style={{ whiteSpace: "nowrap" }}
                                          >
                                             Last Message
                                             {af_message_filter ? (
                                                <>
                                                   <span
                                                      style={{ cursor: "pointer", color: "orange" }}
                                                      onClick={() => setaf_message_filter(false)}
                                                   >
                                                      <i className="fa fa-times fa-lg ms-2"></i>
                                                   </span>
                                                </>
                                             ) : (
                                                <>
                                                   {localStorage.getItem(`leadMessage-${customActiveTab}`) == null || localStorage.getItem(`leadMessage-${customActiveTab}`) == "" ? (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer" }}
                                                         onClick={() => setaf_message_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   ) : (
                                                      <span
                                                         className=""
                                                         style={{ cursor: "pointer", color: "orange" }}
                                                         onClick={() => setaf_message_filter(true)}
                                                      >
                                                         <i className="fa fa-filter ms-2"></i>
                                                      </span>
                                                   )}
                                                </>
                                             )}
                                             <InputFilter
                                                af_message_filter={af_message_filter}
                                                setaf_message_filter={setaf_message_filter}
                                                customActiveTab={customActiveTab}
                                                getSearchedLeads={getSearchedLeads}
                                             />
                                          </div>
                                       </th>
                                       <th>Action</th>
                                    </tr>
                        }
                     </thead>
                     <tbody>
                        {followup &&
                           followup.map((item, obj) => (
                              <TodayList
                                 key={obj}
                                 data={item}
                                 i={obj}
                                 setBlur={setBlur}
                                 setLeadsSelectedIds={setLeadsSelectedIds}
                                 leadsSelectedIds={leadsSelectedIds}
                                 getTodayFollowup={getTodayFollowup}
                                 counter={counter}
                              />
                           ))}
                     </tbody>
                  </table>
                  {error ? (
                     <span className="text-danger mt-3 d-table m-auto" role="alert">
                        {error}
                     </span>
                  ) : (
                     ""
                  )}
               </div>
            </div>
         </div>
         {loader}
         {allPages !== [] ? (
            <Paginations
               data={allPages}
               paginate={paginate}
               getTodayFollowup={getTodayFollowup}
            // local={true}
            />
         ) : (
            ""
         )}
      </>
   )
}
export default TodayFollowup
