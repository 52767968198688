import React, { useState, useEffect } from "react"
import {
   Row,
   Col,
   Card,
   CardBody,
   Modal,
   Nav,
   NavItem,
   NavLink,
   TabContent,
   TabPane,
} from "reactstrap"
import classnames from "classnames"
import SweetAlert from "react-bootstrap-sweetalert"
import Loader from "../Loader/loader.js"
import { getCurrentUser, getPermission } from "../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import CorderModal from "../Customer-Profile/C-order/CorderModal"
import ServiceLineOrder from "../Customer-Profile/C-order/serviceLineOrder"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
   AvForm,
   AvField,
   AvRadioGroup,
   AvRadio,
} from "availity-reactstrap-validation"
import LongText from "../MessageField"

let selectedRoleId = []

const OrderList = props => {
   const [order, setOrder] = useState(props.data)
   const [modal_standard, setmodal_standard] = useState(false)
   const [service_order, setservice_order] = useState(false)
   const [confirm_alert, setconfirm_alert] = useState(false)
   const [openModal, setOpenModal] = useState(false)
   const [openPaymentModal, setOpenPaymentModal] = useState(false)
   const [openDispatchModal, setOpenDispatchModal] = useState(false)
   const [success_dlg, setsuccess_dlg] = useState(false)
   const [dynamic_title, setdynamic_title] = useState("")
   const [dynamic_description, setdynamic_description] = useState("")
   const [customActiveTab, setCustomActiveTab] = useState(1)
   const [profileData, setProfileData] = useState([])
   const [historyData, setHistoryData] = useState([])
   const [paymentData, setPaymentData] = useState([])
   const [shippingData, setShippingData] = useState([])
   const [paymentCounter, setPaymentCounter] = useState([])
   const [updateTab, setUpdateTab] = useState(false)
   const [historyTab, setHistoryTab] = useState(false)
   const [paymentTab, setPaymentTab] = useState(false)
   const [loadingPayment, setLoadingPayment] = useState(false)
   const [loadingShipping, setLoadingShipping] = useState(false)
   const [historyLoader, setHistoryLoader] = useState(<Loader />)
   const [paymentLoader, setPaymentLoader] = useState(<Loader />)
   const [shippingLoader, setShippingLoader] = useState(<Loader />)
   const [shippingError, setShippingError] = useState("")
   const [historyError, setHistoryError] = useState("")
   const [paymentError, setPaymentError] = useState("")
   const [confirm_alert2, setconfirm_alert2] = useState(false)
   const [confirm_alert3, setconfirm_alert3] = useState(false)
   const [invoice, setInvoice] = useState("")
   const [grbill, setGRbill] = useState("")
   const [ewaybill, setEwaybill] = useState("")

   const getSingleOrder = e => {
      setProfileData([])
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/order/${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setProfileData(res.data.data)
               setOrder(res.data.data)
            } else {
            }
            setOpenModal(true)
            setUpdateTab(true)
         })
   }

   const getOrderPayment = e => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/order-payments?order_id=${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setPaymentCounter(res.data.data)
            } else {
            }
         })
      setPaymentLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/ledger?order_id=${e}&per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setPaymentData(res.data.data.data)
               setPaymentError("")
            } else {
               setPaymentError(res.data.message)
            }
            setPaymentLoader("")
            setOpenModal(true)
         })
   }

   const getOrderHistory = e => {
      setHistoryLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/order-history?id=${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setHistoryData(res.data.data)
               setHistoryError("")
            } else {
               setHistoryError(res.data.message)
            }
            setHistoryLoader("")
            setOpenModal(true)
         })
   }

   const getShippingDetails = e => {
      setShippingLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/order-shipping-addresses?order_id=${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setShippingData(res.data.data)
               setShippingError("")
            } else {
               setShippingError(res.data.message)
            }
            setShippingLoader("")
            setOpenModal(true)
         })
   }

   const toggleCustom = tab => {
      if (customActiveTab !== tab) {
         setCustomActiveTab(tab)
      }
      if (tab == 1) {
         setUpdateTab(true)
         getSingleOrder(order.id)
      }
      if (tab == 2) {
         setPaymentTab(true)
         getOrderPayment(order.id)
         getShippingDetails(order.id)
      } else if (tab == 3) {
         setHistoryTab(true)
         getOrderHistory(order.id)
      }
   }

   const deleteOrder = e => {
      axios
         .delete(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/order/${e} `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setOrder("")
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const sendMail = e => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/order-send-email/${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const downloadInvoice = (e, f) => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/proforma/${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
               responseType: "blob",
            }
         )
         .then(function (res) {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", `${f + "0000" + e}.pdf`)
            document.body.appendChild(link)
            link.click()
         })
   }

   const downloadInvoice2 = (e, f) => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/servicesproforma/${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
               responseType: "blob",
            }
         )
         .then(function (res) {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", `${f + "0000" + e}.pdf`)
            document.body.appendChild(link)
            link.click()
         })
   }

   const editOrder = e => {
      if (e == "order") {
         setmodal_standard(true)
         setservice_order(false)
      } else {
         setservice_order(true)
         setmodal_standard(false)
      }
   }

   const updateOrderStatus = (e, type) => {
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/update-order-status`,
            {
               order_id: e,
               status: type,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               // setLeadsSelectedIds([])
               // getOrder(orderType)
               setdynamic_description(res.data.message)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
            getSingleOrder(e)
            props.leadDisplay()
         })
   }

   const addPayment = (e, value) => {
      setLoadingPayment(true)
      let Email = 0
      if (value.send_email) {
         Email = 1
      } else {
         Email = 0
      }
      let Whatsapp = 0
      if (value.whatsapp) {
         Whatsapp = 1
      } else {
         Whatsapp = 0
      }
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/add-payment`,
            {
               customer_id: profileData.lead_id,
               order_id: profileData.id,
               amount: value.amount,
               transaction_date: value.transaction_date,
               message: value.message,
               type: value.type,
               send_email: Email,
               whatsapp: Whatsapp,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               getOrderPayment(order.id)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
               setOpenPaymentModal(false)
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
            setLoadingPayment(false)
         })
   }

   const getInvoice = e => {
      setInvoice(e.target.files[0])
   }

   const getGRbill = e => {
      setGRbill(e.target.files[0])
   }

   const getEwaybill = e => {
      setEwaybill(e.target.files[0])
   }

   const dispatchOrder = (e, value) => {
      setLoadingShipping(true)
      let Email = 0
      if (value.send_email) {
         Email = 1
      } else {
         Email = 0
      }
      let Whatsapp = 0
      if (value.whatsapp) {
         Whatsapp = 1
      } else {
         Whatsapp = 0
      }
      const formData = new FormData()
      if (shippingData.id !== undefined) {
         formData.append("order_id", profileData.id)
         formData.append("shipping_method", value.shipping_method)
         formData.append("ref_no", value.ref_no)
         formData.append("company_name", value.company_name)
         formData.append("message", value.message)
         formData.append("send_email", Email)
         formData.append("whatsapp", Whatsapp)
         formData.append("invoice", invoice)
         formData.append("gr", grbill)
         formData.append("eway", ewaybill)
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/order-shipping-addresses/${shippingData.id}?_method=PUT`,
               formData,
               {
                  headers: {
                     "content-type": "multipart/form-data",
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                        }`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  getShippingDetails(order.id)
                  getSingleOrder(order.id)
                  setLoadingShipping(false)
                  props.leadDisplay()
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
                  setOpenDispatchModal(false)
               } else {
                  setLoadingShipping(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      } else {
         formData.append("order_id", profileData.id)
         formData.append("shipping_method", value.shipping_method)
         formData.append("ref_no", value.ref_no)
         formData.append("company_name", value.company_name)
         formData.append("message", value.message)
         formData.append("invoice", invoice)
         formData.append("gr", grbill)
         formData.append("eway", ewaybill)
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/order-shipping-addresses`,
               formData,
               {
                  headers: {
                     "content-type": "multipart/form-data",
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                        }`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  getShippingDetails(order.id)
                  getSingleOrder(order.id)
                  setLoadingShipping(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
                  setOpenDispatchModal(false)
               } else {
                  setLoadingShipping(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
   }

   const getWhatsApp = e => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/order-send-whatsapp/${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   useEffect(() => {
      selectedRoleId = []
   }, [])

   const getSelectedId = (e, check) => {
      if (check.target.checked) {
         selectedRoleId.push(e)
         const uniqueID = selectedRoleId.filter((val, id, array) => {
            return array.indexOf(val) == id
         })
         selectedRoleId = uniqueID
         props.setLeadsSelectedIds(selectedRoleId)
      } else {
         selectedRoleId = props.leadsSelectedIds
         var array = selectedRoleId
         var index = array.indexOf(e)
         if (index !== -1) {
            array.splice(index, 1)
         }
         props.setLeadsSelectedIds(selectedRoleId)
      }
      if (selectedRoleId.length == 0) {
         props.setBlur(false)
      } else {
         props.setBlur(true)
      }
   }

   if (order === "") {
      return ""
   } else
      return (
         <>
            {modal_standard ? (
               <CorderModal
                  modal_standard={modal_standard}
                  setmodal_standard={setmodal_standard}
                  order={props.data}
                  setOrder={setOrder}
               />
            ) : null}
            {service_order ? (
               <ServiceLineOrder
                  service_order={service_order}
                  setservice_order={setservice_order}
                  order={props.data}
                  setOrder={setOrder}
               />
            ) : null}
            <tr key={props.i}>
               <td>
                  <input
                     onChange={e => getSelectedId(order.id, e)}
                     name="chk"
                     value={order.id}
                     className="p-0 d-inline-block"
                     type="checkbox"
                  />
               </td>
               <td>{props.counter + props.i}</td>
               <td>
                  <span
                     className="text-info fs-5"
                     style={{ cursor: "pointer" }}
                     onClick={e => {
                        getSingleOrder(order.id), setCustomActiveTab(1)
                     }}
                  >
                     {order.app_short_name}0000{order.id}
                  </span>
               </td>
               <td>
                  {(order?.lead?.firm_name == null) || order?.lead?.firm_name == "" ? (
                     ""
                  ) : (
                     <span className="">({order?.lead.firm_name})</span>
                  )}
                  <br />
                  <Link to={`/customers?id=${order.lead_id}`} className="text-info">
                     {order.customer_name}
                  </Link>
               </td>
               <td>&#8377;{order.amount_after_charges}</td>
               <td>&#8377;{order.balance}</td>
               <td>
                  {order.status == "accepted" ? (
                     <span className="badge bg-success">Accepted</span>
                  ) : (
                     ""
                  )}
                  {order.status == "pending" ? (
                     <span className="badge bg-warning text-dark">Pending</span>
                  ) : (
                     ""
                  )}
                  {order.status == "cancelled" ? (
                     <span className="badge bg-danger">Cancelled</span>
                  ) : (
                     ""
                  )}
                  {order.status == "shipped" ? (
                     <span className="badge bg-primary">Shipped</span>
                  ) : (
                     ""
                  )}
                  {order.status == "delivered" ? (
                     <span className="badge bg-warning">Deliverd</span>
                  ) : (
                     ""
                  )}
               </td>
               <td>
                  {order.payment_status == "unpaid" ? (
                     <span className="badge bg-danger">Unpaid</span>
                  ) : (
                     ""
                  )}
                  {order.payment_status == "p_paid" ? (
                     <span className="badge bg-warning text-dark">Partially paid</span>
                  ) : (
                     ""
                  )}
                  {order.payment_status == "paid" ? (
                     <span className="badge bg-success">Paid</span>
                  ) : (
                     ""
                  )}
               </td>
               <td>
                  {order.type == "order" ? (
                     <button
                        className="btn btn-sm btn-info"
                        onClick={e => downloadInvoice(order.id, order.app_short_name)}
                     >
                        <i className="fas fa-download"></i>
                     </button>
                  ) : (
                     <button
                        className="btn btn-sm btn-info"
                        onClick={e => downloadInvoice2(order.id, order.app_short_name)}
                     >
                        <i className="fas fa-download"></i>
                     </button>
                  )}
                  <a
                     className="text-info ms-2"
                     onClick={() => {
                        sendMail(order.id)
                     }}
                  >
                     <i className="fas fa-envelope" />
                  </a>
                  <a
                     style={{ cursor: "pointer", color: "#08913b" }}
                     //   target="_blank"
                     //   href={`https://web.whatsapp.com/send?phone=${order.lead?.number}&text=Order number:${order.app_short_name}0000${order.id}`}
                     onClick={() => getWhatsApp(order.id)}
                  >
                     <i className="fab fa-whatsapp ms-2 fa-lg"></i>
                  </a>
               </td>
               <td>{order.taken_by}</td>
               <td>{order.created_at}</td>
               <td>
                  {/* {order.is_first_order === 1 && getCurrentUser().role_name !== "Administrator" ?
                     null
                     : */}
                  <a
                     className="text-warning"
                     onClick={() => {
                        editOrder(order.type)
                     }}
                  >
                     <i className="fas fa-pen" />
                  </a>
                  {/* } */}
                  {getPermission().order.order.delete.is_checked === "yes" ?
                     <a
                        className="text-danger ms-2"
                        onClick={() => {
                           setconfirm_alert(true)
                        }}
                     >
                        <i className="fas fa-trash-alt" />
                     </a>
                     :
                     ""
                  }
                  {confirm_alert ? (
                     <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                           setconfirm_alert(false)
                           setsuccess_dlg(true)
                           deleteOrder(order.id)
                           setdynamic_title("Deleted")
                           setdynamic_description("Your file has been deleted.")
                        }}
                        onCancel={() => setconfirm_alert(false)}
                     >
                        You won't be able to revert this!
                     </SweetAlert>
                  ) : null}
               </td>
            </tr>
            {openModal ? (
               <Modal isOpen={openModal} centered={true} size="xl">
                  <div className="modal-header">
                     <h5 className="modal-title mt-0" id="myLargeModalLabel">
                        {profileData.app_short_name}0000{profileData.id}
                     </h5>
                     <button
                        type="button"
                        className="cross__btn"
                        onClick={() => setOpenModal(false)}
                        aria-hidden="true"
                     >
                        &times;
                     </button>
                  </div>
                  <div className="modal-body">
                     <Nav
                        tabs
                        style={{
                           justifyContent: "left",
                           background: "#efefef",
                        }}
                     >
                        <NavItem>
                           <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({ active: customActiveTab === 1 })}
                              onClick={() => {
                                 toggleCustom(1)
                              }}
                           >
                              {" "}
                              Order Details
                           </NavLink>
                        </NavItem>
                        <NavItem>
                           <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({ active: customActiveTab === 2 })}
                              onClick={() => {
                                 toggleCustom(2)
                              }}
                           >
                              {" "}
                              Order Payment Details
                           </NavLink>
                        </NavItem>
                        <NavItem>
                           <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({ active: customActiveTab === 3 })}
                              onClick={() => {
                                 toggleCustom(3)
                              }}
                           >
                              {" "}
                              Order History
                           </NavLink>
                        </NavItem>
                     </Nav>
                     <TabContent activeTab={customActiveTab} className="pt-3">
                        <TabPane tabId={1}>
                           <>
                              {updateTab ? (
                                 <>
                                    <div className="page-title-box">
                                       <Row className="align-items-center">
                                          <Col md={4}>
                                             <h6 className="page-title">
                                                Order Status:{" "}
                                                {profileData.status === "accepted" ? (
                                                   <span className="badge bg-success">
                                                      Accepted
                                                   </span>
                                                ) : (
                                                   ""
                                                )}
                                                {profileData.status === "pending" ? (
                                                   <span className="badge bg-warning text-dark">
                                                      Pending
                                                   </span>
                                                ) : (
                                                   ""
                                                )}
                                                {profileData.status === "cancelled" ? (
                                                   <span className="badge bg-danger">
                                                      Cancelled
                                                   </span>
                                                ) : (
                                                   ""
                                                )}
                                                {profileData.status === "shipped" ? (
                                                   <span className="badge bg-primary">
                                                      Shipped
                                                   </span>
                                                ) : (
                                                   ""
                                                )}
                                                {profileData.status === "delivered" ? (
                                                   <span className="badge bg-warning">
                                                      Delivered
                                                   </span>
                                                ) : (
                                                   ""
                                                )}
                                             </h6>
                                          </Col>
                                          <Col md={8}>
                                             <h6 className="page-title float-end">
                                                <button
                                                   className="btn btn-primary submit__button me-2"
                                                   type="button"
                                                   onClick={() => sendMail(profileData.id)}
                                                >
                                                   <i className="fa fa-paper-plane"></i> Send
                                                   Invoice on email
                                                </button>
                                                <a
                                                   className="btn btn-primary submit__button me-2"
                                                   type="button"
                                                   //  target="_blank"
                                                   //  href={`https://web.whatsapp.com/send?phone=${profileData.lead?.number}&text=Order number:${profileData.app_short_name}0000${profileData.id}`}
                                                   onClick={() => getWhatsApp(profileData.id)}
                                                >
                                                   <i className="fab fa-whatsapp"></i> Send
                                                   Invoice on Whatsapp
                                                </a>
                                                {profileData.status == "cancelled" ||
                                                   profileData.status == "pending" ? (
                                                   <button
                                                      className="btn btn-success"
                                                      type="button"
                                                      onClick={() => setconfirm_alert3(true)}
                                                   >
                                                      Accept Order
                                                   </button>
                                                ) : (
                                                   <>
                                                      {profileData.status == "shipped" ? (
                                                         <button
                                                            className="btn btn-success"
                                                            type="button"
                                                            onClick={() =>
                                                               updateOrderStatus(
                                                                  profileData.id,
                                                                  "delivered"
                                                               )
                                                            }
                                                         >
                                                            Mark Order Delivered
                                                         </button>
                                                      ) : (
                                                         <button
                                                            className="btn btn-danger"
                                                            type="button"
                                                            onClick={() => setconfirm_alert2(true)}
                                                         >
                                                            Cancel Order
                                                         </button>
                                                      )}
                                                   </>
                                                )}
                                             </h6>
                                          </Col>
                                       </Row>
                                    </div>
                                    {confirm_alert2 ? (
                                       <SweetAlert
                                          title="Do you want to cancel this order?"
                                          warning
                                          showCancel
                                          confirmButtonText="Yes, do it!"
                                          confirmBtnBsStyle="success"
                                          cancelBtnBsStyle="danger"
                                          onConfirm={() => {
                                             setconfirm_alert2(false)
                                             setsuccess_dlg(true)
                                             updateOrderStatus(order.id, "cancelled")
                                             setdynamic_title("Deleted")
                                             setdynamic_description(
                                                "Selected orders has been cancelled."
                                             )
                                          }}
                                          onCancel={() => setconfirm_alert2(false)}
                                       >
                                          {/* <span className="text-danger">You won't be able to revert this!</span> */}
                                       </SweetAlert>
                                    ) : null}
                                    {confirm_alert3 ? (
                                       <SweetAlert
                                          title="Do you want to Accept this order?"
                                          warning
                                          showCancel
                                          confirmButtonText="Yes, do it!"
                                          confirmBtnBsStyle="success"
                                          cancelBtnBsStyle="danger"
                                          onConfirm={() => {
                                             setconfirm_alert3(false)
                                             setsuccess_dlg(true)
                                             updateOrderStatus(order.id, "accepted")
                                             setdynamic_title("Deleted")
                                             setdynamic_description(
                                                "Selected orders has been cancelled."
                                             )
                                          }}
                                          onCancel={() => setconfirm_alert3(false)}
                                       >
                                          {/* <span className="text-danger">You won't be able to revert this!</span> */}
                                       </SweetAlert>
                                    ) : null}
                                    <div className="card">
                                       <div className="card-body">
                                          <Row className="m-0">
                                             <Col md={4}>
                                                <span>
                                                   <span>
                                                      <span className="fw-bold">Name: </span>
                                                      {profileData.lead?.name}
                                                   </span>
                                                   <br />
                                                </span>
                                                <span>
                                                   <span className="fw-bold">C/O: </span>
                                                   {profileData.lead?.firm_name}
                                                </span>{" "}
                                                <br />
                                                <span>
                                                   <span className="fw-bold">GSTIN:</span>{" "}
                                                   {profileData.lead?.gst_number}
                                                </span>
                                             </Col>
                                             <Col md={5}>
                                                <span>
                                                   <span className="fw-bold">Email: </span>
                                                   <a
                                                      className="text-info"
                                                      target="_blank"
                                                      href={`mailto:${profileData.lead?.email}`}
                                                   >
                                                      {profileData.lead?.email}
                                                   </a>
                                                </span>{" "}
                                                <br />
                                                <span>
                                                   <span className="fw-bold">Phone:</span>
                                                   {profileData.lead?.country_code !== null ? (
                                                      <>{profileData.lead?.country_code}</>
                                                   ) : (
                                                      "+91"
                                                   )}{" "}
                                                   <a
                                                      className="text-info"
                                                      href={`tel://+91${profileData.lead?.number}`}
                                                      target="_blank"
                                                   >
                                                      {profileData.lead?.number}
                                                   </a>
                                                </span>
                                                <a
                                                   style={{
                                                      cursor: "pointer",
                                                      color: "#08913b",
                                                   }}
                                                   target="_blank"
                                                   href={`https://web.whatsapp.com/send?phone=${profileData.lead?.number}`}
                                                >
                                                   <i className="fab fa-whatsapp ms-2 fa-lg"></i>
                                                </a>
                                                <br />
                                                <span>
                                                   <span className="fw-bold">
                                                      Alternate Number:
                                                   </span>
                                                   {profileData.lead?.alternate_number !==
                                                      null ? (
                                                      <>
                                                         {profileData.lead?.country_code !==
                                                            null ? (
                                                            <> {profileData.lead?.country_code}</>
                                                         ) : (
                                                            "+91"
                                                         )}
                                                         {""}
                                                         <a
                                                            className="text-info"
                                                            href={`tel://+91${profileData.lead?.alternate_number}`}
                                                            target="_blank"
                                                         >
                                                            {profileData.lead?.alternate_number}
                                                         </a>
                                                      </>
                                                   ) : (
                                                      ""
                                                   )}
                                                </span>
                                             </Col>
                                             <Col md={3}>
                                                <span>
                                                   <span className="fw-bold">Address:</span>{" "}
                                                   {profileData.lead?.city !== null ? (
                                                      <>{profileData.lead?.city},</>
                                                   ) : (
                                                      ""
                                                   )}{" "}
                                                   {profileData.lead?.district !== null ? (
                                                      <>{profileData.lead?.district},</>
                                                   ) : (
                                                      ""
                                                   )}{" "}
                                                   {profileData.lead?.state !== null ? (
                                                      <>{profileData.lead?.state},</>
                                                   ) : (
                                                      ""
                                                   )}{" "}
                                                   {profileData.lead?.country}
                                                </span>{" "}
                                                <br />
                                                <span>
                                                   <span className="fw-bold">
                                                      Prefered Transport:
                                                   </span>{" "}
                                                   {profileData?.preferred_transport}
                                                </span>
                                             </Col>
                                          </Row>
                                          <div className="d-flex w=100 justify-content-around border border-secondary m-2 p-2">
                                             <span className="fw-bold">
                                                Order ID: {profileData.app_short_name}0000
                                                {profileData.id}
                                             </span>
                                             <span className="fw-bold">
                                                Order Date: {profileData.created_at}
                                             </span>
                                          </div>
                                          <div className="lead_table table-responsive">
                                             <table style={{ width: "100%" }}>
                                                {profileData.type === "order" ? (
                                                   <tbody>
                                                      <tr>
                                                         <th>Name</th>
                                                         <th>Quantity</th>
                                                         <th>Batch no.</th>
                                                         <th>Expiry Date</th>
                                                         <th>MRP</th>
                                                         <th>Price</th>
                                                         <th>Subtotal</th>
                                                         <th>GST</th>
                                                         <th>Total</th>
                                                      </tr>
                                                      {profileData.products &&
                                                         profileData.products.map(item => (
                                                            <tr key={item.id}>
                                                               <td>{item.product.name}</td>
                                                               <td>{item.quantity}</td>
                                                               <td>{item.product.batch}</td>
                                                               <td>{item.product.expiry}</td>
                                                               <td>&#8377; {item.product.mrp}</td>
                                                               <td>&#8377; {item.price_per_unit}</td>
                                                               <td>&#8377; {item.sub_total}</td>
                                                               <td>{item.tax}%</td>
                                                               <td>&#8377; {item.total}</td>
                                                            </tr>
                                                         ))}
                                                      <tr className="border-top">
                                                         <td colSpan="4" rowSpan="3s">
                                                            Note:
                                                         </td>
                                                         <td
                                                            colSpan="4"
                                                            className="border "
                                                            style={{ textAlign: "end" }}
                                                         >
                                                            Total
                                                         </td>
                                                         <td className="border">
                                                            &#8377; {profileData.amount}
                                                         </td>
                                                      </tr>
                                                      <tr>
                                                         <td colSpan="4"></td>
                                                         <td
                                                            colSpan="4"
                                                            className="border "
                                                            style={{ textAlign: "end" }}
                                                         >
                                                            Discount/Tax
                                                         </td>
                                                         <td className="border">
                                                            &#8377;{" "}
                                                            {(
                                                               profileData.amount_after_charges -
                                                               profileData.amount
                                                            ).toFixed(2)}
                                                         </td>
                                                      </tr>
                                                      <tr>
                                                         <td colSpan="4"></td>
                                                         <td
                                                            colSpan="4"
                                                            className="border "
                                                            style={{ textAlign: "end" }}
                                                         >
                                                            Grand Total
                                                         </td>
                                                         <td className="border">
                                                            &#8377;{" "}
                                                            {profileData.amount_after_charges}
                                                         </td>
                                                      </tr>
                                                   </tbody>
                                                ) : (
                                                   <tbody>
                                                      <tr>
                                                         <th>Name</th>
                                                         <th>Quantity</th>
                                                         <th>Price</th>
                                                         <th>Subtotal</th>
                                                         <th>GST</th>
                                                         <th>Total</th>
                                                      </tr>
                                                      {profileData.services &&
                                                         profileData.services.map(item => (
                                                            <tr key={item.id}>
                                                               <td>{item.service_name}</td>
                                                               <td>{item.quantity}</td>
                                                               <td>&#8377; {item.service_price}</td>
                                                               <td>&#8377; {item.subtotal}</td>
                                                               <td>{item.tax}%</td>
                                                               <td>&#8377; {item.total}</td>
                                                            </tr>
                                                         ))}
                                                      <tr className="border-top">
                                                         <td colSpan="3" rowSpan="3s">
                                                            Note:
                                                         </td>
                                                         <td
                                                            colSpan="2"
                                                            className="border "
                                                            style={{ textAlign: "end" }}
                                                         >
                                                            Total
                                                         </td>
                                                         <td className="border">
                                                            &#8377; {profileData.amount}
                                                         </td>
                                                      </tr>
                                                      <tr>
                                                         <td colSpan="3"></td>
                                                         <td
                                                            colSpan="2"
                                                            className="border "
                                                            style={{ textAlign: "end" }}
                                                         >
                                                            Discount/Tax
                                                         </td>
                                                         <td className="border">
                                                            &#8377;{" "}
                                                            {(
                                                               profileData.amount_after_charges -
                                                               profileData.amount
                                                            ).toFixed(2)}
                                                         </td>
                                                      </tr>
                                                      <tr>
                                                         <td colSpan="3"></td>
                                                         <td
                                                            colSpan="2"
                                                            className="border "
                                                            style={{ textAlign: "end" }}
                                                         >
                                                            Grand Total
                                                         </td>
                                                         <td className="border">
                                                            &#8377;{" "}
                                                            {profileData.amount_after_charges}
                                                         </td>
                                                      </tr>
                                                   </tbody>
                                                )}
                                             </table>
                                             {/* {error ? (
                                                <span className="text-danger mt-3 d-table m-auto" role="alert">
                                                   {error}
                                                </span>
                                             ) : (
                                                ""
                                             )} */}
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              ) : (
                                 ""
                              )}
                           </>
                        </TabPane>
                        <TabPane tabId={2}>
                           <>
                              {paymentTab ? (
                                 <>
                                    <div className="page-title-box">
                                       <Row className="align-items-center">
                                          <Col md={4}>
                                             <h6 className="page-title">
                                                Order Status:{" "}
                                                {profileData.status == "accepted" ? (
                                                   <span className="badge bg-success">
                                                      Accepted
                                                   </span>
                                                ) : (
                                                   ""
                                                )}
                                                {profileData.status == "pending" ? (
                                                   <span className="badge bg-warning text-dark">
                                                      Pending
                                                   </span>
                                                ) : (
                                                   ""
                                                )}
                                                {profileData.status == "cancelled" ? (
                                                   <span className="badge bg-danger">
                                                      Cancelled
                                                   </span>
                                                ) : (
                                                   ""
                                                )}
                                                {profileData.status == "shipped" ? (
                                                   <span className="badge bg-primary">
                                                      Shipped
                                                   </span>
                                                ) : (
                                                   ""
                                                )}
                                                {profileData.status == "delivered" ? (
                                                   <span className="badge bg-warning">
                                                      Delivered
                                                   </span>
                                                ) : (
                                                   ""
                                                )}
                                             </h6>
                                          </Col>
                                          <Col md={8}>
                                             <h6 className="page-title float-end">
                                                <button
                                                   className="btn btn-primary submit__button me-2"
                                                   type="button"
                                                   onClick={() => setOpenPaymentModal(true)}
                                                >
                                                   {" "}
                                                   Add Payment
                                                </button>
                                                {profileData.status !== "delivered" ? (
                                                   <button
                                                      className="btn btn-primary submit__button me-2"
                                                      type="button"
                                                      onClick={() => setOpenDispatchModal(true)}
                                                   >
                                                      {" "}
                                                      Dispatch Order
                                                   </button>
                                                ) : (
                                                   ""
                                                )}
                                             </h6>
                                          </Col>
                                       </Row>
                                    </div>
                                    <div className="card">
                                       <div className="card-body">
                                          <div className="d-flex w=100 justify-content-around border border-secondary m-2 p-2">
                                             <span
                                                style={{
                                                   padding: "4px 20px",
                                                   backgroundColor: "#ff00001f",
                                                }}
                                                className="fw-bold"
                                             >
                                                Outstanding: {paymentCounter.outstanding}
                                             </span>
                                             <span
                                                style={{
                                                   padding: "4px 20px",
                                                   backgroundColor: "rgb(0 122 9 / 13%)",
                                                }}
                                                className="fw-bold"
                                             >
                                                Paid: {paymentCounter.paid}
                                             </span>
                                          </div>
                                          <div className="lead_table table-responsive">
                                             <table style={{ width: "100%" }}>
                                                <tbody>
                                                   <tr>
                                                      <th>#</th>
                                                      <th>Amount</th>
                                                      <th>Transaction Date</th>
                                                      <th>Message</th>
                                                      <th>Type</th>
                                                   </tr>
                                                   {paymentLoader ? (
                                                      paymentLoader
                                                   ) : (
                                                      <>
                                                         {paymentData &&
                                                            paymentData.map((item, i) => (
                                                               <tr key={item.id}>
                                                                  <td>{i + 1}</td>
                                                                  <td>{item.amount}</td>
                                                                  <td>{item.transaction_date}</td>
                                                                  <td>
                                                                     <LongText
                                                                        content={item.message}
                                                                     />
                                                                  </td>
                                                                  <td>
                                                                     {item.type == "paid" ? (
                                                                        <span className="badge bg-success">
                                                                           Credit
                                                                        </span>
                                                                     ) : (
                                                                        ""
                                                                     )}
                                                                     {item.type == "outstanding" ? (
                                                                        <span className="badge bg-danger">
                                                                           Debit
                                                                        </span>
                                                                     ) : (
                                                                        ""
                                                                     )}
                                                                  </td>
                                                               </tr>
                                                            ))}
                                                      </>
                                                   )}
                                                </tbody>
                                             </table>
                                             {paymentError ? (
                                                <span
                                                   className="text-danger mt-3 d-table m-auto"
                                                   role="alert"
                                                >
                                                   {paymentError}
                                                </span>
                                             ) : (
                                                ""
                                             )}
                                          </div>
                                       </div>
                                    </div>
                                    {shippingData.id !== undefined ? (
                                       <div className="card">
                                          <div className="card-body">
                                             {shippingLoader ? (
                                                shippingLoader
                                             ) : shippingError ? (
                                                <span
                                                   className="text-danger mt-3 d-table m-auto"
                                                   role="alert"
                                                >
                                                   {shippingError}
                                                </span>
                                             ) : (
                                                <div className="row">
                                                   <span className="col-md-12 mb-4 fs-3 fw-600">
                                                      Shipping Details
                                                      {profileData.status === "delivered" ? (
                                                         ""
                                                      ) : (
                                                         <a
                                                            className="text-warning ms-2"
                                                            onClick={() => {
                                                               setOpenDispatchModal(true)
                                                            }}
                                                         >
                                                            <i className="fas fa-pen" />
                                                         </a>
                                                      )}
                                                   </span>
                                                   <div className="col-md-6">
                                                      <div className="mb-3">
                                                         <span className="fw-bold">
                                                            Shipping Method:{" "}
                                                         </span>
                                                         {shippingData?.shipping_method} <br />
                                                      </div>
                                                      <div className="mb-3">
                                                         <span className="fw-bold">
                                                            Company name:{" "}
                                                         </span>
                                                         {shippingData?.company_name} <br />
                                                      </div>
                                                      <div className="mb-3">
                                                         <span className="fw-bold">
                                                            Reference Number:{" "}
                                                         </span>
                                                         {shippingData?.ref_no} <br />
                                                      </div>
                                                      <div className="mb-3">
                                                         <span className="fw-bold">Message: </span>
                                                         {shippingData?.message} <br />
                                                      </div>
                                                      <div className="mb-3">
                                                         <span className="fw-bold">
                                                            Date & Time:{" "}
                                                         </span>
                                                         {shippingData?.created_at} <br />
                                                      </div>
                                                   </div>
                                                   <div className="col-md-6">
                                                      <div className="mb-3">
                                                         <span className="fw-bold">Invoice: </span>
                                                         <a
                                                            href={shippingData?.invoice}
                                                            target="_blank"
                                                         >
                                                            {shippingData?.invoice_name}
                                                         </a>
                                                         <br />
                                                      </div>
                                                      <div className="mb-3">
                                                         <span className="fw-bold">GR Bill: </span>
                                                         <a
                                                            href={shippingData?.gr}
                                                            target="_blank"
                                                         >
                                                            {shippingData?.gr_name}
                                                         </a>
                                                         <br />
                                                      </div>
                                                      <div className="mb-3">
                                                         <span className="fw-bold">
                                                            Eway Bill:{" "}
                                                         </span>
                                                         <a
                                                            href={shippingData?.eway}
                                                            target="_blank"
                                                         >
                                                            {shippingData?.eway_name}
                                                         </a>
                                                         <br />
                                                      </div>
                                                   </div>
                                                </div>
                                             )}
                                          </div>
                                       </div>
                                    ) : (
                                       ""
                                    )}
                                 </>
                              ) : (
                                 ""
                              )}
                           </>
                        </TabPane>
                        <TabPane tabId={3}>
                           <>
                              {historyTab ? (
                                 <>
                                    <div className="page-title-box">
                                       <Row className="align-items-center">
                                          <Col md={6}>
                                             <h6 className="page-title">Order History</h6>
                                          </Col>
                                       </Row>
                                    </div>
                                    {historyLoader ? (
                                       historyLoader
                                    ) : (
                                       <>
                                          {historyData &&
                                             historyData.map((item, i) => (
                                                <div className={`steps_list followup`}>
                                                   <Card className="leadhist m-0" key={i}>
                                                      <CardBody>
                                                         <div className="d-flex justify-content-between">
                                                            <span className="history-font">
                                                               Message:{" "}
                                                            </span>
                                                            <span
                                                               className="text-muted"
                                                               style={{
                                                                  position: "absolute",
                                                                  right: "25px",
                                                               }}
                                                            >
                                                               {item.created_at}
                                                            </span>
                                                         </div>
                                                         <hr />
                                                         <span
                                                            className="fs-6"
                                                            dangerouslySetInnerHTML={{
                                                               __html: item.message,
                                                            }}
                                                         />
                                                         <br />
                                                      </CardBody>
                                                   </Card>
                                                </div>
                                             ))}
                                       </>
                                    )}
                                    {historyError ? (
                                       <span
                                          className="text-danger mt-3 d-table m-auto"
                                          role="alert"
                                       >
                                          {historyError}
                                       </span>
                                    ) : (
                                       ""
                                    )}
                                 </>
                              ) : (
                                 ""
                              )}
                           </>
                        </TabPane>
                     </TabContent>
                  </div>
               </Modal>
            ) : null}
            {openPaymentModal ? (
               <Modal isOpen={openPaymentModal} centered={true}>
                  <AvForm
                     className="modal-title mt-0"
                     id="myModalLabel"
                     onValidSubmit={(e, v) => {
                        addPayment(e, v)
                     }}
                  >
                     <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                           Record Payment
                        </h5>
                        <button
                           type="button"
                           className="cross__btn"
                           onClick={() => setOpenPaymentModal(false)}
                           aria-hidden="true"
                        >
                           &times;
                        </button>
                     </div>
                     <div className="modal-body">
                        <div className="mb-3 required">
                           <AvField
                              name="amount"
                              type="number"
                              onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                              placeholder="Enter Amount"
                              label="Amount"
                           />
                        </div>
                        <div className="mb-3 required">
                           <AvField
                              name="transaction_date"
                              type="date"
                              label="Transaction Date"
                           />
                        </div>
                        <div className="mb-3 required">
                           <AvField
                              name="message"
                              type="textarea"
                              label="Message"
                              placeholder="Message"
                           />
                        </div>
                        <div className="mb-3">
                           <label>Type </label>
                           <AvRadioGroup name="type" inline>
                              <AvRadio label="Credit" value="paid" />
                              <AvRadio label="Debit" value="outstanding" />
                           </AvRadioGroup>
                        </div>
                        <div className="d-flex">
                           <div className="mb-3">
                              <AvField
                                 label="Send Email"
                                 type="checkbox"
                                 name="send_email"
                                 style={{ background: `${getCurrentUser()?.email_enabled === 1 ? "" : "lightgray"}` }}
                                    disabled={getCurrentUser()?.email_enabled === 1 ? false : true}
                              />
                           </div>
                           <div className="mb-3 ms-3">
                              <AvField
                                 label="Send Whatsapp"
                                 type="checkbox"
                                 name="whatsapp"
                                 style={{ background: `${getCurrentUser()?.whatsapp_enabled === 1 ? "" : "lightgray"}` }}
                                    disabled={getCurrentUser()?.whatsapp_enabled === 1 ? false : true}
                              />
                           </div>
                        </div>
                     </div>
                     <div className="modal-footer">
                        <div className="text-center">
                           {loadingPayment ? (
                              <button
                                 className="btn btn-primary disabled"
                                 type="button"
                              // onClick={() => setmodal_standard(true)}
                              >
                                 Loading
                              </button>
                           ) : (
                              <button
                                 className="btn btn-primary"
                                 type="submit"
                              // onClick={() => setmodal_standard(true)}
                              >
                                 Create New Ledger
                              </button>
                           )}
                        </div>
                     </div>
                  </AvForm>
               </Modal>
            ) : null}
            {openDispatchModal ? (
               <Modal isOpen={openDispatchModal} centered={true}>
                  <AvForm
                     className="modal-title mt-0"
                     id="myModalLabel"
                     onValidSubmit={(e, v) => {
                        dispatchOrder(e, v)
                     }}
                  >
                     <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                           Add Shipping Method
                        </h5>
                        <button
                           type="button"
                           className="cross__btn"
                           onClick={() => setOpenDispatchModal(false)}
                           aria-hidden="true"
                        >
                           &times;
                        </button>
                     </div>
                     <div className="modal-body">
                        <span className="fs-3 fw-600 mb-4">Shipping Form</span>
                        <Row>
                           <Col md={6}>
                              <div className="mb-3">
                                 <AvField
                                    name="shipping_method"
                                    type="select"
                                    label="Shipping Method"
                                    className="select-arrow"
                                    value={shippingData?.shipping_method}
                                 >
                                    <option label="Select" hidden value="" />
                                    <option label="Courier Method" value="Courier" />
                                    <option label="Transport Method" value="Transport" />
                                 </AvField>
                              </div>
                              <div className="mb-3">
                                 <AvField
                                    name="company_name"
                                    type="text"
                                    placeholder="Company Name"
                                    label="Company Name"
                                    value={shippingData?.company_name}
                                 />
                              </div>
                              <div className="mb-3">
                                 <AvField
                                    label="Reference Number:"
                                    type="text"
                                    placeholder="Receipt Number"
                                    name="ref_no"
                                    value={shippingData?.ref_no}
                                 />
                              </div>
                              <div className="mb-3">
                                 <AvField
                                    name="message"
                                    type="text"
                                    label="Message"
                                    placeholder="Message"
                                    value={shippingData?.message}
                                 />
                              </div>
                              <div className="d-flex">
                                 <div className="mb-3">
                                    <AvField
                                       label="Send Email"
                                       type="checkbox"
                                       name="send_email"
                                    />
                                 </div>
                                 <div className="mb-3 ms-3">
                                    <AvField
                                       label="Send Whatsapp"
                                       type="checkbox"
                                       name="whatsapp"
                                    />
                                 </div>
                              </div>
                           </Col>
                           <Col md={6}>
                              <div className="mb-3">
                                 <label>Invoice:</label>
                                 <AvField
                                    type="file"
                                    name="invoice"
                                    accept=".xls,.xlsx,.pdf,.txt.docx,.png,.jpg,.jpeg"
                                    onChange={e => getInvoice(e)}
                                 />
                              </div>
                              <div className="mb-3">
                                 <label>GR Bill:</label>
                                 <AvField
                                    type="file"
                                    name="gr_bill"
                                    accept=".xls,.xlsx,.pdf,.txt.docx,.png,.jpg,.jpeg"
                                    onChange={e => getGRbill(e)}
                                 />
                              </div>
                              <div className="mb-3">
                                 <label>Eway Bill:</label>
                                 <AvField
                                    type="file"
                                    name="eway_bill"
                                    accept=".xls,.xlsx.pdf,.txt.docx,.png,.jpg,.jpeg"
                                    onChange={e => getEwaybill(e)}
                                 />
                              </div>
                           </Col>
                        </Row>
                     </div>
                     <div className="modal-footer">
                        <div className="text-center">
                           {loadingShipping ? (
                              <button
                                 className="btn btn-primary disabled"
                                 type="button"
                              // onClick={() => setmodal_standard(true)}
                              >
                                 Loading
                              </button>
                           ) : (
                              <button
                                 className="btn btn-primary"
                                 type="submit"
                              // onClick={() => setmodal_standard(true)}
                              >
                                 Submit
                              </button>
                           )}
                        </div>
                     </div>
                  </AvForm>
               </Modal>
            ) : null}
         </>
      )
}
export default OrderList
