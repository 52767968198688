import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser, getPermission } from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import SourceModal from "./SourceModal"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

let selectedRoleId = []


const SourceList = props => {
   const [source, setSource] = useState(props.data)
   const [modal_lead_source, setmodal_lead_source] = useState(false)
   const [isOpen, setIsOpen] = useState(false)
   const [confirm_alert, setconfirm_alert] = useState(false)
   const [confirm_alert2, setconfirm_alert2] = useState(false)
   const [success_dlg, setsuccess_dlg] = useState(false)
   const [dynamic_title, setdynamic_title] = useState("")
   const [dynamic_description, setdynamic_description] = useState("")

   const deleteSource = e => {
      axios
         .delete(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead/source/${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setSource("")
               setdynamic_description(res.data.message)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
               props.getAllSources()
            }
         })
   }

   const deleteLeads = (e) => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/leadsource-lead-deleted/${e} `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               props.getAllSource()
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const editSource = e => {
      setIsOpen(true)
      setmodal_lead_source(true)
   }

   useEffect(() => {
      selectedRoleId = []
   }, [])


   const getSelectedId = (e, check) => {
      if (check.target.checked) {
         selectedRoleId.push(e)
         const uniqueID = selectedRoleId.filter((val, id, array) => {
            return array.indexOf(val) == id
         })
         selectedRoleId = uniqueID
         props.setLeadsSelectedIds(selectedRoleId)
      } else {
         selectedRoleId = props.leadsSelectedIds
         var array = selectedRoleId
         var index = array.indexOf(e)
         if (index !== -1) {
            array.splice(index, 1)
         }
         props.setLeadsSelectedIds(selectedRoleId)
      }
      if (selectedRoleId.length == 0) {
         props.setBlur(false)
      } else {
         props.setBlur(true)
      }
   }

   if (source === "") {
      return ""
   } else
      return (
         <>
            {isOpen ? (
               <SourceModal
                  modal_lead_source={modal_lead_source}
                  setmodal_lead_source={setmodal_lead_source}
                  source={source}
                  setSource={setSource}
                  setIsOpen={setIsOpen}
               />
            ) : (
               ""
            )}
            <tr key={props.i}>
               <td>
                  <input
                     onChange={(e) =>
                        getSelectedId(source.id, e)
                     }
                     name="chk"
                     value={source.id}
                     className="p-0 d-inline-block"
                     type="checkbox"
                  />
               </td>
               <td>
                  {props.counter + props.i}
               </td>
               <td>{source.id}</td>
               <td>{source.name}</td>
               <td>
                  <Link
                     className=""
                     to={`/leads?lead_source_id=${source.id}`}
                  >
                     {source.total}
                  </Link>
               </td>
               <td style={{ background: 'green', color: 'white' }}>
                  <Link
                     className="text-light"
                     to={`/leads?lead_source_id=${source.id}&type=matured`}
                  >
                     {source.matured}
                  </Link>
               </td>
               <td>{source.matured_amount}</td>
               <td style={{ background: 'cyan' }}>
                  <Link
                     className=""
                     to={`/leads?lead_source_id=${source.id}&type=new`}
                  >
                     {source.new}
                  </Link>
               </td>
               <td>
                  {source.followup && source.followup.map((item, i) => (<>
                     <Link key={i} to={`/leads?lead_source_id=${source.id}&followup_status_id=${item.id}`} className="badge" style={{ backgroundColor: 'orange' }}>{item.count}{" "}{item.status}</Link> </>
                  ))}
               </td>
               <td style={{ background: '#f14747', color: 'white' }}>
                  <Link to={`/leads?lead_source_id=${source.id}&type=dead`} className="badge bg-black">Total{" "}{source.dead[0] && source.dead[0].total}{" "}Dead </Link> <br />
                  {source.dead && source.dead.map((item, i) => (<>
                     <Link to={`/dead-leads?lead_source_id=${source.id}&dead_reason_id=${item.id}`} key={i} className="badge">{item.count}{" "}{item.reason}</Link> </>
                  ))}
               </td>
               <td style={{ background: 'orange', color: 'white' }}>
                  <Link to={`/leads?lead_source_id=${source.id}&type=dead_request`} className="badge bg-black">Total{" "}{source.dead_request[0] && source.dead_request[0].total}{" "}Dead Request</Link> <br />
                  {source.dead_request && source.dead_request.map((item, i) => (<>
                     <Link to={`/dead-requests?lead_source_id=${source.id}&dead_reason_id=${item.id}`} key={i} className="badge">{item.count}{" "}{item.reason}</Link> </>
                  ))}
               </td>
               <td style={{ background: 'purple', color: 'white' }}>
                  <Link
                     className="text-light"
                     to={`/leads?lead_source_id=${source.id}&type=repeated`}
                  >
                     {source.repeated}
                  </Link>
               </td>
               {/* <td>
                  {source.created_at}
               </td> */}
               <td>
                  {getPermission().lead.lead.delete.is_checked === "yes" ?
                     <>
                        <button
                           className="btn text-danger btn-link btn-sm mb-3"
                           onClick={(e) => setconfirm_alert2(true)}
                        >
                           Delete Leads
                        </button> <br />
                     </>
                     :
                     ""
                  }
                  {getPermission().lead_source.lead_source.edit.is_checked === "yes" ?
                     <a
                        className="text-warning"
                        onClick={() => {
                           editSource(source.id)
                        }}
                     >
                        <i className="fas fa-pen" />
                     </a>
                     :
                     ""
                  }
                  {getPermission().lead_source.lead_source.delete.is_checked === "yes" ?
                     <a
                        className="text-danger ms-2"
                        onClick={() => {
                           setconfirm_alert(true)
                        }}
                     >
                        <i className="fas fa-trash-alt" />
                     </a>
                     :
                     ""
                  }
                  {confirm_alert ? (
                     <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                           setconfirm_alert(false)
                           setsuccess_dlg(true)
                           deleteSource(source.id)
                           setdynamic_title("Deleted")
                           setdynamic_description("Your file has been deleted.")
                        }}
                        onCancel={() => setconfirm_alert(false)}
                     >
                        You won't be able to revert this!
                     </SweetAlert>
                  ) : null}
                  {confirm_alert2 ? (
                     <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                           setconfirm_alert2(false)
                           setsuccess_dlg(true)
                           deleteLeads(source.id)
                           setdynamic_title("Deleted")
                           setdynamic_description("Your file has been deleted.")
                        }}
                        onCancel={() => setconfirm_alert2(false)}
                     >
                        This will delete all related leads PERMANENTLY
                     </SweetAlert>
                  ) : null}
               </td>
            </tr>
         </>
      )
}
export default SourceList
