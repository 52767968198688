import React, { useState, useEffect, useCallback } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import MetaTags from "react-meta-tags"
import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser } from "../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
// import useRazorpay from "react-razorpay"

const PricingTable = props => {
  // const Razorpay = useRazorpay();

  // const handlePayment = async((`https://api.razorpay.com/v1/orders`) => {
  //    const order = await createOrder(`https://api.razorpay.com/v1/orders`);

  //    const options = {
  //      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
  //      amount: "3000",
  //      currency: "INR",
  //      name: "Acme Corp",
  //      description: "Test Transaction",
  //      image: "https://example.com/your_logo",
  //      order_id: order.id,
  //      handler: (res) => {
  //        console.log(res);
  //      },
  //      prefill: {
  //        name: "Piyush Garg",
  //        email: "youremail@example.com",
  //        contact: "9999999999",
  //      },
  //      notes: {
  //        address: "Razorpay Corporate Office",
  //      },
  //      theme: {
  //        color: "#3399cc",
  //      },
  //    };

  // }
  // const rzpay = new Razorpay(options);
  // rzpay.open();
  //  , [Razorpay]);

  const getPlans = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/razor-pay-plan`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          console.log("res", res)
        } else {
        }
      })
  }

  const getSubscriptions = () => {
    var cat =
      process.env.REACT_APP_RAZORPAY_KEY_ID +
      ":" +
      process.env.REACT_APP_RAZORPAY_KEY_SECRET
    var baseToken = "Basic " + btoa(cat)
    axios
      .get(`${process.env.REACT_APP_RAZORPAY_URL}/subcriptions`, {
        auth: {
          username: process.env.REACT_APP_RAZORPAY_KEY_ID,
          password: process.env.REACT_APP_RAZORPAY_KEY_SECRET,
        },
      })
      .then(function (res) {
        console.log("plans", res)
      })
  }

  useEffect(() => {
    getSubscriptions()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Leads | KlikCRM</title>
        </MetaTags>
        <Container>
          <Row>
            <Col md={12}>
              <h1 className="text-center py-4">PRICING TABLE</h1>
            </Col>
          </Row>
          {/* <Row> */}
          {/* 	<Col md={12}> */}
          {/* 		<h1 className="text-center py-4">PRICING TABLE</h1> */}
          {/* 	</Col> */}
          {/* </Row> */}
          <Row>
            <Col md={4}>
              <div className="card rounded pb-3">
                <div className="card-body">
                  <div className="text-center pricing_table">
                    <h3 className="fs-1 fw-bold pt-4 text">Personal</h3>
                    <p className="">Get started to live</p>
                    <span className="d-block border_hover"></span>
                    <h4 className="mb-0 pt-2">$ 35</h4>
                    <span className="d-block fw-light">PER MONTH</span>
                    <p className="text-justify pt-4 pb-2">
                      Lorem Ipsum is a simple dummy text to be displayed for
                      usage and display oject to class.
                    </p>
                    <button
                      type="button"
                      className="btn btn-primary card_submit px-5 py-3"
                      // onClick={handlePayment}
                    >
                      GET STARTED
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="card rounded pb-3">
                <div className="card-body">
                  <div className="text-center pricing_table">
                    <h3 className="fs-1 fw-bold pt-4 text">Business</h3>
                    <p className="">Get started to live</p>
                    <span className="d-block border_hover"></span>
                    <h4 className="mb-0 pt-2">$ 55</h4>
                    <span className="d-block fw-light">PER MONTH</span>
                    <p className="text-justify pt-4 pb-2">
                      Lorem Ipsum is a simple dummy text to be displayed for
                      usage and display oject to class.
                    </p>
                    <button
                      type="button"
                      className="btn btn-primary card_submit px-5 py-3"
                    >
                      GET STARTED
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="card rounded pb-3">
                <div className="card-body">
                  <div className="text-center pricing_table">
                    <h3 className="fs-1 fw-bold pt-4 text">Expert</h3>
                    <p className="">Get started to live</p>
                    <span className="d-block border_hover"></span>
                    <h4 className="mb-0 pt-2">$ 75</h4>
                    <span className="d-block fw-light">PER MONTH</span>
                    <p className="text-justify pt-4 pb-2">
                      Lorem Ipsum is a simple dummy text to be displayed for
                      usage and display oject to class.
                    </p>
                    <button
                      type="button"
                      className="btn btn-primary card_submit px-5 py-3"
                    >
                      GET STARTED
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PricingTable
