import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { withRouter, Link, useLocation } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import axios from "axios"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

let subDomain


// import images
import bg from "../../assets/images/bg.jpg";
import logoSm from "../../assets/images/klikcrm-logo.png"
import { setCurrentUser } from "../../helpers/Utils"
import { useHistory } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResetPasswordPage = props => {
   const [loading, setLoading] = useState(false)
   const [companyName, setCompanyName] = useState("")
   const history = useHistory()

   function useQuery() {
      const { search } = useLocation();
      return React.useMemo(() => new URLSearchParams(search), [search]);
   }

   let query = useQuery();
   let query_url = query.get("reset_tok");

   useEffect(() => {
      subDomain = window.location.host.split('.')[0]
      if (subDomain !== "login") {
         setCompanyName(subDomain)
      }
   }, [])

   const handleValidSubmit = (event, value) => {
      localStorage.setItem("company_name", companyName)
      if (companyName !== "") {
         setLoading(true)
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${companyName}/auth/reset-password`,
               {
                  token: query_url,
                  password: value.password,
                  confirm_password: value.confirm_password
               }
            )
            .then(res => {
               if (res.data.status) {
                  setCurrentUser(res.data)
                  setLoading(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
                  history.push('/login')
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
      else {
         toast.error("Invalid Company Name", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
         })
      }
   }


   return (
      <React.Fragment>
         <div className="account-pages">
            <MetaTags>
               <title>Reset Password | KlikCRM</title>
            </MetaTags>
            <div
               className="accountbg"
               style={{
                  background: `url(${bg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center"
               }}
            ></div>
            <div className="account-pages my-5 pt-5">
               <Container>
                  <Row className="justify-content-center">
                     <Col md={12} lg={10} xl={8}>
                        <Row className="justify-content-center m-0">
                           <Col md={6} className="p-0">
                              <Card className="overflow-hidden rounded-0 h-100 bg-secondary">
                                 <div className="promotional-wrapper h-100">
                                    <div className="text-center">
                                       <img src={logoSm} height="42" alt="logo" />
                                    </div>
                                    <h2 className="heading">Why You will Love KlikCRM!</h2><ul className="advantages"><li><div><i className="fa fa-star"></i></div><div><h2>Easy To Use</h2><p>KlikCRM is easy to use, even for CRM beginners. Just sign up and get going!</p></div></li><li><div><i className="fa fa-user-tie"></i></div><div><h2>Expert Help</h2><p>KlikCRM comes with support that will set you up, help you make the most of the platform and address any issues you may have- all for free!</p></div></li><li><div><i className="fas fa-shield-alt"></i></div><div><h2>Secure Technology</h2><p>KlikCRM is built on a solid technology stack, so your data is in good hands - safe, available at all times and almost impossible to steal!</p></div></li></ul>
                                 </div>
                              </Card>
                           </Col>
                           <Col md={6} className="p-0">
                              <Card className="overflow-hidden rounded-0 h-100">
                                 <div className="text-center mt-4">
                                    <h2 className="font-size-38 mt-4 text-center">
                                       Welcome Back !
                                    </h2>
                                    <p className="font-size-14 text-muted text-center">
                                       Sign in to continue to KlikCRM.
                                    </p>
                                 </div>
                                 <CardBody className="p-4">
                                    <div className="p-3">
                                       <AvForm
                                          className="form-horizontal mt-4"
                                          onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                                       >
                                          {subDomain == "login" ?
                                             <div className="mb-3">
                                                <label>Domain Name</label>
                                                <div className="input-group mb-3">
                                                   <input
                                                      onChange={(e) => setCompanyName(e.target.value)}
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Enter Domain Name"
                                                      aria-label="Recipient's username"
                                                      aria-describedby="basic-addon2"
                                                      required
                                                   />
                                                   {/* <span className="input-group-text" id="basic-addon2">
                                                      .klikcrm.com
                                                   </span> */}
                                                </div>
                                             </div>
                                             :
                                             ""
                                          }
                                          <div className="mb-3">
                                             <AvField
                                                name="password"
                                                label="Password"
                                                className="form-control"
                                                placeholder="New Password"
                                                type="password"
                                                required
                                             />
                                          </div>
                                          <div className="mb-3">
                                             <AvField
                                                name="confirm_password"
                                                label="Confirm Password"
                                                className="form-control"
                                                placeholder="Confirm New Password"
                                                type="password"
                                                required
                                             />
                                          </div>
                                          <Row className="mb-3">
                                             <Col className="text-end">
                                                {loading ? (
                                                   <button

                                                      className="btn btn-primary w-md waves-effect waves-light"
                                                      type="button"
                                                      disabled
                                                   >
                                                      Loading
                                                   </button>
                                                ) : (
                                                   <button
                                                      className="btn btn-primary w-md waves-effect waves-light"
                                                      type="submit"
                                                   >
                                                      Reset
                                                   </button>
                                                )}
                                             </Col>
                                          </Row>
                                          {/* <div className="mt-3 pt-3 d-flex justify-content-between">
                                             <div>
                                                <label className="">Support</label> <br />
                                                <i className="fas fa-headset fa-lg">
                                                </i>
                                                <a className="text-info ms-2" href="tel://+918054805705">+91-805 4805 705</a>
                                             </div>
                                             <div>
                                                <label className="">Sales</label> <br />
                                                <i className="fas fa-users fa-lg">
                                                </i>
                                                <a className="text-info ms-2" href="tel://+919781239781">+91-9781 23 9781</a>
                                             </div>
                                          </div> */}
                                       </AvForm>

                                       <div className="mt-5 pt-4 text-center">
                                          <p>
                                             © {new Date().getFullYear()} KlikCRM.com,{" "}
                                             {/* <i className=""></i>  */}
                                             all rights reserved.
                                          </p>
                                       </div>
                                    </div>
                                 </CardBody>
                              </Card>
                           </Col>
                        </Row>
                     </Col>
                  </Row>
               </Container>
            </div>
         </div>
      </React.Fragment>
   )
}

const mapStateToProps = state => {
   const { error } = state.Login
   return { error }
}

export default ResetPasswordPage

ResetPasswordPage.propTypes = {
   error: PropTypes.any,
   history: PropTypes.object,
   loginUser: PropTypes.func,
}