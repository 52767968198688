import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input, Alert } from "reactstrap"
import {
   AvForm,
   AvField,
   AvRadioGroup,
   AvRadio,
} from "availity-reactstrap-validation"
import { Link } from "react-router-dom"

const ServiceCart = props => {
   const [item, setItem] = useState(props.data)
   const [editable, setEditable] = useState(false)
   const [quan, setQuan] = useState(props.data.quantity)
   const [rate, setRate] = useState(props.data.product_price)
   const [GST, setGST] = useState(props.data.tax)
   const [total, setTotal] = useState(props.data.subtotal)
   const [subtotal, setSubTotal] = useState(props.data.total)

   const getGST = (i, e) => {
      setGST(e.target.value)
      let price = rate
      let Dprice = price * quan
      setTotal(Dprice)
      let subTot = (Dprice + (Dprice * e.target.value) / 100).toFixed(2)
      setSubTotal(subTot)
      let products = props.cart.products
      products[i][e.target.name] = e.target.value
      products[i]["subtotal"] = parseInt(Dprice)
      products[i]["total"] = parseInt(subTot)
      props.setCart({ products })
      setTimeout(() => {
         props.updateTotal()
      }, 500)
   }

   const getNetRate = (i, e) => {
      setQuan(e.target.value)
      let price = rate
      let qn = e.target.value
      let Dprice = price * qn
      setTotal(Dprice)
      let subTot = (Dprice + (Dprice * item.tax) / 100).toFixed(2)
      setSubTotal(subTot)
      let products = props.cart.products
      products[i][e.target.name] = e.target.value
      products[i]["rate"] = parseInt(price)
      products[i]["subtotal"] = parseInt(Dprice)
      products[i]["total"] = parseInt(subTot)
      props.setCart({ products })
      setTimeout(() => {
         props.updateTotal()
      }, 500)
   }

   const getNetRate2 = (i, e) => {
      setRate(e.target.value)
      let price = e.target.value
      let qn = quan
      let Dprice = price * qn
      setTotal(Dprice)
      let subTot = (Dprice + (Dprice * item.tax) / 100).toFixed(2)
      setSubTotal(subTot)
      let products = props.cart.products
      products[i][e.target.name] = parseInt(e.target.value)
      products[i]["subtotal"] = parseInt(Dprice)
      products[i]["total"] = parseInt(subTot)
      props.setCart({ products })
      setTimeout(() => {
         props.updateTotal()
      }, 500)
   }

   if (item === "") {
      return ""
   } else
      return (
         <>
            <tr key={props.i}>
               <td>
                  <span className="">{item.name}</span>
               </td>
               <td>
                  <AvField
                     type="textarea"
                     name="details"
                     style={{ width: "120px" }}
                     value={item.details}
                     disabled={editable ? false : true}
                     onChange={e => props.handleChange(props.i, e)}
                  />
               </td>
               <td>
                  <AvField
                     type="textarea"
                     name="hsn_code"
                     style={{ width: "120px" }}
                     value={item?.hsn_code}
                     disabled={editable ? false : true}
                     onChange={e => props.handleChange(props.i, e)}
                  />
               </td>
               <td>
                  <AvField
                     type="number"
                     onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                     min="1"
                     name="quantity"
                     style={{ width: "120px" }}
                     value={quan}
                     disabled={editable ? false : true}
                     onChange={e => getNetRate(props.i, e)}
                  />
               </td>
               <td>
                  <AvField
                     type="number"
                     onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                     min="0"
                     name="product_price"
                     style={{ width: "120px" }}
                     value={rate}
                     disabled={editable ? false : true}
                     onChange={e => getNetRate2(props.i, e)}
                  />
               </td>
               <td>
                  <AvField
                     type="text"
                     name="total"
                     style={{ width: "120px" }}
                     value={total}
                     disabled
                  />
               </td>
               <td>
                  <div className="d-flex align-items-center">
                     <span>
                        (&#8377;{(props.data.total - props.data.subtotal).toFixed(2)})
                     </span>
                     <AvField
                        className="select-arrow"
                        type="select"
                        name="tax"
                        style={{ width: "120px" }}
                        onChange={e => getGST(props.i, e)}
                        value={GST}
                        disabled={editable ? false : true}
                     >
                        <option value="0">0%</option>
                        <option value="0.1">0.1%</option>
                        <option value="0.25">0.25%</option>
                        <option value="5">5%</option>
                        <option value="12">12%</option>
                        <option value="18">18%</option>
                        <option value="28">28%</option>
                     </AvField>
                  </div>
               </td>
               <td>
                  <AvField
                     type="text"
                     name="subtotal"
                     style={{ width: "120px" }}
                     disabled
                     value={subtotal}
                  />
               </td>
            </tr>
            <tr style={{ borderBottom: "1px solid gray" }}>
               {item.value?.map((option, index) =>
                  <td colSpan={index === 0 ? "2" : ""}>
                     <AvField
                        type="text"
                        label={option.field_name}
                        name={option.field_name}
                        // style={{ width: "100px" }}
                        disabled={editable ? false : true}
                        value={option.value}
                        onChange={e => props.handleDynamicValues(props.i, index, e)}
                     />
                  </td>
               )}
               <td>
                  <label>Action</label>
                  <br />
                  {editable ? (
                     <a
                        className="btn btn-sm btn-success"
                        onClick={() => {
                           setEditable(false)
                        }}
                     >
                        Save
                     </a>
                  ) : (
                     <a
                        className="btn btn-sm btn-info"
                        onClick={() => {
                           setEditable(true)
                        }}
                     >
                        Edit
                     </a>
                  )}
                  <a
                     className="btn btn-sm btn-danger ms-2"
                     onClick={() => props.removeFormFields(props.i)}
                  >
                     Delete
                  </a>
               </td>
            </tr>
         </>
      )
}
export default ServiceCart
