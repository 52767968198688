import React, { useState, useEffect } from "react"
import {
   Row,
   Col,
   Modal,
} from "reactstrap"
import axios from "axios"
import {
   AvForm,
   AvField,
} from "availity-reactstrap-validation"
import { DateRange } from "react-date-range"
import { getCurrentUser } from "../../helpers/Utils"

let fieldValues = []
let fieldText = []
let fieldSelect = []
let fieldFlipButton = []


const LeadsFilter = props => {
   const [loading, setLoading] = useState(false)
   const [dFilter, setDFilter] = useState(false)
   const [mFilter, setMFilter] = useState(false)
   const [allFollowup, setAllFollowup] = useState([])
   const [allMature, setAllMature] = useState([])
   const [allDead, setAllDead] = useState([])
   const [allFields, setAllFields] = useState([])
   const [dateFilter, setDateFilter] = useState([
      {
         startDate: new Date(),
         endDate: new Date(),
         key: "selection",
      },
   ])
   const [matureDate, setMatureDate] = useState([
      {
         startDate: new Date(),
         endDate: new Date(),
         key: "selection",
      },
   ])

   const formatDate = date => {
      let d = new Date(date)
      let month = (d.getMonth() + 1).toString()
      let day = d.getDate().toString()
      let year = d.getFullYear()
      if (month.length < 2) {
         month = "0" + month
      }
      if (day.length < 2) {
         day = "0" + day
      }
      return [year, month, day].join("-")
   }

   const setAllFieldValues = (e, id) => {
      if (e.target && e.target.type !== undefined) {
         if (e.target.type === "text") {
            let index = fieldText.findIndex(fieldText => fieldText.id === id);
            if (index !== -1) {
               fieldText[index].value = e.target.value
            } else {
               fieldText.push({ id: id, value: e.target.value })
            }
         } else if (e.target.type === "select-one") {
            let index = fieldSelect.findIndex(fieldSelect => fieldSelect.id === id);
            if (index !== -1) {
               fieldSelect[index].value = e.target.value
            } else {
               fieldSelect.push({ id: id, value: e.target.value })
            }
         }
      } else {
         let index = fieldFlipButton.findIndex(fieldFlipButton => fieldFlipButton.id === id);
         if (index !== -1) {
            fieldFlipButton[index].value = e.target.value
         } else {
            fieldFlipButton.push({ id: id, value: e.target.value })
         }
      }
   }

   const onFieldChange = (e) => {
      if (e !== undefined) {
         if (e.target.name === "firm_name") {
            localStorage.setItem("leadFirmName", e.target.value)
         } else if (e.target.name === "last_message") {
            localStorage.setItem("leadMessage", e.target.value)
         } else if (e.target.name === "no_updation_since") {
            localStorage.setItem("leadUpdateSince", e.target.value)
         } else if (e.target.name === "no_order_since") {
            localStorage.setItem("leadOrderSince", e.target.value)
         } else if (e.target.name === "no_payment_since") {
            localStorage.setItem("leadPaymentSince", e.target.value)
         } else if (e.target.name === "followup_status_id") {
            localStorage.setItem("followupStatus", e.target.value)
         } else if (e.target.name === "mature_status_id") {
            localStorage.setItem("matureStatus", e.target.value)
         } else if (e.target.name === "dead_reason_id") {
            localStorage.setItem("deadReason", e.target.value)
         } else if (e.target.name === "type") {
            localStorage.setItem("leadStatus", e.target.value)
         } else if (e.target.name === "flag") {
            localStorage.setItem("leadFlag", e.target.checked === true ? "1" : "0")
         } else if (e.target.name === "assigned") {
            localStorage.setItem("leadAssigned", e.target.checked === true ? "1" : "0")
         } else if (e.target.name === "company_pool") {
            localStorage.setItem("CompanyPool", e.target.checked === true ? "1" : "0")
         } else if (e.target.name === "transfer_request") {
            localStorage.setItem(
               "leadTransfer",
               e.target.checked === true ? "1" : "0"
            )
         }
      } else {
      }
   }

   const filterLeads = () => {
      if (dFilter) {
         localStorage.setItem(
            "leadCreated_at",
            JSON.stringify({
               startDate: formatDate(dateFilter[0].startDate),
               endDate: formatDate(dateFilter[0].endDate),
            })
         )
      }
      if (mFilter) {
         localStorage.setItem(
            "leadMatured_at",
            JSON.stringify({
               startDate: formatDate(matureDate[0].startDate),
               endDate: formatDate(matureDate[0].endDate),
            })
         )
      }
      fieldText.map(item => {
         fieldValues.push(item)
      })
      fieldSelect.map(item => {
         fieldValues.push(item)
      })
      fieldFlipButton.map(item => {
         fieldValues.push(item)
      })
      localStorage.setItem(
         "leadfields_filter",
         JSON.stringify({ data: fieldValues })
      )
      setTimeout(() => {
         props.getSearchedLeads()
      }, 500)
   }

   const clearFilter = () => {
      localStorage.setItem(
         "leadCreated_at",
         JSON.stringify({
            startDate: "",
            endDate: "",
         })
      )
      localStorage.setItem(
         "leadMatured_at",
         JSON.stringify({
            startDate: "",
            endDate: "",
         })
      )
      localStorage.setItem("leadFirmName", "")
      localStorage.setItem("leadUpdateSince", "")
      localStorage.setItem("leadOrderSince", "")
      localStorage.setItem("leadPaymentSince", "")
      localStorage.setItem("followupStatus", "")
      localStorage.setItem("matureStatus", "")
      localStorage.setItem("deadReason", "")
      localStorage.setItem("leadStatus", "")
      localStorage.setItem("leadFlag", "")
      localStorage.setItem("leadAssigned", "")
      localStorage.setItem("CompanyPool", "")
      localStorage.setItem("leadTransfer", "")
      localStorage.setItem("leadMessage", "")
      localStorage.setItem("leadfields_filter", JSON.stringify({ data: "" }))
      setTimeout(() => {
         props.getSearchedLeads()
      }, 500)
   }

   const getAllFollowup = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/followup-status?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setAllFollowup(res.data.data.data)
            } else {
            }
            getAllDead()
         })
         .catch(function (err) {
            getAllDead()
         })
   }

   const getAllDead = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/dead-status?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setAllDead(res.data.data.data)
            } else {
            }
            getAllMature()
         })
         .catch(function (err) {
            getAllMature()
         })
   }

   const getAllMature = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/mature-status?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setAllMature(res.data.data.data)
               getAllFields()
            } else {
               getAllFields()
            }
         })
   }

   const getAllFields = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead-field`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setAllFields(res.data.data)
            } else {
            }
         })
   }

   useEffect(() => {
      if (props.type && props.type == "All") {
         getAllFollowup()
      }
      if (props.type && props.type == "Status") {
         getAllMature()
      }
      fieldValues = []
      fieldText = []
      fieldSelect = []
      fieldFlipButton = []
   }, [])

   return (
      <div>
         <Modal isOpen={props.lead_filter} centered={true} size="lg">
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={() => {
                  filterLeads()
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Filter Leads
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => props.setlead_filter(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <Row>
                     <Col md={6}>
                        <div className="mb-3">
                           <AvField
                              label="No Updation Since"
                              type="date"
                              name="no_updation_since"
                              onChange={e => onFieldChange(e)}
                           />
                        </div>
                        {props.type && props.type == "mature" ?
                           <>
                              <div className="mb-3">
                                 <AvField
                                    label="No Order Since"
                                    type="date"
                                    name="no_order_since"
                                    onChange={e => onFieldChange(e)}
                                 />
                              </div>
                              <div className="mb-3">
                                 <AvField
                                    label="No Payment Since"
                                    type="date"
                                    name="no_payment_since"
                                    onChange={e => onFieldChange(e)}
                                 />
                              </div>
                           </>
                           : ""
                        }
                        {props.type && props.type == "Status" ?
                           <>
                              {getCurrentUser().crm_permission == 0 &&
                                 getCurrentUser().crmpro_permission == 0 ?
                                 ""
                                 :
                                 <>
                                    <div className="mb-3">
                                       <AvField
                                          className="select-arrow"
                                          label="Lead Type"
                                          type="select"
                                          name="type"
                                          onChange={e => onFieldChange(e)}
                                          value={localStorage.getItem("leadStatus") && localStorage.getItem("leadStatus")}
                                       >
                                          <option value="">Select</option>
                                          <option value="new">Untouched</option>
                                          <option value="followup">Followup</option>
                                          <option value="matured">Matured</option>
                                       </AvField>
                                    </div>
                                    <div className="mb-3">
                                       <AvField
                                          className="select-arrow"
                                          label="Mature Stages"
                                          type="select"
                                          name="mature_status_id"
                                          value={localStorage.getItem("matureStatus") && localStorage.getItem("matureStatus")}
                                          onChange={e => onFieldChange(e)}
                                       >
                                          <option value="">Select</option>
                                          {allMature && allMature.map((option, i) =>
                                             <option value={option.id} key={i}>{option.name}</option>
                                          )}
                                       </AvField>
                                    </div>
                                 </>
                              }
                           </>
                           : ""
                        }
                        <div className="mb-3">
                           <AvField
                              label="Firm Name"
                              type="text"
                              name="firm_name"
                              value={
                                 localStorage.getItem("leadFirmName") &&
                                 localStorage.getItem("leadFirmName")
                              }
                              placholder="Enter firm name"
                              onChange={e => onFieldChange(e)}
                           />
                        </div>
                        {props.type && props.type == "All" ?
                           <>
                              <div className="mb-3">
                                 <AvField
                                    className="select-arrow"
                                    label="Followup Stages"
                                    type="select"
                                    name="followup_status_id"
                                    value={localStorage.getItem("followupStatus") && localStorage.getItem("followupStatus")}
                                    onChange={e => onFieldChange(e)}
                                 >
                                    <option value="">Select</option>
                                    {allFollowup && allFollowup.map((option, i) =>
                                       <option value={option.id} key={i}>{option.name}</option>
                                    )}
                                 </AvField>
                              </div>
                              <div className="mb-3">
                                 <AvField
                                    className="select-arrow"
                                    label="Mature Stages"
                                    type="select"
                                    name="mature_status_id"
                                    value={localStorage.getItem("matureStatus") && localStorage.getItem("matureStatus")}
                                    onChange={e => onFieldChange(e)}
                                 >
                                    <option value="">Select</option>
                                    {allMature && allMature.map((option, i) =>
                                       <option value={option.id} key={i}>{option.name}</option>
                                    )}
                                 </AvField>
                              </div>
                              <div className="mb-3">
                                 <AvField
                                    className="select-arrow"
                                    label="Dead Reason"
                                    type="select"
                                    name="dead_reason_id"
                                    value={localStorage.getItem("deadReason") && localStorage.getItem("deadReason")}
                                    onChange={e => onFieldChange(e)}
                                 >
                                    <option value="">Select</option>
                                    {allDead && allDead.map((option, i) =>
                                       <option value={option.id} key={i}>{option.reason}</option>
                                    )}
                                 </AvField>
                              </div>
                           </>
                           : ""
                        }
                        <div className="mb-3">
                           <label>Created at</label>
                           <DateRange
                              editableDateInputs={true}
                              onChange={item => {
                                 setDateFilter([item.selection]), setDFilter(true)
                              }}
                              moveRangeOnFirstSelection={false}
                              ranges={dateFilter}
                           />
                        </div>
                     </Col>
                     <Col md={6}>
                        <div className="d-flex justify-content-between mt-2">
                           <div className="mb-3">
                              <AvField
                                 label="Transfer Request"
                                 type="checkbox"
                                 name="transfer_request"
                                 checked={
                                    localStorage.getItem("leadTransfer") &&
                                       localStorage.getItem("leadTransfer") == "1"
                                       ? true
                                       : false
                                 }
                                 onChange={e => onFieldChange(e)}
                              />
                           </div>
                           <div className="mb-3 me-3">
                              <AvField
                                 label="Star"
                                 type="checkbox"
                                 name="flag"
                                 checked={
                                    localStorage.getItem("leadFlag") &&
                                       localStorage.getItem("leadFlag") == "1"
                                       ? true
                                       : false
                                 }
                                 onChange={e => onFieldChange(e)}
                              />
                           </div>
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                           <div className="mb-3">
                              <AvField
                                 label="Assigned"
                                 type="checkbox"
                                 name="assigned"
                                 checked={
                                    localStorage.getItem("leadAssigned") &&
                                       localStorage.getItem("leadAssigned") == "1"
                                       ? true
                                       : false
                                 }
                                 onChange={e => onFieldChange(e)}
                              />
                           </div>
                           <div className="mb-3">
                              <AvField
                                 label="Comapny Pool"
                                 type="checkbox"
                                 name="company_pool"
                                 checked={
                                    localStorage.getItem("CompanyPool") &&
                                       localStorage.getItem("CompanyPool") == "1"
                                       ? true
                                       : false
                                 }
                                 onChange={e => onFieldChange(e)}
                              />
                           </div>
                        </div>
                        <div className="mb-3">
                           <AvField
                              label="Message"
                              type="textarea"
                              name="last_message"
                              value={
                                 localStorage.getItem("leadMessage") &&
                                 localStorage.getItem("leadMessage")
                              }
                              placholder="Enter message"
                              onChange={e => onFieldChange(e)}
                           />
                        </div>
                        <div className="mb-3">
                           <label>Matured at</label>
                           <DateRange
                              editableDateInputs={true}
                              onChange={item => {
                                 setMatureDate([item.selection]), setMFilter(true)
                              }}
                              moveRangeOnFirstSelection={false}
                              ranges={matureDate}
                           />
                        </div>
                     </Col>
                  </Row>
                  <Row>
                     {allFields &&
                        allFields.map((item, i) => (
                           <Col md={6} key={i}>
                              <div className="mb-3">
                                 {item.type === "Input" || item.type === "Text Area" ? (
                                    <AvField
                                       label={item.label}
                                       type="text"
                                       name="fieldvalues"
                                       placeholder="Enter"
                                       onChange={e => setAllFieldValues(e, item.id)}
                                       value={
                                          JSON.parse(localStorage.getItem("leadfields_filter")).data !== ""
                                             ? JSON.parse(localStorage.getItem("leadfields_filter")).data.filter((view) => view.id === item.id).map((view) => view.value).join("")
                                             : ""
                                       }
                                    />
                                 ) : (
                                    ""
                                 )}
                                 {item.type === "Dropdown" || item.type === "Radio" || item.type === "Checkbox" ? (
                                    <AvField
                                       className="select-arrow"
                                       label={item.label}
                                       type="select"
                                       name="fieldvalues"
                                       onChange={e => setAllFieldValues(e, item.id)}
                                       value={
                                          JSON.parse(localStorage.getItem("leadfields_filter")).data !== ""
                                             ? JSON.parse(localStorage.getItem("leadfields_filter")).data.filter((view) => view.id === item.id).map((view) => view.value).join("")
                                             : ""
                                       }
                                    >
                                       <option value="" hidden>
                                          Select
                                       </option>
                                       {item.fieldvalues.map((option, i) => (
                                          <option value={option.id} key={i}>
                                             {option.value}
                                          </option>
                                       ))}
                                       {item.type === "Flip Button" ? (
                                          <>
                                             <div className="mb-3">
                                                <label>
                                                   <span>{item.label}</span>
                                                   <br />
                                                   <div className="form-check form-switch">
                                                      <input
                                                         className="form-check-input"
                                                         type="checkbox"
                                                         role="switch"
                                                         id="flexSwitchCheckDefault"
                                                         name="switch"
                                                         onChange={e =>
                                                            setAllFieldValues(e, item.id)
                                                         }
                                                         value={
                                                            JSON.parse(localStorage.getItem("leadfields_filter")).data !== ""
                                                               ? JSON.parse(localStorage.getItem("leadfields_filter")).data.filter((view) => view.id === item.id).map((view) => view.value).join("")
                                                               : ""
                                                         }
                                                      />
                                                   </div>
                                                </label>
                                             </div>
                                          </>
                                       ) : (
                                          ""
                                       )}
                                    </AvField>
                                 ) : (
                                    ""
                                 )}
                              </div>
                           </Col>
                        ))}
                  </Row>
               </div>
               <div className="modal-footer">
                  <div className="text-center">
                     <button
                        className="btn btn-primary me-2"
                        type="button"
                        onClick={() => clearFilter()}
                     >
                        Clear Filters
                     </button>
                     {loading ? (
                        <button
                           className="btn btn-primary disabled"
                           type="button"
                           disabled
                        >
                           Loading
                        </button>
                     ) : (
                        <button className="btn btn-primary" type="submit">
                           Submit
                        </button>
                     )}
                  </div>
               </div>
            </AvForm>
         </Modal>
      </div >
   )
}

export default LeadsFilter
