import PropTypes from "prop-types"
import axios from "axios"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
   Container,
   Row,
   Col,
   Card,
   Alert,
   CardBody,
   Media,
   Button,
   Modal,
   Label,
   FormGroup,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/user-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"
import { getCurrentUser, setCurrentUser } from "../../helpers/Utils"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { saveAs } from 'file-saver';
import file from "../../assets/files/ApiSteps.pdf"
import SweetAlert from "react-bootstrap-sweetalert"


const UserProfile = props => {
   // const navigate = useNavigate();
   const [modal_xlarge, setmodal_xlarge] = useState(false)
   const [modal_xxlarge, setmodal_xxlarge] = useState(false)
   const [modal_standard, setmodal_standard] = useState(false)
   const [passField1, setPassField1] = useState(false)
   const [passField2, setPassField2] = useState(false)
   const [loading, setLoading] = useState(false)
   const [loading2, setLoading2] = useState(false)
   const [loading3, setLoading3] = useState(false)
   const [error, setError] = useState("")
   const [smtpDetails, setsmtpDetails] = useState("")
   const [invoiceLogo, setInvoiceLogo] = useState("")
   const [tandC1, setTandC1] = useState("")
   const [tandC2, setTandC2] = useState("")
   const [platform, setPlatform] = useState(getCurrentUser().whatsapp_platform ? getCurrentUser().whatsapp_platform : "chatway")
   const [bankDetails, setBankDetails] = useState(getCurrentUser().bank_details)
   const [proInvoice, setProInvoice] = useState(getCurrentUser().performa_invoice)
   const [image, setImage] = useState(getCurrentUser().photo)
   const [imagePreviewProfile, setImagePreviewProfile] = useState(
      getCurrentUser().photo
   )
   const [success_dlg, setsuccess_dlg] = useState(false)
   const [dynamic_title, setdynamic_title] = useState("")
   const [dynamic_description, setdynamic_description] = useState("")
   const [confirm_alert, setconfirm_alert] = useState(false)

   const downloadPDF = () => {
      saveAs(file, 'Steps_to_fetch_leads.pdf');
   }

   const toggle = () => {
      setPassField1(!passField1)
   }

   const toggle2 = () => {
      setPassField2(!passField2)
   }

   const handleValidSubmit = (e, value) => {
      setLoading(true)
      // let bodyFormData = new FormData();
      // bodyFormData.append('name', e.target.name.value);
      // bodyFormData.append('email', e.target.email.value);
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      axios
         .put(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/update-profile`,
            {
               name: e.target.name.value,
               email: e.target.email.value,
               company_name: e.target.company_name.value,
            },
            {
               headers: {
                  // 'Content-Type':'multipart/form-data',
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoading(false)
               setCurrentUser(res.data.data)
               setmodal_xlarge(false)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
               window.history.go(-1)
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const submitPassword = (e, value) => {
      setLoading(true)
      // let bodyFormData = new FormData();
      // bodyFormData.append('password', e.target.password.value);
      // bodyFormData.append('confirm_password', e.target.confirm_password.value);
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      axios
         .put(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/change-password`,
            {
               // old_password: e.target.old_password.value,
               password: e.target.password.value,
               confirm_password: e.target.confirm_password.value,
               logout_others: e.target.logout_others.checked ? 1 : 0,
            },
            {
               headers: {
                  // 'Content-Type':'multipart/form-data',
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoading(false)
               setmodal_xlarge(false)
               setCurrentUser(res.data.data)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
               window.history.go(-1)
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const smtpDetail = (e, value) => {
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      if (smtpDetails.id !== undefined) {
         axios
            .put(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/smtp-detail/${smtpDetails.id}`,
               {
                  host: value.host,
                  port: value.port,
                  username: value.username,
                  password: value.password,
                  encryption: value.encryption,
                  // cc_email: value.cc_email,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  setsmtpDetails(res.data.data)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
                  window.history.go(-1)
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      } else {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/smtp-detail`,
               {
                  host: value.host,
                  port: value.port,
                  username: value.username,
                  password: value.password,
                  encryption: value.encryption,
                  // cc_email: value.cc_email,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  setsmtpDetails(res.data.data)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
                  window.history.go(-1)
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
   }

   const imagePreview = () => {
      if (image === getCurrentUser().photo) {
         setmodal_xxlarge(false)
      } else {
         var formData = new FormData()
         formData.append("profile_pic", image)
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/profile-pic`,
               formData,
               {
                  headers: {
                     "Content-Type": "multipart/form-data",
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setImage(res.data.data.photo)
                  setLoading(false)
                  setCurrentUser(res.data.data)
                  setmodal_xxlarge(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
                  window.history.go(-1)
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
   }

   const getFile = e => {
      setInvoiceLogo(e.target.files[0])
   }

   const getSMTP = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/smtp-detail/${getCurrentUser().id}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setsmtpDetails(res.data.data)
            } else {
               setError(res.data.message)
            }
         })
   }

   const getTandC1 = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/term-condition?type=product_list`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setTandC1(res.data.data[0]?.term_condition)
            } else {
               setError(res.data.message)
            }
         })
   }

   const getTandC2 = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/term-condition?type=product_line`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setTandC2(res.data.data[0]?.term_condition)
            } else {
               setError(res.data.message)
            }
         })
   }

   const getProformaDetail = (e, value) => {
      var formData = new FormData()
      formData.append("performa_invoice", proInvoice)
      formData.append("bank_details", bankDetails)
      formData.append("customer_care_number", value.customer_care_number)
      // formData.append("bcc", value.bcc)
      formData.append("welcome_message", value.welcome_message)
      formData.append("invoice_logo", invoiceLogo)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/proforma-detail`,
            formData,
            {
               headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoading(false)
               setCurrentUser(res.data.data)
               setmodal_xxlarge(false)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
               window.history.go(-1)
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
         .catch(err => {
            // console.log("err", err)
         })
   }

   const submitTermCondition = (e, value) => {
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/term-condition`,
            {
               type: "product_list",
               term_condition: tandC1,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoading(false)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
               window.history.go(-1)
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const submitTermCondition2 = (e, value) => {
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/term-condition`,
            {
               type: "product_line",
               term_condition: tandC2,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoading(false)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
               window.history.go(-1)
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const whatsappToken = (e, value) => {
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      let whatsToken = ""
      if (platform === "chatway") {
         whatsToken = value.whatsapp_credit_balance_token
      }
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/whatsapp-token`,
            {
               whatsapp_platform: value.whatsapp_platform,
               whatsapp_username: value.whatsapp_username,
               whatsapp_token: value.whatsapp_token,
               whatsapp_credit_balance_token: whatsToken,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoading3(false)
               setCurrentUser(res.data.data)
               if (platform === "chatway") {
                  getWhatsAppCredits(value.whatsapp_username, value.whatsapp_token)
               } else {
                  toast.success("Whatsapp Integration details successfully submitted.", {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
                  window.history.go(-1)
               }
            } else {
               setLoading3(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const getWhatsAppCredits = (e, f) => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/get-whatsapp-api-response?whatsapp_username=${e}&whatsapp_token=${f}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.data.status !== "error") {
               toast.success("Whatsapp Integration details successfully submitted.", {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
               window.history.go(-1)
            } else {
               toast.error("Invalid token!", {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const generateApiKey = (e) => {
      setLoading2(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/generate-api-key`,
            {
               id: e,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setCurrentUser(res.data.data)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
               setLoading2(false)
               // window.history.go(-1)
            } else {
               setLoading2(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const feedAttach = e => {
      setImage(e.target.files[0])
      setImagePreviewProfile(URL.createObjectURL(e.target.files[0]))
   }

   useEffect(() => {
      getSMTP()
      getTandC1()
      getTandC2()
   }, [])

   useEffect(() => {
      const close = e => {
         if (e.key === "Escape") {
            setmodal_standard(false)
            setmodal_xlarge(false)
            setmodal_xxlarge(false)
         }
      }
      window.addEventListener("keydown", close)
      return () => window.removeEventListener("keydown", close)
   }, [])

   return (
      <React.Fragment>
         <div className="page-content">
            <MetaTags>
               <title>Profile | KlikCRM</title>
            </MetaTags>
            <Container fluid>
               {/* Render Breadcrumb */}
               {/* <Breadcrumb title="KlikCRM" breadcrumbItem="Profile" /> */}
               <div className="page-title-box">
                  <Row className="align-items-center">
                     <Col md={6}>
                        <h4 className="page-title">Profile</h4>
                     </Col>
                     <Col md={6}>
                        <h6 className="page-title float-end"></h6>
                     </Col>
                  </Row>
               </div>
               <Row>
                  <Col lg="8">
                     {props.error && props.error ? (
                        <Alert color="danger">{props.error}</Alert>
                     ) : null}
                     {props.success ? (
                        <Alert color="success">{props.success}</Alert>
                     ) : null}

                     <Card>
                        <CardBody>
                           <Row>
                              <Col md={4}>
                                 <div className="d-flex align-items-center profile-mobile">
                                    <div className="">
                                       <Label
                                          className="profile_update update-prof"
                                          onClick={() => {
                                             setmodal_xxlarge(true)
                                          }}
                                       >
                                          <img
                                             className="mw-100 mb-1 profile_update update-img"
                                             src={image}
                                             alt=""
                                             style={{ cursor: "pointer" }}
                                          />
                                          <span className="edit-picture">Edit Picture</span>
                                          <FormGroup className="text-center">
                                             Profile Picture
                                          </FormGroup>
                                       </Label>
                                    </div>
                                 </div>
                              </Col>
                              <Col md={8}>
                                 <AvForm
                                    className="modal-body mt-0"
                                    id="myModalLabel"
                                    onValidSubmit={(e, v) => {
                                       handleValidSubmit(e, v)
                                    }}
                                 >
                                    <Row>
                                       <Col md={12}>
                                          <div className="mb-3">
                                             <AvField
                                                name="name"
                                                label="Name"
                                                defaultValue={getCurrentUser().name}
                                                placeholder="Enter Name"
                                                type="text"
                                                required
                                             />
                                          </div>
                                          <div className="mb-3">
                                             <AvField
                                                name="email"
                                                label="Email"
                                                defaultValue={getCurrentUser().email}
                                                style={{ backgroundColor: "#edebeb" }}
                                                placeholder="Enter E-mail"
                                                type="email"
                                                disabled
                                             />
                                          </div>
                                       </Col>
                                       {/* <Col md={5}>
                                          <div className="mb-3">
                                             <AvField
                                                name="dob"
                                                label="DOB"
                                                value={getCurrentUser().dob}
                                                type="date"
                                             />
                                          </div>
                                          <div className="mb-3">
                                             <AvField
                                                name="anniversary"
                                                label="Anniversary"
                                                value={getCurrentUser().dob}
                                                type="date"
                                             />
                                          </div>
                                       </Col> */}
                                    </Row>
                                    <div className="mb-3">
                                       <AvField
                                          name="number"
                                          style={{ backgroundColor: "#edebeb" }}
                                          label="Phone Number"
                                          defaultValue={getCurrentUser().phone}
                                          type="text"
                                          disabled
                                       />
                                    </div>
                                    <div className="mb-3">
                                       <AvField
                                          name="company_name"
                                          style={{ backgroundColor: `${getCurrentUser()?.role_name === "Administrator" ? "#FFF" : "#edebeb"}` }}
                                          label="Company Name"
                                          defaultValue={getCurrentUser().company_name}
                                          type="text"
                                          disabled={getCurrentUser()?.role_name === "Administrator" ? false : true}
                                       />
                                    </div>

                                    <div className="modal-footer">
                                       <div className="text-end">
                                          {loading ? (
                                             <button
                                                className="btn btn-primary disabled"
                                                type="button"
                                                disabled
                                             >
                                                Loading
                                             </button>
                                          ) : (
                                             <button type="submit" className="btn btn-primary">
                                                Submit
                                             </button>
                                          )}
                                       </div>
                                    </div>
                                 </AvForm>
                              </Col>
                           </Row>
                        </CardBody>
                     </Card>
                  </Col>
                  <Col lg="4">
                     <Card>
                        <CardBody>
                           <AvForm
                              className="modal-title mt-0"
                              id="myModalLabel"
                              onValidSubmit={(e, v) => {
                                 submitPassword(e, v)
                              }}
                           >
                              <h4 className="card-title mb-4">Change Password</h4>
                              <hr />
                              {/* <div className="mb-3">
                                 <AvField
                                    label="Old Password"
                                    type="password"
                                    name="old_password"
                                 />
                              </div> */}
                              <div className="mb-3">
                                 <AvField
                                    style={{ paddingRight: "35px" }}
                                    label="Password"
                                    type={passField1 ? "text" : "password"}
                                    name="password"
                                    value={getCurrentUser().password}
                                 />
                                 {passField1 ? (
                                    <span
                                       className="float-end"
                                       style={{ marginTop: "-27px", marginRight: "10px" }}
                                    >
                                       <i
                                          className="fa fa-eye-slash"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => toggle()}
                                       ></i>
                                    </span>
                                 ) : (
                                    <span
                                       className="float-end"
                                       style={{ marginTop: "-27px", marginRight: "10px" }}
                                    >
                                       <i
                                          className="fa fa-eye"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => toggle()}
                                       ></i>
                                    </span>
                                 )}
                              </div>
                              <div className="mb-3">
                                 <AvField
                                    label="Password Confirmation"
                                    style={{ paddingRight: "35px" }}
                                    type={passField2 ? "text" : "password"}
                                    name="confirm_password"
                                    value={getCurrentUser().confirm_password}
                                 />
                                 {passField2 ? (
                                    <span
                                       className="float-end"
                                       style={{ marginTop: "-27px", marginRight: "10px" }}
                                    >
                                       <i
                                          className="fa fa-eye-slash"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => toggle2()}
                                       ></i>
                                    </span>
                                 ) : (
                                    <span
                                       className="float-end"
                                       style={{ marginTop: "-27px", marginRight: "10px" }}
                                    >
                                       <i
                                          className="fa fa-eye"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => toggle2()}
                                       ></i>
                                    </span>
                                 )}
                              </div>
                              <div className="mb-3">
                                 <AvField
                                    type="checkbox"
                                    name="logout_others"
                                    label="Logout All Active Sessions"
                                 />
                              </div>
                              <hr />
                              <div className="text-center">
                                 {loading ? (
                                    <button
                                       className="btn btn-primary disabled"
                                       type="button"
                                       disabled
                                    >
                                       Loading
                                    </button>
                                 ) : (
                                    <button
                                       className="btn btn-primary"
                                       type="submit"
                                    // onClick={() => props.setmodal_dead_reason(false)}
                                    >
                                       Submit
                                    </button>
                                 )}
                              </div>
                           </AvForm>
                        </CardBody>
                     </Card>
                  </Col>
                  {getCurrentUser()?.crmpro_permission === 1 || getCurrentUser()?.crmpronew_permission === 1 ?
                     <>
                        <Col lg="12">
                           {getCurrentUser().role_name === "Administrator" ? (
                              <Card>
                                 <CardBody>
                                    <AvForm
                                       className="card-title mt-0"
                                       onValidSubmit={(e, v) => {
                                          getProformaDetail(e, v)
                                       }}
                                    >
                                       <h4 className="card-title mb-4">Proforma Details</h4>
                                       <hr />
                                       <Row>
                                          <Col md={4}>
                                             {/* <div className="mb-3">
                                          <AvField
                                             label="Proforma Invoice Bcc"
                                             type="email"
                                             name="bcc"
                                             placeholder="Enter Email"
                                             value={getCurrentUser().bcc}
                                          />
                                       </div> */}
                                             <div className="mb-3">
                                                <label>Proforma Invoice</label>
                                                <ReactQuill
                                                   theme="snow"
                                                   value={proInvoice}
                                                   onChange={e => setProInvoice(e)}
                                                />
                                             </div>
                                          </Col>
                                          <Col md={4}>
                                             <div className="mb-3">
                                                <AvField
                                                   label="Customer Care Number"
                                                   type="number" onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                   name="customer_care_number"
                                                   placeholder="Enter Number"
                                                   value={getCurrentUser().customer_care_number}
                                                />
                                             </div>
                                             <div className="mb-3">
                                                <label>Bank Details</label>
                                                <ReactQuill
                                                   theme="snow"
                                                   value={bankDetails}
                                                   onChange={e => setBankDetails(e)}
                                                />
                                             </div>
                                          </Col>
                                          <Col md={4}>
                                             <label>Invoice Logo</label>
                                             <div className="mb-3">
                                                <AvField
                                                   // label="Invoice Logo"
                                                   type="file"
                                                   name="invoice_logo"
                                                   accept="image/jpg, image/png, image/gif, image/jpeg"
                                                   onChange={e => getFile(e)}
                                                />
                                             </div>
                                             <div className="mb-3">
                                                <AvField
                                                   label="Welcome Message"
                                                   type="textarea"
                                                   name="welcome_message"
                                                   placeholder="Enter Welcome Message here"
                                                   value={getCurrentUser().welcome_message}
                                                />
                                             </div>
                                          </Col>
                                          <hr />
                                       </Row>
                                       <div className="text-center">
                                          {loading ? (
                                             <button
                                                className="btn btn-primary disabled"
                                                type="button"
                                                disabled
                                             >
                                                Loading
                                             </button>
                                          ) : (
                                             <button
                                                className="btn btn-primary"
                                                type="submit"
                                             // onClick={() => props.setmodal_dead_reason(false)}
                                             >
                                                Submit
                                             </button>
                                          )}
                                       </div>
                                    </AvForm>
                                 </CardBody>
                              </Card>
                           ) : (
                              ""
                           )}
                        </Col>
                        <Col lg="6">
                           {getCurrentUser().role_name === "Administrator" ? (
                              <Card>
                                 <CardBody>
                                    <AvForm
                                       className="card-title mt-0"
                                       onValidSubmit={(e, v) => {
                                          submitTermCondition(e, v)
                                       }}
                                    >
                                       <h4 className="card-title mb-4">Product List</h4>
                                       <hr />
                                       <div className="mb-3">
                                          <label>Terms & Conditions</label>
                                          <ReactQuill
                                             theme="snow"
                                             value={tandC1}
                                             onChange={e => setTandC1(e)}
                                          />
                                       </div>
                                       <hr />
                                       <div className="text-center">
                                          {loading ? (
                                             <button
                                                className="btn btn-primary disabled"
                                                type="button"
                                                disabled
                                             >
                                                Loading
                                             </button>
                                          ) : (
                                             <button
                                                className="btn btn-primary"
                                                type="submit"
                                             // onClick={() => props.setmodal_dead_reason(false)}
                                             >
                                                Submit
                                             </button>
                                          )}
                                       </div>
                                    </AvForm>
                                 </CardBody>
                              </Card>
                           ) : (
                              ""
                           )}
                        </Col>
                        <Col lg="6">
                           {getCurrentUser().role_name === "Administrator" ? (
                              <Card>
                                 <CardBody>
                                    <AvForm
                                       className="card-title mt-0"
                                       onValidSubmit={(e, v) => {
                                          submitTermCondition2(e, v)
                                       }}
                                    >
                                       <h4 className="card-title mb-4">Service Line</h4>
                                       <hr />
                                       <div className="mb-3">
                                          <label>Terms & Conditions</label>
                                          <ReactQuill
                                             theme="snow"
                                             value={tandC2}
                                             onChange={e => setTandC2(e)}
                                          />
                                       </div>
                                       <hr />
                                       <div className="text-center">
                                          {loading ? (
                                             <button
                                                className="btn btn-primary disabled"
                                                type="button"
                                                disabled
                                             >
                                                Loading
                                             </button>
                                          ) : (
                                             <button
                                                className="btn btn-primary"
                                                type="submit"
                                             // onClick={() => props.setmodal_dead_reason(false)}
                                             >
                                                Submit
                                             </button>
                                          )}
                                       </div>
                                    </AvForm>
                                 </CardBody>
                              </Card>
                           ) : (
                              ""
                           )}
                        </Col>
                     </>
                     :
                     ""
                  }
                  <Col lg="12">
                     {getCurrentUser().role_name === "Administrator" ? (
                        <Card>
                           <CardBody>
                              <AvForm
                                 className="card-title mt-0"
                                 onValidSubmit={(e, v) => {
                                    smtpDetail(e, v)
                                 }}
                              >
                                 <h4 className="card-title mb-4">SMTP Details</h4>
                                 <hr />
                                 <Row>
                                    <Col md={4}>
                                       <div className="mb-3">
                                          <AvField
                                             label="Host"
                                             type="text"
                                             name="host"
                                             placeholder="Enter Host Name"
                                             value={smtpDetails?.host}
                                          />
                                       </div>
                                       <div className="mb-3">
                                          <AvField
                                             label="Port"
                                             type="text"
                                             name="port"
                                             placeholder="Enter Port Name"
                                             value={smtpDetails?.port}
                                          />
                                       </div>
                                    </Col>
                                    <Col md={4}>
                                       <div className="mb-3">
                                          <AvField
                                             label="Username"
                                             type="text"
                                             name="username"
                                             placeholder="Enter Username"
                                             value={smtpDetails?.username}
                                          />
                                       </div>
                                       <div className="mb-3">
                                          <AvField
                                             label="Password"
                                             type="password"
                                             name="password"
                                             placeholder="Enter Password"
                                             value={smtpDetails?.password}
                                          />
                                       </div>
                                    </Col>
                                    <Col md={4}>
                                       <div className="mb-3">
                                          <AvField
                                             label="Encryption"
                                             type="text"
                                             name="encryption"
                                             placeholder="Enter encryption"
                                             value={smtpDetails?.encryption}
                                          />
                                       </div>
                                       {/* <div className="mb-3">
                                          <AvField
                                             label="Email for CC"
                                             type="email"
                                             name="cc_email"
                                             placeholder="Enter email to add in CC"
                                             value={smtpDetails?.cc_email}
                                          />
                                       </div> */}
                                    </Col>
                                    <hr />
                                 </Row>
                                 <div className="text-center">
                                    {loading ? (
                                       <button
                                          className="btn btn-primary disabled"
                                          type="button"
                                          disabled
                                       >
                                          Loading
                                       </button>
                                    ) : (
                                       <button
                                          className="btn btn-primary"
                                          type="submit"
                                       // onClick={() => props.setmodal_dead_reason(false)}
                                       >
                                          Submit
                                       </button>
                                    )}
                                 </div>
                              </AvForm>
                           </CardBody>
                        </Card>
                     ) : (
                        ""
                     )}
                  </Col>
                  <Col lg="12">
                     {getCurrentUser().role_name === "Administrator" ? (
                        <Card>
                           <CardBody>
                              <AvForm
                                 className="card-title mt-0"
                              >
                                 <h4 className="card-title mb-4">Webhook for websites & Apps (API key)</h4>
                                 <hr />
                                 <Row>
                                    <Col md={12}>
                                       {loading2 ?
                                          <span>
                                             <i className="fa fa-spinner"></i>
                                          </span>
                                          :
                                          <div className="mb-3">
                                             <AvField
                                                label="API key"
                                                type="text"
                                                name="api_key"
                                                placeholder="generate API key"
                                                readOnly
                                                defaultValue={getCurrentUser()?.api_key}
                                             />
                                          </div>
                                       }
                                       <p>
                                          Here is the pdf to fetch leads using API. <span className="text-info" style={{ cursor: "pointer" }} onClick={() => downloadPDF()}>click here...</span>
                                       </p>
                                       {/* <p>
                                          Here is the link to Postman API, check it out there. <a className="text-info" href="https://www.postman.com/teamcodingcafe/workspace/klikcrm/collection/17597812-743ecf00-db24-4fc8-b122-6a38def2ce2b?action=share&creator=17597812" target="_blank">click here...</a>
                                       </p> */}
                                    </Col>
                                    <hr />
                                 </Row>
                                 {confirm_alert ? (
                                    <SweetAlert
                                       title="Are you sure?"
                                       warning
                                       showCancel
                                       confirmButtonText="Yes, delete it!"
                                       confirmBtnBsStyle="success"
                                       cancelBtnBsStyle="danger"
                                       onConfirm={() => {
                                          setconfirm_alert(false)
                                          setsuccess_dlg(true)
                                          generateApiKey(getCurrentUser().id)
                                          setdynamic_title("Deleted")
                                          setdynamic_description("Your file has been deleted.")
                                       }}
                                       onCancel={() => setconfirm_alert(false)}
                                    >

                                       <span className="text-normal">
                                          Do you want to generate api key?
                                       </span>
                                    </SweetAlert>
                                 ) : null}
                                 <div className="text-center">
                                    {loading2 ? (
                                       <button
                                          className="btn btn-primary disabled"
                                          type="button"
                                          disabled
                                       >
                                          Loading
                                       </button>
                                    ) : (
                                       <button
                                          className="btn btn-primary"
                                          type="button"
                                          onClick={() => setconfirm_alert(true)}
                                       >
                                          Regenerate API key
                                       </button>
                                    )}
                                 </div>
                              </AvForm>
                           </CardBody>
                        </Card>
                     ) : (
                        ""
                     )}
                  </Col>
                  {getCurrentUser()?.role_name === "Administrator" && getCurrentUser()?.ivr_webhook_url !== null ?
                     <Col lg="12">
                        <Card>
                           <CardBody>
                              <h4 className="card-title mb-4">IVR Webhook URL</h4>
                              <hr />
                              <Row>
                                 <AvForm>
                                    <Col md={12}>
                                       <div className="mb-3">
                                          <AvField
                                             label="Webhook URL"
                                             type="text"
                                             name="ivr_webhook_url"
                                             readOnly
                                             defaultValue={getCurrentUser()?.ivr_webhook_url}
                                          />
                                       </div>
                                       <p>
                                          Please paste the above webhook url into the cloudshope portal by enabling the POST API  in Services &#62; IVR Service &#62; API Section
                                       </p>
                                    </Col>
                                 </AvForm>
                              </Row>
                           </CardBody>
                        </Card>
                     </Col>
                     :
                     ""
                  }
                  <Col lg="12">
                     <Card>
                        <CardBody>
                           <AvForm
                              className="card-title mt-0"
                              onValidSubmit={(e, v) => whatsappToken(e, v)}
                           >
                              <h4 className="card-title mb-4">Whatsapp Token Details</h4>
                              <hr />
                              <Row>
                                 <Col md={platform === "webjaddu" ? 4 : 6}>
                                    <div className="mb-3">
                                       <AvField
                                          className="select-arrow"
                                          label="Select Platform"
                                          type="select"
                                          name="whatsapp_platform"
                                          defaultValue={platform}
                                          onChange={(e) => setPlatform(e.target.value)}
                                       >
                                          <option value="chatway">Chatway</option>
                                          <option value="webjaddu">Web Jaadu</option>
                                       </AvField>
                                    </div>
                                 </Col>
                                 <Col md={platform === "webjaddu" ? 4 : 6}>
                                    <div className="mb-3">
                                       <AvField
                                          label={platform === "webjaddu" ? "Instance ID" : "Username"}
                                          type="text"
                                          name="whatsapp_username"
                                          placeholder="Enter your username"
                                          defaultValue={getCurrentUser()?.whatsapp_username}
                                       />
                                    </div>
                                 </Col>
                                 <Col md={platform === "webjaddu" ? 4 : 6}>
                                    <div className="mb-3">
                                       <AvField
                                          label="Token"
                                          type="text"
                                          name="whatsapp_token"
                                          placeholder="Enter your token"
                                          defaultValue={getCurrentUser()?.whatsapp_token}
                                       />
                                    </div>
                                 </Col>
                                 {platform === "webjaddu" ?
                                    ""
                                    :
                                    <Col md={6}>
                                       <div className="mb-3">
                                          <AvField
                                             label="Whatsapp Credit Balance Token"
                                             type="text"
                                             name="whatsapp_credit_balance_token"
                                             placeholder="Enter your token"
                                             defaultValue={getCurrentUser()?.whatsapp_credit_balance_token}
                                          />
                                       </div>
                                    </Col>
                                 }
                                 <hr />
                              </Row>
                              <div className="text-center">
                                 {loading3 ? (
                                    <button
                                       className="btn btn-primary disabled"
                                       type="button"
                                       disabled
                                    >
                                       Loading
                                    </button>
                                 ) : (
                                    <button
                                       className="btn btn-primary"
                                       type="submit"
                                    >
                                       Submit
                                    </button>
                                 )}
                              </div>
                           </AvForm>
                        </CardBody>
                     </Card>
                  </Col>
                  <Modal isOpen={modal_xxlarge} centered={true}>
                     <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myLargeModalLabel">
                           Change Profile Photo
                        </h5>
                        <button
                           type="button"
                           className="cross__btn"
                           onClick={() => setmodal_xxlarge(false)}
                           aria-hidden="true"
                        >
                           &times;
                        </button>
                     </div>
                     <AvForm
                        className="modal-body mt-0 pad-footer"
                        id="myModalLabel"
                        onValidSubmit={(e, v) => {
                           imagePreview(e, v)
                        }}
                     >
                        <div className="form-group mt-4">
                           <div className="upload_opt">
                              <input
                                 id="attached"
                                 accept="image/png, image/gif, image/jpeg"
                                 className="d-none"
                                 type="file"
                                 name="profile_pic"
                                 onChange={e => feedAttach(e)}
                              />
                              <label htmlFor="attached" className="pl-3">
                                 {imagePreviewProfile ? (
                                    <>
                                       <img
                                          alt="dd"
                                          className="fa-camer uploaded__img p-0"
                                          src={imagePreviewProfile}
                                       />
                                       <br />
                                       <div className="">
                                          <span className="cam-img">Edit Profile Image</span>
                                       </div>
                                    </>
                                 ) : (
                                    <>
                                       <i className="fa fa-camer">{image}</i>{" "}
                                       <span className="cam-img">Change Profile Image</span>
                                    </>
                                 )}
                              </label>

                              <br />
                           </div>
                        </div>

                        {/* <div className="mb-3"> */}
                        {/*   <AvField */}
                        {/*     name="name" */}
                        {/*     label="Name" */}
                        {/*     defaultValue={getCurrentUser().name} */}
                        {/*     placeholder="Enter Name" */}
                        {/*     type="text" */}
                        {/*     required */}
                        {/*   /> */}
                        {/* </div> */}

                        <div className="modal-footer">
                           <div className="text-end">
                              {loading ? (
                                 <button
                                    className="btn btn-primary disabled"
                                    type="button"
                                    disabled
                                 >
                                    Loading
                                 </button>
                              ) : (
                                 <button type="submit" className="btn btn-primary">
                                    Save Changes
                                 </button>
                              )}
                           </div>
                        </div>
                     </AvForm>
                  </Modal>
               </Row>
            </Container>
         </div>
      </React.Fragment>
   )
}

UserProfile.propTypes = {
   editProfile: PropTypes.func,
   error: PropTypes.any,
   success: PropTypes.any,
}

const mapStatetoProps = state => {
   const { error, success } = state.Profile
   return { error, success }
}

export default withRouter(
   connect(mapStatetoProps, { editProfile, resetProfileFlag })(UserProfile)
)
