import React, { useState, useEffect } from "react"
import { Card, Row, Col, CardBody } from "reactstrap"
import { getCurrentUser } from "../../../helpers/Utils"
import axios from "axios"
import Paginations from "../../Pagination"
import Loader from "../../Loader/loader.js"
import CledgerList from "./CledgerList"
import CledgerModal from "./CledgerModal"

const CledgerTable = props => {
   const [modal_standard, setmodal_standard] = useState(false)
   const [modal3, setModal3] = useState(false)
   const [ledger, setLedger] = useState([])
   const [error, setError] = useState("")
   const [loader, setLoader] = useState(<Loader />)
   const [allPages, setAllPages] = useState([])
   const [counter, setCounter] = useState("")
   const [allData, setAllData] = useState([])


   const getAllLedger = () => {
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/ledger?customer_id=${props.leadId}&per_page=${localStorage.getItem("PerPage") !== null
               ? localStorage.getItem("PerPage")
               : ""
            }`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setError(res.data.message)
               setCounter(res.data.data.from)
               setLedger(res.data.data.data)
               setAllPages(res.data.data)
            } else {
               setLoader("")
               setError(res.data.message)
            }
         })
   }

   const paginate = e => {
      setLedger([])
      setAllPages([])
      setCounter("")
      setLoader(<Loader />)
      axios
         .get(`${e}&customer_id=${props.leadId}`, {
            headers: {
               "content-type": "application/json",
               Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
            },
         })
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setCounter(res.data.data.from)
               setAllPages(res.data.data)
               setLedger(res.data.data.data)
            } else {
               setError(res.data.message)
               setLoader("")
            }
         })
   }

   const leadDisplay = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/order-counts?customer_id=${props.leadId}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            setAllData(res.data.data)
         })
   }

   useEffect(() => {
      getAllLedger()
   }, [])

   return (
      <>
         {modal_standard ? (
            <CledgerModal
               modal_standard={modal_standard}
               id={props.leadId}
               setmodal_standard={setmodal_standard}
               getAllLedger={getAllLedger}
            />
         ) : (
            ""
         )}
         {modal3 ? (
            <div style={{ marginTop: "25px" }}>
               <Row>
                  <Col>
                     <Card className="mini-stat bg-primary text-white">
                        <CardBody>
                           <div className="">
                              <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                                 Total Business
                              </h5>
                              <h4 className="fw-medium font-size-18">
                                 {allData.all_orders_with_amount}{" "}
                              </h4>
                           </div>
                        </CardBody>
                     </Card>
                  </Col>
                  <Col>
                     <Card className="mini-stat bg-primary text-white">
                        <CardBody>
                           <div className="">
                              <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                                 Payment Received
                              </h5>
                              <h4 className="fw-medium font-size-18">
                                 {allData.received}{" "}
                              </h4>
                           </div>
                        </CardBody>
                     </Card>
                  </Col>
                  <Col>
                     <Card className="mini-stat bg-primary text-white">
                        <CardBody>
                           <div className="">
                              <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                                 Total Outstanding
                              </h5>
                              <h4 className="fw-medium font-size-18">
                                 {allData.outstanding}{" "}
                              </h4>
                           </div>
                        </CardBody>
                     </Card>
                  </Col>
               </Row>
            </div>
         ) : (
            ""
         )}
         <div className="page-title-box">
            <Row className="align-items-center">
               <Col md={3}>
                  <h6 className="page-title">Recent Ledger/Payments</h6>
               </Col>
               <Col md={9}>
                  <h6 className="page-title float-end">
                     {modal3 ? (
                        <button
                           className="btn btn-primary submit__button me-2"
                           onClick={() => {
                              setModal3(false)
                           }}
                        >
                           Legder Counter
                           <i className="fa fa-arrow-up ms-2"></i>
                        </button>
                     ) : (
                        <button
                           className="btn btn-primary submit__button me-2"
                           onClick={() => {
                              setModal3(true), leadDisplay()
                           }}
                        >
                           Legder Counter
                           <i className="fa fa-arrow-down ms-2"></i>
                        </button>
                     )}
                     <button
                        className="btn btn-primary submit__button"
                        type="button"
                        onClick={() => {
                           setmodal_standard(true)
                        }}
                     >
                        Add New
                     </button>
                  </h6>
               </Col>
            </Row>
         </div>
         <div className="card">
            <div className="card-body">
               <div className="lead_table table-responsive">
                  <table style={{ width: "100%" }}>
                     <tbody>
                        <tr>
                           <th>#</th>
                           <th>Ledger ID</th>
                           <th>Order ID</th>
                           <th>Amount</th>
                           <th>Transaction date</th>
                           <th>Message</th>
                           <th>Type</th>
                           <th>Issued by</th>
                        </tr>
                        {ledger &&
                           ledger.map((item, obj) => (
                              <CledgerList
                                 key={obj}
                                 data={item}
                                 i={obj}
                                 counter={counter}
                              />
                           ))}
                     </tbody>
                  </table>
                  {error ? (
                     <span className="text-danger mt-3 d-table m-auto" role="alert">
                        {error}
                     </span>
                  ) : (
                     ""
                  )}
               </div>
            </div>
         </div>
         {loader}
         {allPages !== [] ? (
            <Paginations
               data={allPages}
               paginate={paginate}
               perPage={getAllLedger}
            />
         ) : (
            ""
         )}
      </>
   )
}
export default CledgerTable
